import React, { Component } from 'react';
import './StateUnsupported.scss';

import {
  FormControl,
  FormHelperText,
  Button,
  Grid,
  Input,
} from '@mui/material';

import { connect } from 'react-redux';
import { saveWaitlistEntry } from '../../../../_actions/notificationsActions';
import ScreenWrapper from '../../../Common/ScreenWrapper/ScreenWrapper';
import { Link } from 'react-router-dom/cjs/react-router-dom';


import BaseComponent from '../../../BaseComponent';

class StateUnsupported extends BaseComponent {
  constructor(props) {
    super(props, 'state_unsupported');


    this.state = {
      email: '',
      emailSent: false,
      emailError: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      emailSent: false,
      emailError: false,
    });
    const data = {
      tag: 'availability',
      email: this.state.email,
    };
    await this.props.saveWaitlistEntry(data);
    const res = this.props.waitlistSaved;
    if (res.status === 200) {
      this.setState({ emailSent: true });
      setTimeout(() => {
        this.setState({ email: '', emailSent: false });
      }, 3000);
    } else {
      this.setState({ emailError: true });
      setTimeout(() => {
        this.setState({ email: '', emailError: false });
      }, 3000);
    }
  };

  handleChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    await this.setState({ [name]: value });
  };
  handleClick = () => {
    this.props.history.push({ pathname: `/register` });
  };
  render() {
    const { isMobileView } = this.props;
    const { email, emailSent, emailError } = this.state;
    return (
      <>
        <ScreenWrapper>
          <form onSubmit={this.handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12}>
                <h2>The state is not supported yet! </h2>
                <p className="sub-head">
                  It looks like Ava is not available in your state yet. Do you
                  want us to notify you once it will be supported?
                </p>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <FormControl error={emailError} style={{ width: '100%' }}>
                  <label htmlFor="streetAddress" className="lbl">
                    Email Address
                  </label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={this.handleChange}
                    required
                  />
                  {emailError && (
                    <FormHelperText className="error">
                      Something went wrong.
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  fullWidth={true}
                  className="btn-primary mgr-top-8"
                  type="submit"
                >
                  Yes, notify me
                </Button>
                <Link className="link-account text-red" to="/register">
                  No, thanks
                </Link>
              </Grid>
            </Grid>
          </form>

          {emailSent && (
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12}>
                <h2>Thank you! </h2>
                <p className="sub-head">
                  We will notify you via email once Ava will be supported in
                  your state.
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  fullWidth={true}
                  className="btn-primary mgr-top-8"
                  type="button"
                  onClick={this.handleClick}
                >
                  Back to the Website
                </Button>
              </Grid>
            </Grid>
          )}
        </ScreenWrapper>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  waitlistSaved: dispatch.waitlistSaved.item31,
});

export default connect(mapDispatchToProps, { saveWaitlistEntry })(
  StateUnsupported
);
