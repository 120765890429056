import { REG_SUBSCRIPTION, SUBSCRIPTION_PLANS, SUBSCRIPTION_STATUS } from "./_types";

import ava from "../apis/ava";

export const registerSubscription = (data) => async (dispatch) => {
  await ava.post(`${process.env.REACT_APP_V2_BASE_URL}/subscriptions`, { planId: data.id })
    .then((res) => {
      const regSubs = res;
      dispatch({
        type: REG_SUBSCRIPTION,
        payload: regSubs,
      });
    })
    .catch((err) => {
      const regSubs = err.response;
      dispatch({
        type: REG_SUBSCRIPTION,
        payload: regSubs,
      });
    });
};

export const subscriptionAuthorization = (data) => async (dispatch) => {
  await ava.post(`${process.env.REACT_APP_V2_BASE_URL}/subscriptions/authorization`, { subscriptionPlanId: data.id })
    .then((res) => {
      dispatch({
        type: REG_SUBSCRIPTION,
        payload: res.data,
      });
    })
    .catch((err) => {
      const regSubs = err.response;
      dispatch({
        type: REG_SUBSCRIPTION,
        payload: regSubs,
      });
    });
};

export const getSubscriptionPlans = () => async (dispatch) => {
  await ava.get(`${process.env.REACT_APP_V2_BASE_URL}/subscriptions/plans`)
    .then((res) => {
      dispatch({
        type: SUBSCRIPTION_PLANS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SUBSCRIPTION_PLANS,
        payload: [],
      });
    });
};


export const subscriptionStatus = () => async (dispatch) => {
  await ava.get(`${process.env.REACT_APP_V2_BASE_URL}/subscriptions`)
    .then((res) => {
      const subsStatus = res;
      dispatch({
        type: SUBSCRIPTION_STATUS,
        payload: subsStatus,
      });
    })
    .catch((err) => {
      const subsStatus = err.response;
      dispatch({
        type: SUBSCRIPTION_STATUS,
        payload: subsStatus,
      });
    });
};