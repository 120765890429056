import React, { Component } from 'react';

import './Partners.scss';
import { Grid } from '@mui/material';
import ReportingPartners from '../../../assets/logos/reporting-logo.png';

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="btm-partners">
        <p className="lbll-rpt">Reporting to:</p>
        <Grid
          container
          spacing={4}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <img
              style={{ maxWidth: '100%' }}
              src={ReportingPartners}
              alt="Experian  | Trans Union | Equifax"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Partners;
