/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import "./EditInfo.scss";
import { alpha, styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";

import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  InputLabel,
  InputBase,
  InputAdornment,
  IconButton,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";

import warningIcon from '../../../../assets/icons/warningIcon.svg';

import { connect } from "react-redux";
import { getUserById, registerStatus } from "../../../../_actions/userActions";

import moment from "moment";
import LockIcon from "@mui/icons-material/Lock";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import GoogleSearchBox from "../GoogleSearchBox/GoogleSearchBox";
import { geocodeByPlaceId } from "react-places-autocomplete";

import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { API_RESPONSE_STATUS } from "../../../../Constants/AppConstants";

import BaseComponent from '../../../BaseComponent';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("mm/dd/yyyy");

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: 20,
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #D4D6D8",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const styles = (theme) => ({
  dateInput: {
    borderRadius: 8,
    position: "relative",
    // marginTop: 20,
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #D4D6D8",
    fontSize: 16,
    width: "100%",
    color: "#363636",
    padding: "12px 12px",
    outline: "none",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  kycAlert: {
    marginTop: 20,
    borderRadius: 12,
    paddingTop: 15,
    paddingBottom: 15,
    border: '1px solid #FFC926',
    boxShadow: '0px 5px 10px rgba(103, 103, 103, 0.05)',
    "& .MuiAlert-icon": {
      padding: 0,
      marginRight: 8,
      color: '#FFC926',
    }
  }
});

class EditInfo extends BaseComponent {
  constructor(props) {
    super(props, 'edit_info');


    this.state = {
      firstName: "",
      lastName: "",
      
      firstNameError: false,
      lastNameError: false,
      kycRetry: false
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    let registerStatusProm = this.props.registerStatus();
    await this.props.getUserById();
    await registerStatusProm;
    const userRegStatusRes = this.props.regStatus;
    if (userRegStatusRes.status === 200) {
      await this.setState({
        kycRetry: userRegStatusRes.data.canRetryKyc && userRegStatusRes.data.status === API_RESPONSE_STATUS.FAILED
      });
    }

    const res = this.props.userData;
    if (res.status === 200 && res.data.firstName !== null) {
      const x = res.data;
      await this.setState({
        firstName: x?.user?.firstName,
        lastName: x?.user?.lastName,
      });
    }
  };

  handleChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    await this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const s = this.state;
    this.setState({
      firstNameError: false,
      lastNameError: false,
    });

    var fPattern = new RegExp("^[a-zA-Z ,'-]+$");
    var fn = await fPattern.test(s.firstName);
    var ln = await fPattern.test(s.lastName);

    if (!fn) {
      this.setState({ firstNameError: true });
    }
    if (!ln) {
      this.setState({ lastNameError: true });
    }
   

    if (fn && ln) {
      const data = {
        name: {
          firstName: s.firstName,
          lastName: s.lastName,
          middleName: null,
        },
      };

      // if((data.address.city === undefined || data.address.city === "") && (data.address.state === undefined || data.address.state === "")
      //   && (data.address.zip === undefined || data.address.zip === "") && (data.address.line1 === undefined || data.address.line1 === "")
      //   && (data.address.country === undefined || data.address.country === "")) {
      //   this.setState({ addressError: true, streetAddress: "", addressErrMsg: "Please select the address from suggestions list" });
      // }
        this.props.editUpdatedUserDetails(data);
    }
  };

  render() {
    const {
      firstName,
      lastName,
      firstNameError,
      lastNameError,
      kycRetry
    } = this.state;
    const { classes, isMobileView, disableSubmitBtn } = this.props;
    return (
      <Box className="editInfoBox">
        <form onSubmit={(e) => this.handleSubmit(e, this.state)}>
          <Typography
            variant="h6"
            className="infoHeader1"
            style={{ marginTop: 40 }}
          >
            Edit your information
          </Typography>
          {!kycRetry && (<Typography variant="body1" className="infoText1">
            Please review the following details. They need to match your
            government issued ID.
          </Typography>)}
          {kycRetry && (<Alert
              variant="outlined"
              severity="warning"
              className={classes.kycAlert}
              icon={<img src={warningIcon} style={{ height: 40 }}/>}
            >
            <AlertTitle style={{fontWeight: 600, fontSize: 15, color: "#363636"}}>Something doesn’t look right</AlertTitle>
            <text style={{fontWeight: 500, fontSize: 14, color: "#363636"}}>Please double-check your information and make sure you didn’t mistype anything.</text>
          </Alert>)}
          <FormControl
            error={firstNameError}
            variant="standard"
            style={{ width: "100%", marginTop: 20 }}
          >
            <InputLabel shrink htmlFor="firstName" className="typoHeader">
              First Name
            </InputLabel>
            <BootstrapInput
              id="firstName"
              name="firstName"
              value={firstName}
              onChange={this.handleChange}
              required
            />
            {firstNameError && (
              <FormHelperText>
                Please don’t use special characters
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            error={lastNameError}
            variant="standard"
            style={{ width: "100%", marginTop: 15 }}
          >
            <InputLabel shrink htmlFor="lastName" className="typoHeader">
              Last Name
            </InputLabel>
            <BootstrapInput
              id="lastName"
              name="lastName"
              value={lastName}
              onChange={this.handleChange}
              required
            />
            {lastNameError && (
              <FormHelperText>
                Please don’t use special characters
              </FormHelperText>
            )}
          </FormControl>
          <Button
            className="goalSelectionBtn"
            variant="contained"
            color="primary"
            size="large"
            style={{
              pointerEvents: disableSubmitBtn ? 'none' : null,
              marginTop: 35,
              marginBottom: 35,
              width: isMobileView ? "100%" : "400px",
            }}
            type="submit"
          >
            Continue
          </Button>
        </form>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userData: dispatch.userData.item22,
  regStatus: dispatch.regStatus.item26,
});

export default connect(mapDispatchToProps, { getUserById, registerStatus })(
  withStyles(styles)(EditInfo)
);
