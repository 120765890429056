import React, { Component } from 'react';
import './Header.scss';

import avaLogo from '../../assets/logos/AvaLogo.svg';

import {
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Box,
  Button,
} from '@mui/material';
import GTM from '../Gtm';

class Header extends Component {
  render() {
    const {
      isMobileView,
      isDrawerView,
      // headerContent, avaLogo
    } = this.props;

    return (
      <>
        <GTM />
        <AppBar position="fixed" className="app-header">
          <a
            href="https://www.meetava.com/"
            target={'_blank'}
            rel="noreferrer"
            title="MeetAva"
            className="brand"
          >
            <img src={avaLogo} alt="MeetAva" />
          </a>
        </AppBar>
      </>
    );
  }
}

export default Header;
