import React, { Component } from 'react';
import './UserAddress.scss';


import {
  FormControl,
  FormHelperText,
  Button,
  Grid,
  Input,
  Select,
  MenuItem,
  Autocomplete,
  Box,
  TextField,
  Checkbox,
} from '@mui/material';

import GoogleSearchBox from '../GoogleSearchBox/GoogleSearchBox';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { getUserById } from '../../../../_actions/userActions';
import ScreenWrapper from '../../../Common/ScreenWrapper/ScreenWrapper';
import { STATES } from '../../../../Constants/States';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentIdentify,
  segmentPage,
  segmentTrack,
} from '../../../../services/segment';

import BaseComponent from '../../../BaseComponent';

class UserAddress extends BaseComponent {
  constructor(props) {
    super(props, 'user_address');


    this.state = {
      streetAddress: '',
      gAddress: '',
      addressChanged: false,
      aptNumber: '',
      //aptNumberError: false,
      aptNumberVisible: false,
      stateSN: '',
      citySN: '',
      aptChanged: false,
      zip: '',
      refAddress: '',
      disableSubmit: true,
      manualStreetAddress: '',
      manualStreetAddressError: false,
      manualAptSuit: '',
      manualCityError: false,
      manualCity: '',
      manualStateError: false,
      manualState: '',
      manualZipCodeError: false,
      manualZipCode: '',
      disableManualSubmit: true,
      isManualAddressEnable: false,
      addressSelected: false,
      isTosChecked: false,
      isTosCheckedError: false,
      userID: '',
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    var aptNumber = document.getElementById(`aptNumber`);
    if (aptNumber) {
      aptNumber.oninvalid = function (e) {
        e.target.setCustomValidity('');
        if (!e.target.validity.valid) {
          e.target.setCustomValidity('Please enter a valid address line');
        } else {
          e.target.setCustomValidity('');
        }
      };
      aptNumber.oninput = function (e) {
        if (e.target.value.length !== 0 && e.target.value.length < 3) {
          e.target.setCustomValidity('Please enter a valid address line');
        } else {
          e.target.setCustomValidity('');
        }
      };
    }

    await this.setState({ userID: localStorage.getItem('userId') });

    await this.props.getUserById();
    const res = this.props.userData;
    if (res.status === 200 && res.data.address) {
      await this.setState({
        streetAddress:
          res.data.address.line1 +
          ', ' +
          res.data.address.city +
          ', ' +
          res.data.address.state +
          ', ' +
          res.data.address.country,
        gAddress: res.data.address.line1,
        aptNumber: res.data.address.line2,
        zip: res.data.address.zip,
        refAddress: res.data.address,
      });
    }
    this.setState({ isTosChecked: false });

    segmentIdentify(
      this.state.userID,
      SEGMENT_PAGE_CATEGORY.EVENT.IDENTIFY_CALL,
      {
        traits: {
          //  anonymousId: localStorage.getItem('segmentAnonymousId'),
          // userId: this.state.userID,
        },
      }
    );
  };

  handleLocationChange = (x) => {
    this.setState({
      streetAddress: x,
      addressError: false,
      // disableSubmit: false,
      addressSelected: false,
    });
  };

  handleLocationSelect = async (address, placeId, location) => {
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = '' } =
      place.address_components.find((c) => c.types.includes('postal_code')) ||
      {};
    const { short_name: stateSN = '' } =
      place.address_components.find((c) =>
        c.types.includes('administrative_area_level_1')
      ) || {};
    const { long_name: citySN = '' } =
      place.address_components.find((c) => c.types.includes('locality')) ||
      place.address_components.find((c) => c.types.includes('sublocality')) ||
      {};
    if (citySN && stateSN && postalCode) {
      this.setState({
        streetAddress: address,
        gAddress: location,
        stateSN: stateSN,
        citySN: citySN,
        addressChanged: true,
        zip: postalCode,
        addressError: false,
        addressErrMsg: '',
        // disableSubmit: false,
        aptNumberVisible: true,
        addressSelected: true,
      });
    } else {
      this.setState({
        streetAddress: address,
        gAddress: location,
        stateSN: '',
        citySN: '',
        addressChanged: true,
        zip: '',
        addressError: true,
        disableSubmit: true,
        //isTosCheckedError: true,
        addressErrMsg: 'Please provide the complete address',
        aptNumberVisible: false,
      });
    }
    if (this.state.isTosChecked && citySN && stateSN && postalCode) {
      this.setState({
        disableSubmit: false,
        isTosCheckedError: false,
      });
    } else {
      this.setState({
        disableSubmit: true,
        isTosCheckedError: true,
      });
    }
  };

  handleChange = async (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    await this.setState({ [name]: value });
  };

  handleManualChange = async (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    await this.setState({ [name]: value });

    if (
      this.state.manualStreetAddress !== '' &&
      this.state.manualZipCode !== '' &&
      this.state.manualState !== '' &&
      this.state.manualCity !== '' &&
      this.state.isTosChecked
    ) {
      await this.setState({ disableManualSubmit: false });
    }
  };

  handleTOSChange = async (event) => {
    await this.setState({
      isTosChecked: event.target.checked,
      disableManualSubmit: true,
      disableSubmit: true,
    });
    if (
      this.state.isTosChecked &&
      this.state.streetAddress !== '' &&
      this.state.gAddress !== '' &&
      !this.state.isManualAddressEnable
    ) {
      this.setState({
        isTosCheckedError: false,
        disableSubmit: false,
      });
    } else if (
      this.state.isTosChecked &&
      !this.state.isManualAddressEnable &&
      (this.state.streetAddress === '' || this.state.gAddress === '')
    ) {
      this.setState({
        isTosCheckedError: false,
        disableSubmit: true,
      });
    } else if (
      this.state.isTosChecked &&
      this.state.manualStreetAddress !== '' &&
      this.state.manualZipCode !== '' &&
      this.state.manualState !== '' &&
      this.state.manualCity !== '' &&
      this.state.isManualAddressEnable
    ) {
      this.setState({
        isTosCheckedError: false,
        disableManualSubmit: false,
      });
    } else if (
      this.state.isTosChecked &&
      this.state.manualStreetAddress === '' &&
      this.state.manualZipCode === '' &&
      this.state.manualState === '' &&
      this.state.manualCity === '' &&
      this.state.isManualAddressEnable
    ) {
      this.setState({
        isTosCheckedError: false,
      });
    } else {
      this.setState({
        isTosCheckedError: true,
        disableManualSubmit: true,
        disableSubmit: true,
      });
    }
  };

  onDataSubmit = async (event) => {
    event.preventDefault();
    const s = this.state;

    if (s.streetAddress === '' || s.gAddress === '') {
      this.setState({
        addressError: true,
        addressErrMsg: 'Please enter your address',
        disableSubmit: true,
        // aptNumberError: true,
      });
    }

    if (this.state.addressChanged) {
      if (!s.citySN && !s.stateSN && !s.postalCode) {
        this.setState({
          addressChanged: false,
          addressError: true,
          //  aptNumberError: true,
          addressErrMsg: 'Please provide the complete address',
        });
      }
    }
    if (!s.isTosChecked) {
      this.setState({ isTosCheckedError: true });
    } else {
      this.setState({ isTosCheckedError: false });
    }
    if (s.addressError === false && s.isTosChecked) {
      const data = {
        id: localStorage.getItem('userId'),
        version: 'web_enter_your_address_v1 ',
      };

      // if (s.aptNumber === '') {
      //   this.setState({
      //     //  aptNumberError: true,
      //     disableSubmit: false,
      //   });
      // } else {
      //   this.setState({ aptNumber: s.aptNumber });
      // }
      const data2 = {
        address: this.state.addressChanged
          ? {
              zip: s.zip,
              country: 'US',
              city: s.citySN,
              state: s.stateSN,
              line2: s.aptNumber,
              line1: s.gAddress.formattedSuggestion.mainText,
            }
          : {
              zip: s.refAddress.zip,
              country: s.refAddress.country,
              city: s.refAddress.city,
              state: s.refAddress.state,
              line2: s.aptNumber,
              line1: s.refAddress.line1,
            },
      };
      if (
        (data2.address.city === undefined || data2.address.city === '') &&
        (data2.address.state === undefined || data2.address.state === '') &&
        (data2.address.zip === undefined || data2.address.zip === '') &&
        (data2.address.line1 === undefined || data2.address.line1 === '') &&
        (data2.address.country === undefined || data2.address.country === '')
      ) {
        this.setState({
          addressError: true,
          streetAddress: '',
          addressErrMsg: 'Please select the address from suggestions list',
        });
      } else {
        segmentTrack(
          this.state.userID,
          SEGMENT_PAGE_CATEGORY.EVENT.FORM_SUBMITTED,
          {
            properties: {
              page_name: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.PAGE_NAME,
              form_name: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.FORM_NAME,
              form_category: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.CATEGORY,
              form_referrer: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.REFERER,
              input_type: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.INPUT_TYPE,
              number_of_fields:
                SEGMENT_PAGE_CATEGORY.USER_ADDRESS.NUMBER_OF_FIELDS,
              email: this.props?.userData?.data?.user?.email,
              firstName: this.props?.userData?.data?.user?.firstName,
              lastName: this.props?.userData?.data?.user?.lastName,
              city: data2.address.city,
              state: data2.address.state,
              zip: data2.address.zip,
              advertiser_tracking_enabled: 0,
              application_tracking_enabled: 0,
              platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
            },
          }
        );
        segmentIdentify(
          this.state.userID,
          SEGMENT_PAGE_CATEGORY.EVENT.IDENTIFY_CALL,
          {
            traits: {
              // anonymousId: localStorage.getItem('segmentAnonymousId'),
              //  userId: this.state.userID,
              city: data2.address.city,
            },
          }
        );
        this.props.updateUserAddressDetails(data, data2);
      }
    } else {
      this.setState({ disableSubmit: false });
    }
  };

  onManulDataSubmit = async (event) => {
    event.preventDefault();
    const statesAddress = this.state;
    const data = {
      id: localStorage.getItem('userId'),
      version: 'web_enter_your_address_v1',
    };
    try {
      this.setState({
        manualStreetAddressError: false,
        manualCityError: false,
        manualStateError: false,
        manualZipCodeError: false,
      });

      let lettersRegexPattern = new RegExp("^[a-zA-Z ,'-]+$");
      let numberRegexPattern = new RegExp('^[0-9]+$');
      let manualCityLettersOnly = new RegExp(lettersRegexPattern);
      let manualZipCodeNumberOnly = new RegExp(numberRegexPattern);

      let manualCityLettersValidity = await manualCityLettersOnly.test(
        statesAddress.manualCity
      );

      let manualZipCodeNumberValidity = await manualZipCodeNumberOnly.test(
        statesAddress.manualZipCode
      );

      if (this.state.manualStreetAddress === '') {
        this.setState({ manualStreetAddressError: true });
      }
      if (this.state.manualCity === '') {
        this.setState({ manualCityError: true });
      }
      if (this.state.manualState === '') {
        this.setState({ manualStateError: true });
      }

      if (this.state.manualZipCode === '') {
        this.setState({ manualZipCodeError: true });
      }
      if (!manualCityLettersValidity) {
        this.setState({ manualCityError: true });
      }
      if (!manualZipCodeNumberValidity) {
        this.setState({ manualZipCodeError: true });
      }

      if (
        this.state.manualStreetAddress !== '' &&
        this.state.manualCity !== '' &&
        manualCityLettersValidity &&
        this.state.manualState !== '' &&
        this.state.manualZipCode !== '' &&
        manualZipCodeNumberValidity &&
        this.state.isTosChecked
      ) {
        this.setState({ disableManualSubmit: false, isTosCheckedError: false });
        const addressData = {
          address: {
            line1: statesAddress.manualStreetAddress,
            line2: statesAddress.manualAptSuit,
            city: statesAddress.manualCity,
            state: statesAddress.manualState,
            zip: statesAddress.manualZipCode,
            country: 'US',
          },
        };
        segmentTrack(
          this.state.userID,
          SEGMENT_PAGE_CATEGORY.EVENT.FORM_SUBMITTED,
          {
            properties: {
              page_name: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.PAGE_NAME,
              form_name: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.FORM_NAME,
              form_category: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.CATEGORY,
              form_referrer: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.REFERER,
              input_type: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.INPUT_TYPE,
              number_of_fields:
                SEGMENT_PAGE_CATEGORY.USER_ADDRESS.NUMBER_OF_FIELDS,
              email: this.props?.userData?.data?.user?.email,
              firstName: this.props?.userData?.data?.user?.firstName,
              lastName: this.props?.userData?.data?.user?.lastName,
              city: statesAddress.manualCity,
              state: statesAddress.manualState,
              zip: statesAddress.manualZipCode,
              advertiser_tracking_enabled: 0,
              application_tracking_enabled: 0,
              platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
            },
          }
        );
        segmentIdentify(
          this.state.userID,
          SEGMENT_PAGE_CATEGORY.EVENT.IDENTIFY_CALL,
          {
            traits: {
              //             anonymousId: localStorage.getItem('segmentAnonymousId'),
              // userId: this.state.userID,
              city: statesAddress.manualCity,
            },
          }
        );
        this.props.updateUserAddressDetails(data, addressData);
      } else {
        this.setState({ disableManualSubmit: true });
      }
    } catch (e) {
      console.log('something went wrong', e);
    } finally {
      this.setState({ openBackdrop: false });
    }
  };
  showManualAddressForm = () => {
    this.setState({ isManualAddressEnable: true });
  };
  render() {
    const {
      streetAddress,
      aptNumber,
      // aptNumberError,
      aptNumberVisible,
      addressError,
      addressErrMsg,
      disableSubmit,
      manualStreetAddress,
      manualStreetAddressError,
      manualAptSuit,
      manualCityError,
      manualCity,
      manualStateError,
      manualState,
      manualZipCodeError,
      manualZipCode,
      disableManualSubmit,
      isManualAddressEnable,
      isTosChecked,
      isTosCheckedError,
    } = this.state;
    const { isMobileView, disableSubmitBtn } = this.props;
    return (
      <ScreenWrapper>
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12}>
            <h2>Enter your address</h2>
            <p className="sub-head">This helps us verify who you are.</p>
          </Grid>
        </Grid>
        {!isManualAddressEnable ? (
          <form onSubmit={this.onDataSubmit}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl
                  error={addressError}
                  //variant="standard"
                  style={{ width: '100%' }}
                >
                  <label htmlFor="streetAddress" className="lbl">
                    Street address (No PO Boxes)
                  </label>
                  <GoogleSearchBox
                    streetAddress={streetAddress}
                    handleLocationSelect={this.handleLocationSelect}
                    handleLocationChange={this.handleLocationChange}
                    addressError={addressError}
                    showManualAddressForm={this.showManualAddressForm}
                    addressSelected={this.state.addressSelected}
                  />

                  {addressError && (
                    <FormHelperText className="error">
                      {addressErrMsg}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {aptNumberVisible ? (
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl style={{ width: '100%' }}>
                    <label htmlFor="streetAddress" className="lbl">
                      Apt/Suite Number (Optional)
                    </label>
                    <Input
                      id="aptNumber"
                      name="aptNumber"
                      className="textfield"
                      value={aptNumber}
                      inputProps={{ name: 'aptNumber' }}
                      onChange={this.handleChange}
                    />
                  </FormControl>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  fullWidth={true}
                  className="btn-primary mgr-top-8"
                  disabled={disableSubmit ? true : false}
                  type="submit"
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <form onSubmit={this.onManulDataSubmit}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl
                  error={manualStreetAddressError}
                  style={{ width: '100%' }}
                >
                  <label for="manualStreetAddress" className="lbl">
                    Street address (No PO Boxes)
                  </label>
                  <Input
                    id="manualStreetAddress"
                    name="manualStreetAddress"
                    className="textfield"
                    placeholder="#1234"
                    value={manualStreetAddress}
                    onChange={this.handleManualChange}
                    inputProps={{
                      name: 'manualStreetAddress',
                    }}
                  />
                  {manualStreetAddressError && (
                    <FormHelperText className="error">
                      Please enter valid Street address (No PO Boxes)
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl style={{ width: '100%' }}>
                  <label for="manualAptSuit" className="lbl">
                    Apt/Suite Number (Optional)
                  </label>

                  <Input
                    id="manualAptSuit"
                    name="manualAptSuit"
                    value={manualAptSuit}
                    className="textfield"
                    onChange={this.handleManualChange}
                    placeholder="123"
                    inputProps={{
                      name: 'manualAptSuit',
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl error={manualCityError} style={{ width: '100%' }}>
                  <label for="manualCity" className="lbl">
                    City
                  </label>
                  <Input
                    id="manualCity"
                    name="manualCity"
                    value={manualCity}
                    className="textfield"
                    onChange={this.handleManualChange}
                    placeholder="City"
                    inputProps={{
                      name: 'manualCity',
                    }}
                  />
                  {manualCityError && (
                    <FormHelperText className="error">
                      Please enter valid City
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl error={manualStateError} style={{ width: '100%' }}>
                  <label for="manualState" className="lbl">
                    State
                  </label>

                  <Autocomplete
                    id="state-select-demo"
                    name={'manualState'}
                    options={STATES}
                    onSelect={this.handleManualChange}
                    autoHighlight
                    getOptionLabel={(option) => option.code}
                    filterOptions={(options, state) => {
                      return options.filter((option) => {
                        if (option.label) {
                          return option.label
                            .toLowerCase()
                            .startsWith(state?.inputValue?.toLowerCase());
                        } else {
                          return false;
                        }
                      });
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.code}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={'manualState'}
                        className="select"
                        placeholder="State"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  {manualStateError && (
                    <FormHelperText className="error">
                      Please select state
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  error={manualZipCodeError}
                  style={{ width: '100%' }}
                >
                  <label for="manualZipCode" className="lbl">
                    Zip Code
                  </label>
                  <Input
                    id="manualZipCode"
                    name="manualZipCode"
                    value={manualZipCode}
                    className="textfield"
                    type="tel"
                    onChange={this.handleManualChange}
                    placeholder="00000"
                    inputProps={{
                      name: 'manualZipCode',
                    }}
                  />
                  {manualZipCodeError && (
                    <FormHelperText className="error">
                      Please enter valid zip code
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Button
                  fullWidth={true}
                  className="btn-primary mgr-top-8"
                  disabled={disableManualSubmit ? true : false}
                  type="submit"
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs="auto">
            <Checkbox
              checked={isTosChecked}
              onChange={this.handleTOSChange}
              inputProps={{ 'aria-label': 'controlled' }}
              className={isTosCheckedError ? 'error checkBox' : 'checkBox'}
            />
          </Grid>
          <Grid item xs>
            <p className="terms-text">
              Ava Finance, Inc. partners with financial services software
              company Sila Inc. (Sila) and banking services provider Evolve Bank
              & Trust (Evolve), member FDIC, to provide you with an Account on
              our platform. The Account allows you to store value and perform
              certain transactions through our platform. By registering to use
              our platform and receiving access to the Account, you agree to the
              terms and conditions of the &nbsp;
              <a
                target={'_blank'}
                href="https://meetava.com/evolve-customer-account-agreement"
                title="Terms of Use"
                rel="noreferrer"
              >
                <u>Evolve Customer Account Agreement.</u>
              </a>
              &nbsp; Additionally, by opening an Account, linking your external
              bank account, or authorizing any electronic payment, you agree
              to&nbsp;
              <a
                target={'_blank'}
                href="https://www.silamoney.com/legal/terms-of-service"
                title="Terms of Use"
                rel="noreferrer"
              >
                <u>Sila’s terms of service</u>
              </a>
              ,&nbsp;
              <a
                target={'_blank'}
                href="https://www.silamoney.com/legal/acceptable-use-policy"
                title="Terms of Use"
                rel="noreferrer"
              >
                <u>Sila’s acceptable use policy</u>
              </a>
              ,&nbsp;
              <a
                target={'_blank'}
                href="https://www.silamoney.com/legal/sila-privacy-policy"
                title="Terms of Use"
                rel="noreferrer"
              >
                <u>Sila’s privacy policy</u>
              </a>
              ,&nbsp; and ,&nbsp;
              <a
                target={'_blank'}
                href="https://www.silamoney.com/legal/sila-electronic-communications-consent"
                title="Terms of Use"
                rel="noreferrer"
              >
                <u>Sila’s electronic communications consent </u>
              </a>
              ,&nbsp;(the Sila Terms). I accept the terms and conditions of the
              Evolve Customer Account Agreement and the Sila Terms.
            </p>
            <p className="terms-text">
              By clicking ‘Continue’ you hereby consent to Ava using a consumer
              reporting agency like Experian to make a soft credit inquiry,
              verify your employment and income, and retrieve the vehicle
              identification number(s) of your vehicle(s). You also accept
              Ava’s&nbsp;
              <a
                target={'_blank'}
                href="https://meetava.com/terms"
                title="Terms of Use"
                rel="noreferrer"
              >
                <u>Terms of Use</u>
              </a>
              ,&nbsp;
              <a
                target={'_blank'}
                href="https://meetava.com/privacy"
                title="Privacy Policy"
                rel="noreferrer"
              >
                <u>Privacy Policy</u>
              </a>
              ,&nbsp;
              <a
                target={'_blank'}
                href="https://meetava.com/esign"
                title="E-SIGN Consent"
                rel="noreferrer"
              >
                <u>E-SIGN Consent</u>
              </a>
              ,&nbsp;
              <a
                target={'_blank'}
                href="https://meetava.com/cad"
                title="Credit Authorization Disclosure"
                rel="noreferrer"
              >
                <u>Credit Authorization Disclosure</u>
              </a>
              ,&nbsp;
              <a
                target={'_blank'}
                href="https://meetava.com/pgd"
                title="Partner Guarantee Disclosure"
                rel="noreferrer"
              >
                <u>Partner Guarantee Disclosure</u>
              </a>
              ,&nbsp;
              <a
                target={'_blank'}
                href="https://meetava.com/apr"
                title="Representative Example & APR Disclosure"
                rel="noreferrer"
              >
                <u>Representative Example & APR Disclosure</u>
              </a>
              ,&nbsp;
              <a
                target={'_blank'}
                href="https://meetava.com/advertiser"
                title="Advertiser Disclosure"
                rel="noreferrer"
              >
                <u>Advertiser Disclosure</u>
              </a>
              , and&nbsp;
              <a
                target={'_blank'}
                href="https://meetava.com/phone"
                title="Phone Number Disclosure"
                rel="noreferrer"
              >
                <u>Phone Number Disclosure</u>
              </a>
              .<br />
              <br />
              You are also providing written consent under the Fair Credit
              Reporting Act (FCRA) for Ava and its{' '}
              <a
                target={'_blank'}
                href="https://meetava.com/partners"
                title="partners"
                rel="noreferrer"
              >
                <u>partners</u>
              </a>{' '}
              and financial institutions to obtain consumer report information
              from your credit profile.
              <br />
              <br />
              By providing your phone number, you consent to Ava’s{' '}
              <a
                target={'_blank'}
                href="https://meetava.com/terms"
                title="Terms of Service"
                rel="noreferrer"
              >
                <u>Terms of Service</u>
              </a>{' '}
              and to be contacted by Ava and its{' '}
              <a
                target={'_blank'}
                href="https://meetava.com/partners"
                title="partners"
                rel="noreferrer"
              >
                <u>partners</u>
              </a>{' '}
              and their affiliated companies and financial institutions via
              email and/or at the telephone number(s) you have provided above to
              explore various financial products and services you may inquire
              about, including contact through automatic dialing systems,
              artificial or pre-recorded voice messaging, or text message. Data
              rates may apply. Consent is not required as a condition to utilize
              the service. Reply ‘stop’ to opt out.
            </p>
          </Grid>
        </Grid>
      </ScreenWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userData: dispatch.userData.item22,
});

export default connect(mapDispatchToProps, { getUserById })(UserAddress);
