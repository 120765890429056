export const STATES = [
    {
        "name": "Alabama",
        "abbr": "AL",
        "label": "Alabama",
        "code": "AL"
    },
    {
        "name": "Alaska",
        "abbr": "AK",
        "label": "Alaska",
        "code": "AK"
    },
    {
        "name": "Arizona",
        "abbr": "AZ",
        "label": "Arizona",
        "code": "AZ"
    },
    {
        "name": "Arkansas",
        "abbr": "AR",
        "label": "Arkansas",
        "code": "AR"
    },
    {
        "name": "California",
        "abbr": "CA",
        "label": "California",
        "code": "CA"
    },
    {
        "name": "Colorado",
        "abbr": "CO",
        "label": "Colorado",
        "code": "CO"
    },
    {
        "name": "Connecticut",
        "abbr": "CT",
        "label": "Connecticut",
        "code": "CT"
    },
    {
        "name": "Delaware",
        "abbr": "DE",
        "label": "Delaware",
        "code": "DE"
    },
    {
        "name": "Florida",
        "abbr": "FL",
        "label": "Florida",
        "code": "FL"
    },
    {
        "name": "Georgia",
        "abbr": "GA",
        "label": "Georgia",
        "code": "GA"
    },
    {
        "name": "Hawaii",
        "abbr": "HI",
        "label": "Hawaii",
        "code": "HI"
    },
    {
        "name": "Idaho",
        "abbr": "ID",
        "label": "Idaho",
        "code": "ID"
    },
    {
        "name": "Illinois",
        "abbr": "IL",
        "label": "Illinois",
        "code": "IL"
    },
    {
        "name": "Indiana",
        "abbr": "IN",
        "label": "Indiana",
        "code": "IN"
    },
    {
        "name": "Iowa",
        "abbr": "IA",
        "label": "Iowa",
        "code": "IA"
    },
    {
        "name": "Kansas",
        "abbr": "KS",
        "label": "Kansas",
        "code": "KS"
    },
    {
        "name": "Kentucky",
        "abbr": "KY",
        "label": "Kentucky",
        "code": "KY"
    },
    {
        "name": "Louisiana",
        "abbr": "LA",
        "label": "Louisiana",
        "code": "LA"
    },
    {
        "name": "Maine",
        "abbr": "ME",
        "label": "Maine",
        "code": "ME"
    },
    {
        "name": "Maryland",
        "abbr": "MD",
        "label": "Maryland",
        "code": "MD"
    },
    {
        "name": "Massachusetts",
        "abbr": "MA",
        "label": "Massachusetts",
        "code": "MA"
    },
    {
        "name": "Michigan",
        "abbr": "MI",
        "label": "Michigan",
        "code": "MI"
    },
    {
        "name": "Minnesota",
        "abbr": "MN",
        "label": "Minnesota",
        "code": "MN"
    },
    {
        "name": "Mississippi",
        "abbr": "MS",
        "label": "Mississippi",
        "code": "MS"
    },
    {
        "name": "Missouri",
        "abbr": "MO",
        "label": "Missouri",
        "code": "MO"
    },
    {
        "name": "Montana",
        "abbr": "MT",
        "label": "Montana",
        "code": "MT"
    },
    {
        "name": "Nebraska",
        "abbr": "NE",
        "label": "Nebraska",
        "code": "NE"
    },
    {
        "name": "Nevada",
        "abbr": "NV",
        "label": "Nevada",
        "code": "NV"
    },
    {
        "name": "New Hampshire",
        "abbr": "NH",
        "label": "New Hampshire",
        "code": "NH"
    },
    {
        "name": "New Jersey",
        "abbr": "NJ",
        "label": "New Jersey",
        "code": "NJ"
    },
    {
        "name": "New Mexico",
        "abbr": "NM",
        "label": "New Mexico",
        "code": "NM"
    },
    {
        "name": "New York",
        "abbr": "NY",
        "label": "New York",
        "code": "NY"
    },
    {
        "name": "North Carolina",
        "abbr": "NC",
        "label": "North Carolina",
        "code": "NC"
    },
    {
        "name": "North Dakota",
        "abbr": "ND",
        "label": "North Dakota",
        "code": "ND"
    },
    {
        "name": "Ohio",
        "abbr": "OH",
        "label": "Ohio",
        "code": "OH"
    },
    {
        "name": "Oklahoma",
        "abbr": "OK",
        "label": "Oklahoma",
        "code": "OK"
    },
    {
        "name": "Oregon",
        "abbr": "OR",
        "label": "Oregon",
        "code": "OR"
    },
    {
        "name": "Pennsylvania",
        "abbr": "PA",
        "label": "Pennsylvania",
        "code": "PA"
    },
    {
        "name": "Rhode Island",
        "abbr": "RI",
        "label": "Rhode Island",
        "code": "RI"
    },
    {
        "name": "South Carolina",
        "abbr": "SC",
        "label": "South Carolina",
        "code": "SC"
    },
    {
        "name": "South Dakota",
        "abbr": "SD",
        "label": "South Dakota",
        "code": "SD"
    },
    {
        "name": "Tennessee",
        "abbr": "TN",
        "label": "Tennessee",
        "code": "TN"
    },
    {
        "name": "Texas",
        "abbr": "TX",
        "label": "Texas",
        "code": "TX"
    },
    {
        "name": "Utah",
        "abbr": "UT",
        "label": "Utah",
        "code": "UT"
    },
    {
        "name": "Vermont",
        "abbr": "VT",
        "label": "Vermont",
        "code": "VT"
    },
    {
        "name": "Virginia",
        "abbr": "VA",
        "label": "Virginia",
        "code": "VA"
    },
    {
        "name": "Washington",
        "abbr": "WA",
        "label": "Washington",
        "code": "WA"
    },
    {
        "name": "West Virginia",
        "abbr": "WV",
        "label": "West Virginia",
        "code": "WV"
    },
    {
        "name": "Wisconsin",
        "abbr": "WI",
        "label": "Wisconsin",
        "code": "WI"
    },
    {
        "name": "Wyoming",
        "abbr": "WY",
        "label": "Wyoming",
        "code": "WY"
    }
]