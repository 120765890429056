import React, { Component } from 'react';

import { Typography, Box, Badge, Avatar, Button } from "@mui/material";

import { withStyles } from "@mui/styles";

import WorriedFace from '../../../../assets/ai/WorriedFace.svg';
import PlaidSetup from "../PlaidSetup";
import BaseComponent from '../../../BaseComponent';

const styles = (theme) => ({

    confirmPlanBox: {
        paddingTop: 60,
        display: 'grid',
        justifyItems: 'center',
    },
    mainHeader: {
        fontSize: 48,
        fontWeight: 600,
        color: "#363636",
        textAlign: "center",
        marginTop: 40,
        [theme.breakpoints.down("md")]: {
            fontSize: 38,
            lineHeight: 1.2
        },
    },
    typoHeader1: {
        fontSize: 22,
        fontWeight: 400,
        color: "#363636",
        textAlign: "center",
        marginTop: 20,
        [theme.breakpoints.down("md")]: {
            fontSize: 20,
        },
    },
    typoHeader2: {
        fontSize: 21,
        fontWeight: 700,
        color: "#363636",
        textAlign: "center",
    },
    emojiStyle: {
        border: '30px solid rgba(235, 87, 87, 0.07)',
        background: 'rgba(235, 87, 87, 0.07)',
        borderRadius: '50%'
    },
    avatarStyle: {
        border: '30px solid rgba(235, 87, 87, 0.07)',
        background: 'rgba(235, 87, 87, 0.07)',
        borderRadius: '50%',
        height: 66,
        width: 66,
    },
    checkMark: {
        color: '#fff',
        zIndex: 1,
        backgroundColor: '#7B57E2',
        borderRadius: '50%',
    },
    connectAccBtn: {
        boxShadow: "0px 14px 14px rgba(198, 182, 244, 0.4)",
        borderRadius: 40,
        width: 240,
        fontSize: 12,
        fontWeight: 500,
        padding: "15px 0px",
        marginTop: 40,
        marginBottom: 40
    },
});

class PlaidError extends BaseComponent {
    constructor(props) {
        super(props, 'plaid_error');

        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const plaidLinkToken = this.props.entireData.plaidLinkToken;
        const { classes, plaidErrMsg } = this.props;
        return (
            <Box className={classes.confirmPlanBox}>
                <Typography className={classes.mainHeader}>
                    Something went wrong
                </Typography>

                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    style={{ marginTop: 24, marginBottom: 24 }}
                >
                    <Avatar alt="Remy Sharp" className={classes.avatarStyle} src={WorriedFace} />
                </Badge>

                <Typography >
                    It looks like we’re having some trouble linking that account.
                </Typography>
                <Typography className={classes.typoHeader1}>
                    {plaidErrMsg}
                </Typography>

                <Button
                    className={classes.connectAccBtn}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={this.props.handleConnectPlaidAcc}
                    id='PlaidFailiure-LinkANewAccount'
                >
                    Link Different Account
                </Button>
                {plaidLinkToken &&
                    <PlaidSetup plaidLinkToken={plaidLinkToken}
                        handleCreatePlaidLink={this.props.handleCreatePlaidLink} />
                }
            </Box>
        );
    }
}

export default withStyles(styles)(PlaidError);
