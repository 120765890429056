import React, { Component } from "react";
import "./ConfirmInfo.scss";
//import TagManager from 'react-gtm-module';

import {
  Typography,
  Box,
  Button,
  CircularProgress
} from "@mui/material";

import moment from "moment";

import { connect } from "react-redux";
import { getUserById } from "../../../../_actions/userActions";
import { LtePlusMobiledataTwoTone } from "@mui/icons-material";
import BaseComponent from '../../../BaseComponent';


class ConfirmInfo extends BaseComponent {
  constructor(props) {
    super(props, 'confirm_info');


    // const result =  TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'ga4_abed_custom_page_view',
    //     pagePath: 'register/confirm_info'
    //   }
    // });

    this.state = {
      loading: true,
      ssn: ""
    };
  }

  componentDidMount = async() => {
    window.scrollTo(0, 0);
    await this.props.getUserById();
    const res = this.props.userData;    
    if(res.status === 200 && res.data.ssn !== null) {
      let ssnRef = res.data.ssn.replace(/\D/g, '');
      ssnRef = ssnRef.replace(/^(\d{3})/, '$1-');
      ssnRef = ssnRef.replace(/-(\d{2})/, '-$1-');
      ssnRef = ssnRef.replace(/(\d)-(\d{4}).*/, '$1-$2');
      await this.setState({ ssn: ssnRef, loading: false })
    }
  }

  render() {
    const { isMobileView, disableSubmitBtn } = this.props;
    const info = this.props.userData.data;
    return (
      <>{!this.state.loading ?
      <Box className="confirmAlignment">
        <Typography variant="h6" className="infoHeader1" style={{ marginTop: 40 }}>
            Confirm your information
        </Typography>
        <Typography variant="body1" className="infoText1">
            Please review the following details. They need to match your government issued ID.
        </Typography>
        <Typography variant="body2" className={'typoHeader'} style={{ marginTop: 35 }}>
          Name
        </Typography>
        <Typography variant="body1" className={'typoContent'}>
          {info.firstName} {info.lastName}
        </Typography>
        <Typography variant="body2" className={'typoHeader'}>
          Date of Birth
        </Typography>
        <Typography variant="body1" className={'typoContent'}>
          {moment(info.dob).format("MM/DD/YYYY")}
        </Typography>
        <Typography variant="body2" className={'typoHeader'}>
            SOCIAL SECURITY
        </Typography>
        <Typography variant="body1" className={'typoContent'}>
          {this.state.ssn}
        </Typography>
        <Typography variant="body2" className={'typoHeader'}>
            ADDRESS
        </Typography>
        <Typography variant="body1" className={'typoContent'}>
          {info.address.line1}<br/>
          {info.address.city},&nbsp;{info.address.state},&nbsp;{info.address.country}&nbsp;{info.address.zip}<br/>
          {info.address.line2}
        </Typography>
        <Button
          className="continueBtn"
          variant="contained"
          color="primary"
          size="large"
          style={{ pointerEvents: disableSubmitBtn ? 'none' : null, width: isMobileView ? "100%" : "400px", marginTop: 20 }}
          onClick={(e) => this.props.handleSubmitKYC(e)}
        >
          Continue
        </Button>
        <Button
          className="continueBtn"
          variant="outlined"
          color="primary"
          size="large"
          style={{ width: isMobileView ? "100%" : "400px", marginTop: 20 }}
          onClick={(e) => this.props.editInfoFlow(e, 101)}
        >
          Edit
        </Button>
      </Box>:
      <Box className="confirmInfoLoading">
      <CircularProgress />
    </Box>}</>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  userData: dispatch.userData.item22,
});

export default connect(mapDispatchToProps, { getUserById })(ConfirmInfo);

