import React, { Component } from 'react';
import './LandingPage.scss';

import {
  Button,
  Grid,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Input,
  FormHelperText,
  FormControl,
} from '@mui/material';

import {
  signInWithPopup,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  signInWithEmailLink,
  fetchSignInMethodsForEmail,
  sendSignInLinkToEmail,
} from 'firebase/auth';

import { connect } from 'react-redux';
import {
  createUser,
  getUserStatus,
  getReferralData,
} from '../../../_actions/userActions';
import { landingContent } from '../../../_actions/goalsActions';

import CarouselList from '../_CarouselList/CarouselList';

import { authentication } from '../../../services/firebase-config';
import { getAnalytics, logEvent } from 'firebase/analytics';

import LogRocket from 'logrocket';
import { AFFILIATE_DATA } from '../../../Constants/AppConstants';
import { FB_PIXEL_EVENTS } from '../../../Constants/AppConstants';
import { fbqTrackCustomEvent } from '../../../services/facebookPixelTracking';
import { singularSdk } from 'singular-sdk';
import { getMoneyBackGuarantee } from '../../../services/firebase-config';
import Partners from '../../Common/Partners/Partners';
import { submitUserInfo, termsAndCons } from '../../../_actions/userActions';
import { OAuthProvider } from 'firebase/auth';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentPage,
  segmentTrack,
} from '../../../services/segment';

LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
const auth = authentication;
const analytics = getAnalytics();

const actionCodeSettings = {
  url: `${window.location.origin}/login`,
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios',
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12',
  },
};

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBackdrop: false,
      openSnackBar: false,
      snackBarTitle: '',
      snackBarMsg: '',
      entireData: '',
      showBanner: false,
      show_money_back_guarantee: false,
      money_back_guarantee_string: null,
      firstName: '',
      lastName: '',
      emailAddress: '',
      acceptedPolicy: true,
      firstNameError: false,
      lastNameError: false,
      emailAddressError: false,
      disableSubmitBtn: true,
    };
  }

  handleReferralFlow = async (data) => {
    const timeout = this.props.isMobileView ? 5000 : 15000;

    if (data?.data_parsed?.campaign_id) {
      await this.props.getReferralData(data?.data_parsed);
      if (this.props.campaign && this.props.campaign.loaded) {
        localStorage.setItem(
          AFFILIATE_DATA,
          JSON.stringify(this.props.campaign)
        );
        this.setState({ showBanner: true }, () => {
          // setTimeout(() => {
          //   this.setState({ showBanner: false });
          // }, timeout);
        });
      }
    }
  };

  handleChange = async (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    if (name === 'acceptedPolicy') {
      await this.setState({ [name]: !this.state[name] });
    } else {
      await this.setState({ [name]: value });
    }
    if (
      this.state.firstName !== '' &&
      this.state.lastName !== '' &&
      this.state.emailAddress !== ''
    ) {
      this.setState({ disableSubmitBtn: false });
    } else {
      this.setState({ disableSubmitBtn: true });
    }
  };

  componentDidMount = async () => {
    segmentPage({
      userId: null,
      name: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.PAGE_NAME,
      properties: {
        page_category: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.CATEGORY,
        name: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.PAGE_NAME,
        page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
        category_flow: SEGMENT_PAGE_CATEGORY.CATEGORY_FLOW.FLOW_1,
        platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
      },
    });
    try {
      const moneyBackGuarantee = await getMoneyBackGuarantee();
      if (moneyBackGuarantee) {
        const { show_money_back_guarantee, money_back_guarantee_string } =
          moneyBackGuarantee;
        this.setState({
          show_money_back_guarantee,
          money_back_guarantee_string,
        });
      }
    } catch (e) {
      console.log(e);
    }
    singularSdk.pageVisit();
    window.scrollTo(0, 0);
    LogRocket.getSessionURL((sessionURL) => {});
    // ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    if (isSignInWithEmailLink(auth, window.location.href)) {
      this.checkEmailSignInLink();
    } else {
      let self = this;
      // localStorage.removeItem(AFFILIATE_DATA);
      self.handleReferralFlow(this.props.branchData);
    }
    await logEvent(analytics, 'web_user_landed_to_loginpage');
  };

  checkEmailSignInLink() {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      this.setState({ openBackdrop: true });
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {
          const data = {
            id: result.user.uid,
            email: result.user.email,
          };
          this.setTokens(result);
          // call update details

          let campaign = localStorage.getItem(AFFILIATE_DATA);
          if (campaign) {
            campaign = JSON.parse(campaign);
          }
          await this.props.getUserStatus(
            result.user.uid,
            result.user.accessToken
          );
          let affiliateData = null;
          if (
            campaign &&
            campaign.campaignData &&
            campaign.campaignData.campaign_id &&
            campaign.campaignData.user_id
          ) {
            affiliateData = {
              campaignId: campaign.campaignData.campaign_id,
              referrerId: campaign.campaignData.user_id,
            };
          }

          const res2 = this.props.userStatus;
          if (res2 && res2.status === 200) {
            this.setState({ openBackdrop: false });
            logEvent(analytics, 'web_emailSignIn_success', {
              name: result.user.displayName,
              email: result.user.email,
            });
            await this.props.history.push({ pathname: `/register` });
            //window.localStorage.removeItem('emailForSignIn');
          } else {
            await this.props.createUser(
              data,
              result.user.accessToken,
              affiliateData
            );
            const res3 = this.props.userCreated;
            if (res3 && res3.status === 200) {
              this.setState({ openBackdrop: false });
              logEvent(analytics, 'web_user_created');
              logEvent(analytics, 'web_emailSignIn_success', {
                name: result.user.displayName,
                email: result.user.email,
              });
              await this.props.history.push({ pathname: `/register` });
              //window.localStorage.removeItem('emailForSignIn');
            }
          }
        })
        .catch((error) => {
          this.setState({ openBackdrop: false });
          logEvent(analytics, 'web_emailSignIn_expired');
          let errRes = JSON.stringify(error);
          errRes = JSON.parse(errRes);
          if (errRes.code === 'auth/invalid-action-code') {
            this.displayEmailLinkSnackbar();
          }
        });
    }
  }

  setTokens = (res) => {
    localStorage.setItem('accessToken', res.user.accessToken);
    localStorage.setItem('userId', res.user.uid);
    LogRocket.identify(res.user.uid, {
      name: res.user.displayName,
      email: res.user.email,
      environment: process.env.REACT_APP_ENV,
    });
  };

  signInWithApple = async () => {
    fbqTrackCustomEvent(FB_PIXEL_EVENTS.CONTINUE_WITH_APPLE, {});
    segmentTrack(null, SEGMENT_PAGE_CATEGORY.EVENT.BUTTON_CLICKED, {
      properties: {
        page_name: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.PAGE_NAME,
        button_text: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.APPLE_BUTTON_TEXT,
        button_color:
          SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.APPLE_BUTTON_COLOR,
        button_rgb: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.APPLE_RGB,
        button_name: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.APPLE_BUTTON_NAME,
        platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
      },
    });
    this.setState({ openBackdrop: true });
    await logEvent(analytics, 'web_googleSignIn_clicked');
    // const provider = new GoogleAuthProvider();
    const provider = new OAuthProvider('apple.com');

    signInWithPopup(auth, provider)
      .then(async (res) => {
        // The signed-in user info.
        // const user = res.user;

        // Apple credential
        // const credential = OAuthProvider.credentialFromResult(res);
        // const accessToken = credential.accessToken;
        // const idToken = credential.idToken;
        if (res.user) {
          // ReactGA.pageview('/userCreated');
          const data = {
            id: res.user.uid,
            email: res.user.email,
          };
          await this.setTokens(res);
          await this.props.getUserStatus(res.user.uid, res.user.accessToken);
          const res2 = this.props.userStatus;
          if (res2 && res2.status === 200) {
            this.setState({ openBackdrop: false });
            logEvent(analytics, 'web_googleSignIn_success', {
              name: res.user.displayName,
              email: res.user.email,
            });
            await this.props.history.push({ pathname: `/register` });
          } else {
            let campaign = localStorage.getItem(AFFILIATE_DATA);
            if (campaign) {
              campaign = JSON.parse(campaign);
            }
            let affiliateData = null;
            if (
              campaign &&
              campaign.campaignData &&
              campaign.campaignData.campaign_id &&
              campaign.campaignData.user_id
            ) {
              affiliateData = {
                campaignId: campaign.campaignData.campaign_id,
                referrerId: campaign.campaignData.user_id,
              };
            }
            await this.props.createUser(
              data,
              res.user.accessToken,
              affiliateData
            );

            const res3 = this.props.userCreated;
            if (res3 && res3.status === 200) {
              this.setState({ openBackdrop: false });
              logEvent(analytics, 'web_user_created');
              logEvent(analytics, 'web_googleSignIn_success', {
                name: res.user.displayName,
                email: res.user.email,
              });
              await this.props.history.push({ pathname: `/register` });
            }
          }
        }

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The credential that was used.
        // const credential = OAuthProvider.credentialFromError(error);
        this.setState({ openBackdrop: false });
        logEvent(analytics, 'web_googleSignIn_failed');
        // ...
      });
  };

  signInWithGoogle = async () => {
    fbqTrackCustomEvent(FB_PIXEL_EVENTS.CONTINUE_WITH_GMAIL, {});
    segmentTrack(null, SEGMENT_PAGE_CATEGORY.EVENT.BUTTON_CLICKED, {
      properties: {
        page_name: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.PAGE_NAME,
        button_text:
          SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.PLAYSTORE_BUTTON_TEXT,
        button_color:
          SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.PLAYSTORE_BUTTON_COLOR,
        button_rgb: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.PLAYSTORE_RGB,
        button_name:
          SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.PLAYSTORE_BUTTON_NAME,
        platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
      },
    });
    this.setState({ openBackdrop: true });
    await logEvent(analytics, 'web_googleSignIn_clicked');
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then(async (res) => {
        if (res.user) {
          // ReactGA.pageview('/userCreated');
          const data = {
            id: res.user.uid,
            email: res.user.email,
          };
          await this.setTokens(res);
          await this.props.getUserStatus(res.user.uid, res.user.accessToken);
          const res2 = this.props.userStatus;
          if (res2 && res2.status === 200) {
            this.setState({ openBackdrop: false });
            logEvent(analytics, 'web_googleSignIn_success', {
              name: res.user.displayName,
              email: res.user.email,
            });
            await this.props.history.push({ pathname: `/register` });
          } else {
            let campaign = localStorage.getItem(AFFILIATE_DATA);
            if (campaign) {
              campaign = JSON.parse(campaign);
            }
            let affiliateData = null;
            if (
              campaign &&
              campaign.campaignData &&
              campaign.campaignData.campaign_id &&
              campaign.campaignData.user_id
            ) {
              affiliateData = {
                campaignId: campaign.campaignData.campaign_id,
                referrerId: campaign.campaignData.user_id,
              };
            }
            await this.props.createUser(
              data,
              res.user.accessToken,
              affiliateData
            );

            const res3 = this.props.userCreated;
            if (res3 && res3.status === 200) {
              this.setState({ openBackdrop: false });
              logEvent(analytics, 'web_user_created');
              logEvent(analytics, 'web_googleSignIn_success', {
                name: res.user.displayName,
                email: res.user.email,
              });
              await this.props.history.push({ pathname: `/register` });
            }
          }
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        logEvent(analytics, 'web_googleSignIn_failed');
      });
  };

  displayEmailLinkSnackbar = (x) => {
    this.setState({
      openSnackBar: true,
      snackBarTitle: 'ERROR',
      snackBarMsg: 'Email link has expired / consumed.',
    });
    setTimeout(() => {
      this.closeEmailLinkSnackbar();
    }, 8000);
  };

  closeEmailLinkSnackbar = () => {
    this.setState({
      snackBarTitle: '',
      snackBarMsg: '',
      openSnackBar: false,
    });
  };

  onDataSubmit = async (event) => {
    this.setState({ openBackdrop: true });
    event.preventDefault();
    try {
      const s = this.state;
      this.setState({
        firstNameError: false,
        lastNameError: false,
        emailAddressError: false,
      });
      let regEmailPattern =
        /^([A-Za-z0-9_+\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      let fPattern = new RegExp("^(['a-zA-Z.]{2,})$");
      let emailPattern = new RegExp(regEmailPattern);
      let fn = await fPattern.test(s.firstName);
      let ln = await fPattern.test(s.lastName);
      let em = await emailPattern.test(s.emailAddress);
      if (!fn) {
        this.setState({ firstNameError: true });
      }
      if (!ln) {
        this.setState({ lastNameError: true });
      }
      if (!em) {
        this.setState({ emailAddressError: true });
      }

      if (fn && ln && em) {
        const data = {
          id: localStorage.getItem('userId'),
          version: '1',
        };

        const data2 = {
          name: {
            firstName: s.firstName,
            lastName: s.lastName,
            emailAddress: s.emailAddress,
          },
        };
        localStorage.setItem('emailForSignIn', s.emailAddress);
        localStorage.setItem('firstName', s.firstName);
        localStorage.setItem('lastName', s.lastName);
        this.emailLogin(event, s.emailAddress, 'ie', s.firstName, s.lastName);
        fbqTrackCustomEvent(FB_PIXEL_EVENTS.CONTINUE_WITH_EMAIL, {
          ...data2,
          ...data,
        });
        segmentTrack(null, SEGMENT_PAGE_CATEGORY.EVENT.FORM_SUBMITTED, {
          properties: {
            page_name: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.PAGE_NAME,
            form_name: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.FORM_NAME,
            form_category:
              SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.FORM_CATEGORY,
            input_type: SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.INPUT_TYPE,
            number_of_fields:
              SEGMENT_PAGE_CATEGORY.USER_REGISTRATION.NUMBER_OF_FIELDS,
            email: s.emailAddress,
            firstName: s.firstName,
            lastName: s.lastName,
            advertiser_tracking_enabled: 0,
            application_tracking_enabled: 0,
            platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
          },
        });
        localStorage.setItem('emailAlreadySent', 'true');
        await this.props.history.push({ pathname: `/email_login` });
      }
    } catch (e) {
      console.log('something went wrong', e);
    } finally {
      this.setState({ openBackdrop: false });
    }
  };

  emailLogin = async (event, email, refComp, firstName, lastName) => {
    event.preventDefault();
    this.setState({ openBackdrop: true });
    logEvent(analytics, 'web_emailSignIn_clicked');
    if (refComp === 'ie') {
      logEvent(analytics, 'web_emailSignIn_loginlink_sent', { email: email });
      this.setState({ email: email, openCheckMail: true });
    } else {
      logEvent(analytics, 'web_emailSignIn_resendLoginlink_sent', {
        email: email,
      });
      this.setState({ email: email });
    }
    await fetchSignInMethodsForEmail(auth, email).then((signInMethods) => {
      if (signInMethods.length === 0) {
        this.setState({ newUser: true });
      } else {
        this.setState({ newUser: false });
      }
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          this.setState({ openCheckMail: true, openBackdrop: false });
          logEvent(analytics, 'web_emailSignIn_loginlink_success', {
            email: email,
          });
          // window.localStorage.setItem('emailForSignIn', email);
          // window.localStorage.setItem('firstName', firstName);
          // window.localStorage.setItem('lastName', lastName);
        })
        .catch((error) => {
          this.setState({ openCheckMail: false, openBackdrop: false });
          logEvent(analytics, 'web_emailSignIn_loginlink_failed');
        });
    });
  };

  render() {
    const { isMobileView, campaign } = this.props;
    const {
      openBackdrop,
      showBanner,
      show_money_back_guarantee,
      money_back_guarantee_string,
      firstName,
      lastName,
      emailAddress,
      disableSubmitBtn,
      firstNameError,
      lastNameError,
      emailAddressError,
    } = this.state;

    return (
      <>
        <Grid container className={'gridWrapper'}>
          <Grid item xs={12} sm={12} md={6} lg={6} size={12}>
            <div className={'rightGridWrapper'}>
              <div className={'rightGrid'}>
                {show_money_back_guarantee && (
                  <div className="moneyback-wrap show-mobile">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="28"
                        s
                        fill="none"
                        viewBox="0 0 24 28"
                      >
                        <path
                          fill="#5F2FCB"
                          fillRule="evenodd"
                          d="M10.781.637c.375-.256.769-.506.875-.556.363-.162.656-.062 1.481.506 2.182 1.488 4.557 2.55 6.87 3.057.95.212 1.487.287 2.512.343.925.057 1.037.094 1.319.476.13.168.13.212.13 5.743 0 5.475-.006 5.588-.143 6.463-.706 4.475-3.075 7.143-8.95 10.075C13.031 27.663 12.262 28 12 28c-.356 0-3.281-1.387-4.875-2.313-1.731-1.012-2.806-1.806-3.881-2.875-1.769-1.75-2.657-3.53-3.069-6.143-.137-.875-.144-.988-.144-6.463 0-5.531 0-5.575.132-5.743.28-.382.393-.42 1.318-.476 1.856-.106 3.381-.45 5.175-1.156A20.763 20.763 0 0010.781.637zm7.375 8.27C16.87 7.374 15.1 6.38 13.181 6.1a9.528 9.528 0 00-2.056-.056c-1.169.125-2.55.631-3.531 1.287-1.138.763-2.144 1.9-2.732 3.075-2.443 4.881.6 10.713 6.013 11.507 2.456.356 5.012-.488 6.731-2.238.581-.594.713-.75 1.063-1.269 1.931-2.875 1.718-6.85-.513-9.5zm-5.418 3.956c.95-.957 1.8-1.763 1.887-1.8.231-.094.731-.038.925.106.381.281.531.731.394 1.156-.05.15-.707.844-2.275 2.406-1.914 1.909-2.261 2.264-2.62 2.274-.087.003-.175-.015-.287-.036-.193-.031-.418-.225-1.462-1.269-1.325-1.325-1.369-1.4-1.269-1.931.057-.306.432-.681.738-.738.494-.093.644-.012 1.469.8l.762.757 1.738-1.725z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div className="content">
                      <p>{money_back_guarantee_string}</p>
                    </div>
                  </div>
                )}
                <section className="rightGrid-content">
                  <form onSubmit={this.onDataSubmit}>
                    <Grid container rowSpacing={2} columnSpacing={1}>
                      <Grid item xs={12}>
                        {(() => {
                          if (
                            showBanner &&
                            campaign &&
                            campaign.referralData &&
                            campaign.referralData.welcomeScreenTitle
                          ) {
                            return (
                              <>
                                <h2 className="space-top">
                                  {campaign.referralData.welcomeScreenTitle}
                                </h2>
                                <p className="sub-head-ref">
                                  {campaign.referralData.welcomeScreenBody}
                                </p>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <h2 className="space-top">
                                  Boost your credit fast with Ava
                                </h2>
                              </>
                            );
                          }
                        })()}
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          startIcon={
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="none"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  fill="#4285F4"
                                  d="M27.745 16.27c0-.79-.07-1.54-.19-2.27h-11.3v4.51h6.47c-.29 1.48-1.14 2.73-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82z"
                                ></path>
                                <path
                                  fill="#34A853"
                                  d="M16.255 28c3.24 0 5.95-1.08 7.93-2.91l-3.86-3c-1.08.72-2.45 1.16-4.07 1.16-3.13 0-5.78-2.11-6.73-4.96h-3.98v3.09c1.97 3.92 6.02 6.62 10.71 6.62z"
                                ></path>
                                <path
                                  fill="#FBBC05"
                                  d="M9.525 18.29c-.25-.72-.38-1.49-.38-2.29s.14-1.57.38-2.29v-3.09h-3.98a11.86 11.86 0 000 10.76l3.98-3.09z"
                                ></path>
                                <path
                                  fill="#EA4335"
                                  d="M16.255 8.75c1.77 0 3.35.61 4.6 1.8l3.42-3.42C22.205 5.19 19.495 4 16.255 4c-4.69 0-8.74 2.7-10.71 6.62l3.98 3.09c.95-2.85 3.6-4.96 6.73-4.96z"
                                ></path>
                              </svg>
                            </>
                          }
                          className="btn-secondary"
                          fullWidth={true}
                          onClick={this.signInWithGoogle}
                          id="Register-ContinueWithGoogle"
                        >
                          Continue with Google
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          startIcon={
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="none"
                                viewBox="0 0 32 32"
                              >
                                <mask
                                  style={{ maskType: 'alpha' }}
                                  width="32"
                                  height="32"
                                  x="0"
                                  y="0"
                                  maskUnits="userSpaceOnUse"
                                >
                                  <path fill="#D9D9D9" d="M0 0H32V32H0z"></path>
                                </mask>
                                <path
                                  fill="#000"
                                  d="M20.43 4.033c-.37.089-1.06.342-1.453.53-1.95.932-2.836 2.23-2.916 4.275-.023.534-.014.646.038.646.154-.004.923-.197 1.321-.332 1.167-.399 2.246-1.186 2.72-1.997.44-.76.641-1.875.52-2.911-.033-.244-.047-.253-.23-.211zM19.797 9.817c-.534.061-.933.178-1.828.535-1.73.703-2.025.712-3.445.13-.605-.248-1.088-.421-1.383-.487-.994-.23-2.283-.089-3.211.357-1.458.693-2.794 2.273-3.347 3.96-.717 2.194-.366 5.405.919 8.415 1.115 2.606 2.855 4.664 4.35 5.137.708.225 1.162.164 2.508-.333 1.162-.426 1.504-.52 2.086-.548.6-.033.942.051 2.132.506 1.97.76 2.377.703 3.914-.567 1.013-.835 1.52-1.524 2.386-3.263.352-.703.872-1.903.872-2.001 0-.033-.154-.136-.347-.235-1.34-.689-2.268-1.903-2.732-3.571-.16-.577-.202-1.613-.09-2.17.249-1.22 1.055-2.424 2.208-3.296.14-.108.258-.216.258-.24 0-.027-.248-.294-.548-.594-.553-.549-1.055-.924-1.632-1.21-.82-.412-2.1-.633-3.07-.525z"
                                ></path>
                              </svg>
                            </>
                          }
                          className="btn-secondary"
                          fullWidth={true}
                          id="Register-ContinueWithApple"
                          onClick={this.signInWithApple}
                        >
                          Continue with Apple
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <h3 className="lbl-register">
                          <span> Or, register with your email </span>
                        </h3>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          error={firstNameError}
                          style={{ width: '100%' }}
                        >
                          <label for="firstName" className="lbl">
                            First Name
                          </label>
                          <Input
                            id="firstName"
                            name="firstName"
                            className="textfield"
                            placeholder="Jane"
                            value={firstName}
                            onChange={this.handleChange}
                            inputProps={{
                              name: 'firstName',
                            }}
                          />
                          {firstNameError && (
                            <FormHelperText className="error">
                              Please enter valid first name
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          error={lastNameError}
                          style={{ width: '100%' }}
                        >
                          <label for="lastName" className="lbl">
                            Last Name
                          </label>

                          <Input
                            id="lastName"
                            name="lastName"
                            value={lastName}
                            className="textfield"
                            onChange={this.handleChange}
                            placeholder="Doe"
                            inputProps={{
                              name: 'lastName',
                            }}
                          />
                          {lastNameError && (
                            <FormHelperText className="error">
                              Please enter valid last name
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl
                          error={emailAddressError}
                          style={{ width: '100%' }}
                        >
                          <label for="Email address" className="lbl">
                            Email address
                          </label>
                          <Input
                            id="emailAddress"
                            name="emailAddress"
                            value={emailAddress}
                            className="textfield"
                            onChange={this.handleChange}
                            placeholder="email@me.com"
                            inputProps={{
                              name: 'emailAddress',
                            }}
                          />
                          {emailAddressError && (
                            <FormHelperText className="error">
                              Please enter valid email address
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Button
                          fullWidth={true}
                          type="submit"
                          disabled={disableSubmitBtn}
                          className="btn-primary mgr-top-8"
                          id="Register-ContinueWithEmail"
                        >
                          Continue
                        </Button>
                        <p
                          className="link-account"
                          onClick={() => {
                            segmentTrack(
                              null,
                              SEGMENT_PAGE_CATEGORY.EVENT.BUTTON_CLICKED,
                              {
                                properties: {
                                  page_name:
                                    SEGMENT_PAGE_CATEGORY.USER_REGISTRATION
                                      .PAGE_NAME,
                                  button_text:
                                    SEGMENT_PAGE_CATEGORY.USER_REGISTRATION
                                      .ALREADY_HAVE_AN_ACCOUNT_BUTTON_TEXT,
                                  button_color:
                                    SEGMENT_PAGE_CATEGORY.USER_REGISTRATION
                                      .ALREADY_HAVE_AN_ACCOUNT_BUTTON_COLOR,
                                  button_rgb:
                                    SEGMENT_PAGE_CATEGORY.USER_REGISTRATION
                                      .ALREADY_HAVE_AN_ACCOUNT_RGB,
                                  button_name:
                                    SEGMENT_PAGE_CATEGORY.USER_REGISTRATION
                                      .ALREADY_HAVE_AN_ACCOUNT_BUTTON_NAME,
                                  platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
                                },
                              }
                            );
                            this.props.history.push('/email_login');
                          }}
                        >
                          Already have an account?
                        </p>
                      </Grid>
                    </Grid>
                  </form>
                </section>
                {/* 
                {showBanner &&
                  campaign &&
                  campaign.referralData &&
                  campaign.referralData.welcomeScreenTitle && (
                    <div className="noitficationBar">
                      <div className="column">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="none"
                          viewBox="0 0 32 32"
                        >
                          <g clipPath="url(#clip0_13_575)">
                            <path
                              fill="#FFCC4D"
                              d="M32 16c0 8.836-7.164 16-16 16S0 24.836 0 16 7.164 0 16 0s16 7.164 16 16z"
                            ></path>
                            <path
                              fill="#664500"
                              d="M6.222 18.9c0 3.524 4.085 8 9.778 8 5.692 0 9.778-4.444 9.778-8 0 0-9.185 2.45-19.556 0z"
                            ></path>
                            <path
                              fill="#5D9040"
                              d="M10.667 20.447v6.188c0 2.666 1.777 5.333 5.333 5.333s5.333-2.667 5.333-5.333v-6.151c-5.592 1.148-10.666-.037-10.666-.037z"
                            ></path>
                            <path
                              fill="#664500"
                              d="M10.919 10.96c-.672-.26-1.308-.505-1.308-.86 0-.586.786-.616.944-.616.555 0 .832.208 1.075.392.187.14.38.287.65.287.501 0 .73-.353.73-.681 0-.654-.802-1.117-1.652-1.31v-.35a.835.835 0 00-1.67 0v.379c-1.016.296-1.64 1.043-1.64 1.986 0 1.166 1.15 1.644 2.164 2.065.727.303 1.413.588 1.413 1.033 0 .326-.41.678-1.07.678-.666 0-1.048-.267-1.386-.502-.233-.162-.454-.316-.717-.316-.382 0-.68.322-.68.732 0 .604.827 1.206 1.917 1.417v.418a.835.835 0 001.67 0l-.005-.443c1.152-.28 1.833-1.086 1.833-2.185 0-1.248-1.257-1.734-2.268-2.124zm12.809 2.139c0-1.248-1.258-1.734-2.269-2.124-.671-.26-1.306-.505-1.306-.86 0-.586.786-.616.943-.616.555 0 .831.208 1.076.392.187.141.38.287.648.287.503 0 .731-.353.731-.681 0-.655-.802-1.117-1.651-1.31v-.349a.836.836 0 00-1.67 0v.379c-1.017.296-1.64 1.043-1.64 1.986 0 1.166 1.149 1.644 2.163 2.065.726.303 1.412.588 1.412 1.033 0 .326-.408.678-1.068.678-.667 0-1.05-.267-1.386-.502-.234-.162-.454-.316-.716-.316-.384 0-.682.322-.682.732 0 .604.827 1.206 1.916 1.417v.418a.834.834 0 001.67 0l-.002-.443c1.15-.281 1.83-1.087 1.83-2.186z"
                            ></path>
                            <path
                              fill="#fff"
                              d="M18.64 27.617c0-1.216-1.226-1.69-2.21-2.07-.656-.253-1.275-.493-1.275-.838 0-.572.765-.6.919-.6.541 0 .81.203 1.049.382.182.137.371.28.634.28.488 0 .712-.345.712-.664 0-.638-.782-1.088-1.611-1.278v-.34a.815.815 0 00-1.629 0v.369c-.99.288-1.599 1.018-1.599 1.937 0 1.136 1.121 1.602 2.11 2.013.708.294 1.377.573 1.377 1.006 0 .318-.399.66-1.043.66-.65 0-1.022-.259-1.351-.49-.227-.157-.442-.308-.698-.308-.372 0-.665.315-.665.715 0 .59.806 1.175 1.869 1.382v.407a.814.814 0 001.628 0l-.003-.431c1.123-.275 1.786-1.061 1.786-2.132z"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_13_575">
                              <path fill="#fff" d="M0 0H32V32H0z"></path>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="column">
                        <h2>{campaign.referralData.welcomeScreenTitle}</h2>
                        <p>{campaign.referralData.welcomeScreenBody}</p>
                      </div>
                    </div>
                  )} */}
              </div>
              <Partners />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            size={12}
            className="right-container"
          >
            {show_money_back_guarantee && (
              <div className="moneyback-wrap show-desktop">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="28"
                    s
                    fill="none"
                    viewBox="0 0 24 28"
                  >
                    <path
                      fill="#5F2FCB"
                      fillRule="evenodd"
                      d="M10.781.637c.375-.256.769-.506.875-.556.363-.162.656-.062 1.481.506 2.182 1.488 4.557 2.55 6.87 3.057.95.212 1.487.287 2.512.343.925.057 1.037.094 1.319.476.13.168.13.212.13 5.743 0 5.475-.006 5.588-.143 6.463-.706 4.475-3.075 7.143-8.95 10.075C13.031 27.663 12.262 28 12 28c-.356 0-3.281-1.387-4.875-2.313-1.731-1.012-2.806-1.806-3.881-2.875-1.769-1.75-2.657-3.53-3.069-6.143-.137-.875-.144-.988-.144-6.463 0-5.531 0-5.575.132-5.743.28-.382.393-.42 1.318-.476 1.856-.106 3.381-.45 5.175-1.156A20.763 20.763 0 0010.781.637zm7.375 8.27C16.87 7.374 15.1 6.38 13.181 6.1a9.528 9.528 0 00-2.056-.056c-1.169.125-2.55.631-3.531 1.287-1.138.763-2.144 1.9-2.732 3.075-2.443 4.881.6 10.713 6.013 11.507 2.456.356 5.012-.488 6.731-2.238.581-.594.713-.75 1.063-1.269 1.931-2.875 1.718-6.85-.513-9.5zm-5.418 3.956c.95-.957 1.8-1.763 1.887-1.8.231-.094.731-.038.925.106.381.281.531.731.394 1.156-.05.15-.707.844-2.275 2.406-1.914 1.909-2.261 2.264-2.62 2.274-.087.003-.175-.015-.287-.036-.193-.031-.418-.225-1.462-1.269-1.325-1.325-1.369-1.4-1.269-1.931.057-.306.432-.681.738-.738.494-.093.644-.012 1.469.8l.762.757 1.738-1.725z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="content">
                  <h2>Money Back Guarantee</h2>
                  <p>{money_back_guarantee_string}</p>
                </div>
              </div>
            )}

            <CarouselList isMobileView={isMobileView} />
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.openSnackBar}
          autoHideDuration={8000}
          onClose={this.closeEmailLinkSnackbar}
          className="snackbarStyle"
        >
          <Alert
            onClose={this.closeEmailLinkSnackbar}
            severity="error"
            sx={{ width: '100%' }}
          >
            {this.state.snackBarTitle}
            <br />
            {this.state.snackBarMsg}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userCreated: dispatch.userCreated.item21,
    userStatus: dispatch.userStatus.item27,
    lcList: dispatch.lcList.item3,
    campaign: dispatch.userData?.referralData,
    userInfoSubmitted: dispatch.userInfoSubmitted.item24,
  };
};

export default connect(mapDispatchToProps, {
  createUser,
  getUserStatus,
  termsAndCons,
  landingContent,
  submitUserInfo,
  getReferralData,
})(LandingPage);
