import BaseComponent from '../../BaseComponent';
import React, { Component } from 'react';

import './ScreenWrapperPayment.scss';
import { Grid } from '@mui/material';
import Partners from '../Partners/Partners';
import LinkAccount from '../../../assets/ai/points.png';
import { getMoneyBackGuarantee } from '../../../services/firebase-config';

class ScreenWrapperPayment extends BaseComponent {
  constructor(props) {
    super(props,'payment_details');

    this.state = {
      show_money_back_guarantee: false,
      money_back_guarantee_string: null,
    };
  }

  componentDidMount = async () => {
    const { show_money_back_guarantee, money_back_guarantee_string } =
      await getMoneyBackGuarantee();
    this.setState({ show_money_back_guarantee, money_back_guarantee_string });
  };

  render() {
    const { show_money_back_guarantee, money_back_guarantee_string } =
      this.state;
    const { isMobileView } = this.props;

    return (
      <>
        <Grid container className={'gridWrapper'}>
          <Grid item xs={12} sm={12} md={6} lg={6} size={12}>
            <div className={'rightGridWrapper'}>
              <div className={'rightGrid'}>
                {show_money_back_guarantee && (
                  <div className="moneyback-wrap green show-mobile">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="none"
                        viewBox="0 0 32 32"
                      >
                        <g fill="#4EB668" clipPath="url(#clip0_112_10907)">
                          <path d="M27.25 2.569c-2.512.125-2.75.162-3 .475a1.365 1.365 0 00-.194.406c-.112.425 0 .619.925 1.556.456.463.831.875.831.907 0 .03-2.524 2.58-5.606 5.662l-5.612 5.613-2.625-2.625c-2.888-2.882-2.906-2.894-3.625-2.9-.269 0-.456.043-.669.143-.206.107-1.275 1.138-3.881 3.75C.544 18.813.194 19.188.1 19.462c-.219.67-.056 1.244.506 1.8.494.482.8.632 1.294.632.731 0 .725.006 3.738-3.006l2.737-2.726 2.644 2.626c1.631 1.625 2.725 2.668 2.868 2.73.37.17.738.194 1.15.076l.37-.1 6.512-6.513 6.512-6.506.9.881c.738.725.944.894 1.138.931.575.107 1.137-.337 1.243-.974.02-.12.094-1.532.17-3.138l.137-2.912-.138-.275c-.225-.47-.45-.55-1.45-.544-.462.006-1.893.062-3.181.125z"></path>
                          <path d="M26.075 13.581l-2.387 2.388V29.23l.162.169.169.163h4.462l.169-.163.163-.169V11.525l-.175-.169-.176-.168-2.387 2.393zM19.375 20.281c-1.256 1.257-2.413 2.388-2.575 2.519l-.3.225v6.15l.163.194.162.194h4.494l.15-.15a.853.853 0 00.187-.357c.019-.119.025-2.65.019-5.637L21.656 18l-2.281 2.281zM9.313 24.438c0 4.875 0 4.893.3 5.056.087.044.875.069 2.343.069h2.219l.162-.194.163-.194-.012-2.788-.02-2.793-.343-.038c-.425-.044-.731-.143-1.219-.387-.3-.15-.675-.482-1.937-1.738-.863-.856-1.588-1.556-1.613-1.556-.025 0-.043 2.056-.043 4.563zM5.656 21.55c-1.131 1.119-1.731 1.675-1.968 1.806a4.004 4.004 0 01-1.232.425l-.268.044V29.231l.162.169.169.163h4.487l.15-.163.157-.169v-4.644c0-2.556-.007-4.65-.013-4.65-.013 0-.75.726-1.644 1.613z"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_112_10907">
                            <path fill="#fff" d="M0 0H32V32H0z"></path>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="content">
                      <p>
                        Average Ava members see an average score increase of
                        <strong> 41 points </strong>
                        after 4 months.
                      </p>
                    </div>
                  </div>
                )}

                <section className="rightGrid-content">
                  <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid item xs={12}>
                      <h2 className="plan-head space-top">Pay</h2>
                    </Grid>
                    <Grid item xs={12}>
                      {this.props.children}
                    </Grid>
                  </Grid>
                </section>
              </div>
              <Partners />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            size={12}
            className="right-container"
          >
            {show_money_back_guarantee && (
              <div className="moneyback-wrap m-b-32">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="28"
                    s
                    fill="none"
                    viewBox="0 0 24 28"
                  >
                    <path
                      fill="#5F2FCB"
                      fillRule="evenodd"
                      d="M10.781.637c.375-.256.769-.506.875-.556.363-.162.656-.062 1.481.506 2.182 1.488 4.557 2.55 6.87 3.057.95.212 1.487.287 2.512.343.925.057 1.037.094 1.319.476.13.168.13.212.13 5.743 0 5.475-.006 5.588-.143 6.463-.706 4.475-3.075 7.143-8.95 10.075C13.031 27.663 12.262 28 12 28c-.356 0-3.281-1.387-4.875-2.313-1.731-1.012-2.806-1.806-3.881-2.875-1.769-1.75-2.657-3.53-3.069-6.143-.137-.875-.144-.988-.144-6.463 0-5.531 0-5.575.132-5.743.28-.382.393-.42 1.318-.476 1.856-.106 3.381-.45 5.175-1.156A20.763 20.763 0 0010.781.637zm7.375 8.27C16.87 7.374 15.1 6.38 13.181 6.1a9.528 9.528 0 00-2.056-.056c-1.169.125-2.55.631-3.531 1.287-1.138.763-2.144 1.9-2.732 3.075-2.443 4.881.6 10.713 6.013 11.507 2.456.356 5.012-.488 6.731-2.238.581-.594.713-.75 1.063-1.269 1.931-2.875 1.718-6.85-.513-9.5zm-5.418 3.956c.95-.957 1.8-1.763 1.887-1.8.231-.094.731-.038.925.106.381.281.531.731.394 1.156-.05.15-.707.844-2.275 2.406-1.914 1.909-2.261 2.264-2.62 2.274-.087.003-.175-.015-.287-.036-.193-.031-.418-.225-1.462-1.269-1.325-1.325-1.369-1.4-1.269-1.931.057-.306.432-.681.738-.738.494-.093.644-.012 1.469.8l.762.757 1.738-1.725z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="content">
                  <h2>Money Back Guarantee</h2>
                  <p>{money_back_guarantee_string}</p>
                </div>
              </div>
            )}
            <div className="f-center">
              <img
                src={LinkAccount}
                alt="Ava subscribers see an average score increase of 41 points after 4 months..."
              />
              <p className="avg-lbl">
                Average Ava members see an average score increase of
                <strong> 41 points </strong>
                after 4 months.
              </p>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ScreenWrapperPayment;
