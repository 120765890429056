import React from 'react';

import './ScreenWrapper.scss';
import { Grid } from '@mui/material';
import Partners from '../Partners/Partners';

const ScreenWrapper = (props) => {
  return (
    <>
      <Grid container className={'gridWrapper'}>
        <Grid item xs={12} sm={12} md={12} lg={12} size={12}>
          <div className={'rightGridWrapper blue-bg'}>
            <div className={'rightGrid inner-screens'}>
              <section className="rightGrid-content ms-initial">
                <Grid container rowSpacing={2} columnSpacing={1}>
                  <Grid item xs={12}>
                    <section className="content-area">{props.children}</section>
                  </Grid>
                </Grid>
              </section>
            </div>
            <Partners />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ScreenWrapper;
