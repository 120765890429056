import React, { Component } from "react";
import "./CreditFrozen.scss";

import {
  Typography,
  Box
} from "@mui/material";


import BaseComponent from '../../../BaseComponent';
  
class CreditFrozen extends BaseComponent {
  constructor(props) {

    super(props, 'credit_frozen');
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Box className="creditFrozenBox">
        <Typography variant="h6" className="infoHeader1" style={{ marginTop: 40 }}>
        Credit frozen
        </Typography>
        <Typography variant="body1" className="infoText1" style={{ marginTop: 10, marginBottom: 160 }}>
        Unfortunately we couldn’t pull your credit report because it’s frozen. Please reach out to Equifax and unfreeze your credit.
        </Typography>
      </Box>
    );
  }
}

export default CreditFrozen;
