import {
  CREATE_USER, USER_BY_ID, TOS,
  SUBMIT_USER_INFO, SUBMIT_KYC,
  REGISTER_STATUS, USER_STATUS,
  PLAID_LINK_TOKEN, CREATE_PLAID_LINK, GET_PLAID_LINK, GET_REFERRAL_DATA, POST_REFERRAL_ATTRIBUTE
} from "./_types";
import axios from "axios";

import ava from "../apis/ava";
import { AFFILIATE_DATA } from "../Constants/AppConstants";

export const createUser = (data, accessToken, affiliateData) => async (dispatch) => {
  await axios.post(`${process.env.REACT_APP_BASE_URL}/users`, { ...data, ...affiliateData }, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    const userCreated = res;
   localStorage.removeItem(AFFILIATE_DATA);
    dispatch({
      type: CREATE_USER,
      payload: userCreated,
    });
  })
    .catch((err) => {
      const userCreated = err.response;
     localStorage.removeItem(AFFILIATE_DATA);
      dispatch({
        type: CREATE_USER,
        payload: userCreated,
      });
    });
};

export const getUserById = () => async (dispatch) => {
  let userid = localStorage.getItem('userId');
  await ava.get(`${process.env.REACT_APP_V2_BASE_URL}/users/${userid}`)
    .then((res) => {
      const userData = res;
      dispatch({
        type: USER_BY_ID,
        payload: userData,
      });
    })
    .catch((err) => {
      const userData = err.response;
      dispatch({
        type: USER_BY_ID,
        payload: userData,
      });
    });
};

export const termsAndCons = (data) => async (dispatch) => {
  await ava.post("/tos", data)
    .then((res) => {
      const acceptedTos = res;
      dispatch({
        type: TOS,
        payload: acceptedTos,
      });
    })
    .catch((err) => {
      const acceptedTos = err.response;
      dispatch({
        type: TOS,
        payload: acceptedTos,
      });
    });
};

export const submitUserInfo = (uid, data) => async (dispatch) => {
  await ava.put(`/users/${uid}`, data)
    .then((res) => {
      const userInfoSubmitted = res;
      dispatch({
        type: SUBMIT_USER_INFO,
        payload: userInfoSubmitted,
      });
    })
    .catch((err) => {
      const userInfoSubmitted = err.response;
      dispatch({
        type: SUBMIT_USER_INFO,
        payload: userInfoSubmitted,
      });
    });
};

export const submitKyc = () => async (dispatch) => {
  let userid = localStorage.getItem('userId')
  await ava.post(`/users/${userid}/kyc`)
    .then((res) => {
      const kycSubmitted = res;
      dispatch({
        type: SUBMIT_KYC,
        payload: kycSubmitted,
      });
    })
    .catch((err) => {
      const kycSubmitted = err.response;
      dispatch({
        type: SUBMIT_KYC,
        payload: kycSubmitted,
      });
    });
};

export const registerStatus = () => async (dispatch) => {
  let userid = localStorage.getItem('userId');
  await ava.get(`/users/${userid}/registration`)
    .then((res) => {
      const regStatus = res;
      dispatch({
        type: REGISTER_STATUS,
        payload: regStatus,
      });
    })
    .catch((err) => {
      const regStatus = err.response;
      dispatch({
        type: REGISTER_STATUS,
        payload: regStatus,
      });
    });
};

export const getUserStatus = (uid, accessToken) => async (dispatch) => {
  await axios.get(`${process.env.REACT_APP_BASE_URL}/users/${uid}/status`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    const userStatus = res;
    dispatch({
      type: USER_STATUS,
      payload: userStatus,
    });
  })
    .catch((err) => {
      const userStatus = err.response;
      dispatch({
        type: USER_STATUS,
        payload: userStatus,
      });
    });
};

export const createPlaidLinkToken = () => async (dispatch) => {
  // await ava.post(`/users/${userid}/money/link/token`) 
  await ava.post(`${process.env.REACT_APP_V2_BASE_URL}/plaid/link/token`)
    .then((res) => {
      const plaidLinkToken = res;
      dispatch({
        type: PLAID_LINK_TOKEN,
        payload: plaidLinkToken,
      });
    })
    .catch((err) => {
      const plaidLinkToken = err.response;
      dispatch({
        type: PLAID_LINK_TOKEN,
        payload: plaidLinkToken,
      });
    });
};


export const createPlaidLink = (data) => async (dispatch) => {
  await ava.post(`${process.env.REACT_APP_V2_BASE_URL}/plaid/link`, data)
    .then((res) => {
      const plaidLink = res;
      dispatch({
        type: CREATE_PLAID_LINK,
        payload: plaidLink,
      });
    })
    .catch((err) => {
      const plaidLink = err.response;

      dispatch({
        type: CREATE_PLAID_LINK,
        payload: plaidLink,
      });
    });
};

export const getPlaidLink = () => async (dispatch) => {
  await ava.get(`${process.env.REACT_APP_V2_BASE_URL}/plaid/link`)
    .then((res) => {
      const getPlaid = res;
      dispatch({
        type: GET_PLAID_LINK,
        payload: getPlaid,
      });
    })
    .catch((err) => {
      const getPlaid = err.response;
      dispatch({
        type: GET_PLAID_LINK,
        payload: getPlaid,
      });
    });
};



export const getReferralData = (campaignData) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    ava.get(`/referral/campaigns/${campaignData?.campaign_id}`)
      .then((res) => {
        const referralData = res.data;
        dispatch({
          type: GET_REFERRAL_DATA,
          payload: { campaignData, referralData },
        });
        resolve({ campaignData, referralData });
      })
      .catch((err) => {
        const referralData = err.response;
        dispatch({
          type: GET_REFERRAL_DATA,
          payload: referralData,
        });
        reject(referralData);
      });
  })
};



export const postReferralAttribution = (campaignData = {}) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    ava.post(`/referral/attribution`,
      { userId: campaignData?.user_id, campaignId: campaignData?.campaign_id })
      .then((res) => {
        const referralData = res.data;
        dispatch({
          type: POST_REFERRAL_ATTRIBUTE,
          payload: { campaignData, referralData },
        });
        resolve({ campaignData, referralData });
      })
      .catch((err) => {
        const referralData = err.response;
        dispatch({
          type: POST_REFERRAL_ATTRIBUTE,
          payload: referralData,
        });
        reject(referralData);
      });
  })
};