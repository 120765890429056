import cheerio from 'cheerio';

export const getGoogleRating = async () => {
    try {
        const response = await fetch('https://play.google.com/store/apps/details?id=com.meetava.avaapp&gl=us&hl=en');
        const page = await response.text();

        const $ = cheerio.load(page);
        const ratingObject = $('div', 'div[itemprop="starRating"]');
        const rating = +ratingObject.text()?.replace(/[^\d.-]/g, '') ?? 0;
        const reviewsText = ratingObject
            .parent()
            .parent()
            .next()
            .text();
        let reviews = +reviewsText?.replace(/[^\d.-]/g, '') ?? 0;

        if (!Number.isNaN(reviews)) {
            if (reviewsText.indexOf('K') > -1) {
                reviews *= 1000;
            } else if (reviewsText.indexOf('M') > -1) {
                reviews *= 1000000;
            }
        }

        return {
            rating,
            reviews,
        };
    } catch (e) {
        return {
            rating: 0,
            reviews: 0,
        };
    }
};

export const getAppStoreRating = async () => {
    try {
        const response = await fetch('https://itunes.apple.com/lookup?id=1584183782&country=us');
        const data = await response.json();

        const reviews = +data.results[0].userRatingCount;
        const rating = +data.results[0].averageUserRating.toFixed(1);

        return {
            reviews,
            rating,
        };
    } catch (e) {
        return {
            reviews: 0,
            rating: 0,
        };
    }
};
