import { LIST_OF_GOALS, SAVE_GOAL_ENTRY, LANDING_CONTENT } from "./_types";

import ava from "../apis/ava";
import axios from 'axios';

export const getListOfGoals = () => async (dispatch) => {
  await ava.get("/goals")
    .then((res) => {
      const goalsList = res;
      dispatch({
        type: LIST_OF_GOALS,
        payload: goalsList,
      });
    })
    .catch((err) => {
      const goalsList = err.response;
      dispatch({
        type: LIST_OF_GOALS,
        payload: goalsList,
      });
    });
};

export const saveUserGoal = (data) => async (dispatch) => {
  await ava.post("/goals", data)
    .then((res) => {
      const goalSaved = res;
      dispatch({
        type: SAVE_GOAL_ENTRY,
        payload: goalSaved,
      });
    })
    .catch((err) => {
      const goalSaved = err.response;
      dispatch({
        type: SAVE_GOAL_ENTRY,
        payload: goalSaved,
      });
    });
};

export const landingContent = () => async (dispatch) => {
  await axios.get(`${process.env.REACT_APP_BASE_URL}/copy/outside`)
    .then((res) => {
      const lcList = res;
      dispatch({
        type: LANDING_CONTENT,
        payload: lcList,
      });
    })
    .catch((err) => {
      const lcList = err.response;
      dispatch({
        type: LANDING_CONTENT,
        payload: lcList,
      });
    });
};


