import { Analytics } from '@segment/analytics-node';
import { v4 as uuidv4 } from 'uuid';
import { APP_CONSTANTS } from '../Constants/AppConstants';
import { getIpAddress } from '../ipHelper';
import { DeviceInfo } from 'react-device-detect';

const analyticsSegment = new Analytics({
  writeKey: process.env.REACT_APP_SEGMENT_WRITE_ID,
  batch: false, // disable batching,
  options: {
    // set a timeout of 5 seconds for requests to the Segment API
    timeout: 8000,
    // set a maximum of 3 retry attempts for failed requests
    maxRetryAttempts: 3,
    // set a backoff interval of 1 second between retry attempts
    retryInterval: 2000,
    // set a backoff factor of 2 for exponential backoff
    backoffFactor: 2,
  },
});

// Generate UUID
let segmentAnonymousId = localStorage.getItem('segmentAnonymousId');
if (!segmentAnonymousId) {
  segmentAnonymousId = uuidv4();
  localStorage.setItem('segmentAnonymousId', segmentAnonymousId);
}

// get IP Address
const getIPAddress = async () => {
  let IPAddress = await localStorage.getItem('IPAddress');
  if (!IPAddress) {
    IPAddress = await getIpAddress();
    localStorage.setItem('IPAddress', IPAddress);
  }
  return IPAddress;
};

export const segmentIdentify = async (userId, userEvent, data = {}) => {
  try {
    const ipAddress = await getIPAddress();
    console.log('segmentIdentify ', userEvent);

    analyticsSegment.identify({
      userId: userId,
      anonymousId: segmentAnonymousId,
      context: {
        userAgent: navigator.userAgent,
        ip: ipAddress,
      },
      ...data,
    });
  } catch (ex) {
    console.log('Error Tracking Segment Identify ' + userEvent, ex);
  }
};

export const segmentTrack = async (userId, userEvent, data = {}) => {
  try {
    const ipAddress = await getIPAddress();
    console.log('segmentTrack ', userEvent);
    let trackObject = {
      event: userEvent,
      ...data,
      context: {
        userAgent: navigator.userAgent,
        ip: ipAddress,
      },
    };
    if (userId) {
      trackObject.userId = userId;
    } else {
      trackObject.anonymousId = segmentAnonymousId;
    }
    analyticsSegment.track(trackObject);
  } catch (ex) {
    console.log('Error Tracking Segment Track ', ex);
  }
};

export const segmentPage = async (data = {}) => {
  try {
    const ipAddress = await getIPAddress();
    console.log('segmentPage ', data.name);
    let trackObject = {
      ...data,
      context: {
        userAgent: navigator.userAgent,
        ip: ipAddress,
      },
    };
    if (data.userId) {
      trackObject.userId = data?.userId;
    } else {
      trackObject.anonymousId = segmentAnonymousId;
      delete trackObject?.userId;
    }

    analyticsSegment.page(trackObject);
  } catch (ex) {
    console.log('Error Tracking Segment Page ', ex);
  }
};

export function sendRelativePageViewtoSegment(currentPage) {
  switch (currentPage) {
    case APP_CONSTANTS.MOBILE_VERIFY_SCREEN:
      segmentPage({
        userId: localStorage.getItem('userId'),
        page_category: SEGMENT_PAGE_CATEGORY.MOBILE_VERIFY.CATEGORY,
        name: SEGMENT_PAGE_CATEGORY.MOBILE_VERIFY.NAME,
        properties: {
          page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
          referrer_page: SEGMENT_PAGE_CATEGORY.MOBILE_VERIFY.REFERER,
          category_flow: SEGMENT_PAGE_CATEGORY.CATEGORY_FLOW.FLOW_8,
          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
        },
      });
      break;
    case APP_CONSTANTS.OTP_VERIFY_SCREEN:
      segmentPage({
        userId: localStorage.getItem('userId'),
        page_category: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.CATEGORY,
        name: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.NAME,
        properties: {
          page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
          referrer_page: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.REFERER,
          category_flow: SEGMENT_PAGE_CATEGORY.CATEGORY_FLOW.FLOW_9,
          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
        },
      });
      break;
    case APP_CONSTANTS.USER_INFO_SCREEN:
      segmentPage({
        userId: localStorage.getItem('userId'),
        name: SEGMENT_PAGE_CATEGORY.FULL_NAME.PAGE_NAME,
        properties: {
          page_category: SEGMENT_PAGE_CATEGORY.FULL_NAME.CATEGORY,
          name: SEGMENT_PAGE_CATEGORY.FULL_NAME.PAGE_NAME,
          page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
          category_flow: SEGMENT_PAGE_CATEGORY.CATEGORY_FLOW.FLOW_3,
          referrer_page: SEGMENT_PAGE_CATEGORY.CATEGORY_FLOW.REFERER,
          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
        },
      });
      break;
    case APP_CONSTANTS.ADD_ADDRESS_SCREEN:
      segmentPage({
        userId: localStorage.getItem('userId'),
        page_category: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.CATEGORY,
        name: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.PAGE_NAME,
        properties: {
          page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
          referrer_page: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.REFERER,
          category_flow: SEGMENT_PAGE_CATEGORY.CATEGORY_FLOW.FLOW_4,
          page_category: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.PAGE_CATEGORY,
          name: SEGMENT_PAGE_CATEGORY.USER_ADDRESS.PAGE_NAME,
          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
        },
      });
      break;
    case APP_CONSTANTS.CONFIRM_INFO_SCREEN:
      break;
    case APP_CONSTANTS.LOADING_PROGRESS_SCREEN:
      break;
    case APP_CONSTANTS.CONFIRM_PLAN_SCREEN:
      break;
    case APP_CONSTANTS.ALMOST_THERE_SCREEN:
      segmentPage({
        userId: localStorage.getItem('userId'),
        name: SEGMENT_PAGE_CATEGORY.PLAID_START.PAGE_NAME,
        properties: {
          page_category: SEGMENT_PAGE_CATEGORY.PLAID_START.CATEGORY,
          name: SEGMENT_PAGE_CATEGORY.PLAID_START.PAGE_NAME,
          page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
          category_flow: SEGMENT_PAGE_CATEGORY.CATEGORY_FLOW.FLOW_5,
          referrer_page: SEGMENT_PAGE_CATEGORY.PLAID_START.REFERER,
          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
        },
      });
      break;
    case APP_CONSTANTS.PAYMENT_DETAILS_SCREEN:
      // segmentPage({
      //   userId: localStorage.getItem('userId'),
      //   name: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.NAME,
      //   properties: {
      //     page_category: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.CATEGORY,
      //     page_type: SEGMENT_PAGE_CATEGORY.TYPE.MODAL,
      //     name: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.NAME,
      //     referrer_page: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.REFERER,
      //     category_flow: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.FLOW_7,
      //     platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
      //   },
      // });
      break;
    case APP_CONSTANTS.WELCOME_SCREEN:
      segmentPage({
        userId: localStorage.getItem('userId'),
        page_category: SEGMENT_PAGE_CATEGORY.WELCOME.CATEGORY,
        name: SEGMENT_PAGE_CATEGORY.WELCOME.NAME,
        properties: {
          page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
          referrer_page: SEGMENT_PAGE_CATEGORY.WELCOME.REFERER,
          category_flow: SEGMENT_PAGE_CATEGORY.CATEGORY_FLOW.FLOW_10,
          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
        },
      });
      break;
    case APP_CONSTANTS.IMPROVE_CREDIT_SCREEN:
      break;
    case APP_CONSTANTS.EDIT_INFO_SCREEN:
      break;
    case APP_CONSTANTS.STATE_UN_SUPPORTED_SCREEN:
      break;
    case APP_CONSTANTS.CREDIT_FROZEN_SCREEN:
      break;
    case APP_CONSTANTS.KYC_FAILED_SCREEN:
      break;
    case APP_CONSTANTS.PLAID_ERROR_SCREEN:
      break;
    case APP_CONSTANTS.PLAID_LOADING_PROGRESS_SCREEN:
      segmentPage({
        userId: localStorage.getItem('userId'),
        page_category: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.CATEGORY,
        name: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.NAME,
        properties: {
          page_type: SEGMENT_PAGE_CATEGORY.TYPE.MODAL,
          referrer_page: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.REFERER,
          category_flow: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.FLOW_7,
          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
        },
      });
      break;
    default:
      console.log('nothing selected');
  }
}

export const SEGMENT_PAGE_CATEGORY = {
  TYPE: {
    PAGE: 'Page',
    MODAL: 'Modal',
  },
  PLATFORM: {
    WEB: 'web',
  },
  EVENT: {
    FORM_SUBMITTED: 'Form Submitted',
    BUTTON_CLICKED: 'Button Clicked',
    IDENTIFY_CALL: 'Identify',
  },
  CUSTOM_EVENTS: {
    SUBSCRIPTION_PENDING: 'Subscription Pending',
    PLAID_PENDING: 'Plaid Pending',
    PLAID_STARTED: 'Plaid Started',
  },
  CATEGORY_FLOW: {
    FLOW_1: 1,
    FLOW_2: 2,
    FLOW_3: 3,
    FLOW_4: 4,
    FLOW_5: 5,
    FLOW_6: 6,
    FLOW_7: 7,
    FLOW_8: 8,
    FLOW_9: 9,
    FLOW_10: 10,
  },
  USER_REGISTRATION: {
    CATEGORY: 'Registration / Login',
    PAGE_NAME: 'Email Selection',
    FORM_NAME: 'Email and Name',
    INPUT_TYPE: 'Text',
    FORM_CATEGORY: 'Registration',
    NUMBER_OF_FIELDS: 3,
    APPLE_BUTTON_TEXT: 'Continue with Apple',
    APPLE_BUTTON_COLOR: 'IOS_white',
    APPLE_BUTTON_NAME: 'continue_apple',
    APPLE_RGB: 'rgb(255 255 255)',
    PLAYSTORE_BUTTON_TEXT: 'Continue with Google',
    PLAYSTORE_BUTTON_COLOR: 'google_white',
    PLAYSTORE_BUTTON_NAME: 'continue_google ',
    PLAYSTORE_RGB: 'rgb(255 255 255)',
    ALREADY_HAVE_AN_ACCOUNT_BUTTON_TEXT: 'Already have an account?',
    ALREADY_HAVE_AN_ACCOUNT_BUTTON_COLOR: 'email_purple',
    ALREADY_HAVE_AN_ACCOUNT_BUTTON_NAME: 'continue_email ',
    ALREADY_HAVE_AN_ACCOUNT_RGB: 'rgb(123 87 226)',
  },
  CHECK_EMAIL: {
    CATEGORY: 'Registration',
    PAGE_NAME: 'Check Email',
    REFERER: 'Email Selection',
    RESEND_EMAIL_BUTTON_TEXT: 'Resend Email ',
    RESEND_EMAIL_BUTTON_COLOR: 'Ava purple',
    RESEND_EMAIL_BUTTON_NAME: 'verification_email_resend',
    RESEND_EMAIL_BUTTON_RGB: 'rgb(255 255 255)',
    CHANGE_EMAIL_BUTTON_TEXT: 'Change Email',
    CHANGE_EMAIL_BUTTON_COLOR: 'Ava purple',
    CHANGE_EMAIL_BUTTON_NAME: 'change_email',
    CHANGE_EMAIL_BUTTON_RGB: 'rgb(255 255 255)',
  },
  FULL_NAME: {
    CATEGORY: 'Registration',
    PAGE_NAME: 'Full Name',
    REFERER: 'Email Selection',
    FORM_NAME: 'Full Name',
    INPUT_TYPE: 'Text',
    NUMBER_OF_FIELDS: 2,
    FORM_CATEGORY: 'Registration',
  },
  USER_ADDRESS: {
    CATEGORY: 'Registration',
    PAGE_CATEGORY: 'Registration',
    PAGE_NAME: 'Address Entry',
    REFERER: 'Full Name',
    FORM_NAME: 'Address Entry',
    INPUT_TYPE: 'Text',
    NUMBER_OF_FIELDS: 2,
  },
  PLAID_START: {
    CATEGORY: 'Registration',
    PAGE_NAME: 'Plaid Start',
    REFERER: 'Address Entry',
    BUTTON_TEXT: 'Link your account',
    BUTTON_COLOR: 'Ava purple',
    BUTTON_NAME: 'plaid_start',
    NAME: 'Plaid Start',
    BUTTON_RGB: 'rgb(123 87 226)',
  },
  CHOOSE_PLAN: {
    NAME: 'Choose Plan',
    CATEGORY: 'Registration',
    REFERER: 'Plaid Start',
    EVENT_NAME: 'Plaid Pending',
    INPUT_TYPE: 'panel_selection',
  },
  CHOOSE_PLAN_FAILED: {
    NAME: 'Choose Plan UW Fail',
    CATEGORY: 'Registration',
    REFERER: 'Plaid Start',
    EVENT_NAME: 'Plaid Pending',
    INPUT_TYPE: 'panel_selection',
    FORM_NAME: 'Choose Plan',
    PAGE_CATEGORY: 'Registration',
  },
  CC_PAYMENT: {
    NAME: 'Choose Plan UW Fail',
    FORM_NAME: 'CC Payment',
    CATEGORY: 'Registration',
    REFERER: 'Plaid Start',
    INPUT_TYPE: 'Text',
    NUMBER_OF_FIELDS: 5,
    EVENT_NAME: 'Plaid Pending',
  },
  PAYMENT_CONFIRMATION: {
    NAME: 'Payment Authorization',
    CATEGORY: 'Registration',
    REFERER: 'Choose Plan',
    BUTTON_TEXT: 'CONFIRM PAYMENT',
    BUTTON_COLOR: 'Ava purple',
    BUTTON_NAME: 'confirm_payment',
  },
  MOBILE_VERIFY: {
    NAME: 'Phone Entry',
    FORM_NAME: 'Phone Entry',
    CATEGORY: 'Registration',
    REFERER: 'Payment Authorization',
    INPUT_TYPE: 'Text',
    NUMBER_OF_FIELDS: 1,
    EVENT_NAME: 'Phone Entry',
  },
  OTP_VERIFY: {
    NAME: 'Phone Verification',
    FORM_NAME: 'Phone Verification Code',
    CATEGORY: 'Registration',
    REFERER: 'Phone Entry',
    INPUT_TYPE: 'Text',
    NUMBER_OF_FIELDS: 1,
    EVENT_NAME: 'Phone Verification',
    BUTTON_TEXT_RESEND: 'RESEND TEXT',
    BUTTON_NAME_RESEND: 'Resend Text',
    BUTTON_TEXT_CHANGE_PHONE: 'CHANGE PHONE NUMBER',
    BUTTON_NAME_CHANGE_PHONE: 'Change Phone Number',
    BUTTON_COLOR: 'Ava purple',
  },
  WELCOME: {
    NAME: 'Subscription Authorized',
    CATEGORY: 'Registration',
    REFERER: 'Payment Authorization',
    EVENT_NAME: 'Subscription Pending',
  },
};
