import axios from "axios";

const token = localStorage.getItem("accessToken");
const userId = localStorage.getItem("userId");

export const baseURL = process.env.REACT_APP_BASE_URL;

const apiAva = axios.create({
  baseURL
});

apiAva.defaults.headers.common = { 'Authorization': token ? `Bearer ${token}` : '', 'x-ava-client-type': `${window.location.hostname} / web (UserId ${userId})` }

// function fnBrowserDetect(){

//   let userAgent = window.navigator.userAgent;
//   let browserName;

//   if(userAgent.match(/chrome|chromium|crios/i)){
//       browserName = "Chrome";
//     }else if(userAgent.match(/firefox|fxios/i)){
//       browserName = "Firefox";
//     }  else if(userAgent.match(/safari/i)){
//       browserName = "Safari";
//     }else if(userAgent.match(/opr\//i)){
//       browserName = "Opera";
//     } else if(userAgent.match(/edg/i)){
//       browserName = "Edge";
//     }else{
//       browserName="No browser detection";
//     }

//    return browserName;
// }

// apiAva.defaults.headers.common = { 'User-Agent': `${baseURL} / 3.5.1 (Android SDK 30)` }

// userAgent: "com.meetava.avaapp / 3.5.1 (Android SDK 30) (UserId sq4ntga64BRys2CK5tEhfhLZzCl1)"

// ==== For GET Requests ==== //
apiAva.interceptors.request.use(
  (req) => {
    // Add configurations here
    return req;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 500) {
      localStorage.clear();
      return window.location.href = '/';
    }
    return Promise.reject(error);
  }
);


// ==== For POST Requests ==== //
apiAva.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === 200) {
    }
    return response;
  },

  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401 || error.response.status === 500) {
      localStorage.clear();
      return window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default apiAva;












// export const welcome = () => {
//   const token = localStorage.getItem("accessToken");
//   const userId = localStorage.getItem("userId");
// }
// apiAva.get(`/users/${userId}/status`)
//     .then((res) => {
//     })
//     .catch((err) => {
//       localStorage.clear();
//       // window.location.pathname('/');
//     });

// if (token) {
//   apiAva.interceptors.request.use(
//     function (config) {
//       // Do something before request is sent
//       checkTokenExpirationMiddleware(token)
//       return config;
//     },
//     function (error) {
//       // Do something with request error
//       return Promise.reject(error);
//     }
//   );
// }