/* eslint-disable import/no-anonymous-default-export */
import {
  CREATE_USER, USER_BY_ID, TOS,
  SUBMIT_USER_INFO, SUBMIT_KYC,
  REGISTER_STATUS, USER_STATUS, PLAID_LINK_TOKEN,
  CREATE_PLAID_LINK, GET_PLAID_LINK, GET_REFERRAL_DATA, POST_REFERRAL_ATTRIBUTE
} from "../_actions/_types";

const initialState = {
  item21: {},
  item22: {},
  item23: {},
  item24: {},
  item25: {},
  item26: {},
  item27: {},
  item28: {},
  item29: {},
  item30: {},
  referralData: {
    campaignData: null,
    referralData: null,
    loaded: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        item21: action.payload,
      };
    case USER_BY_ID:
      return {
        ...state,
        item22: action.payload,
      };
    case TOS:
      return {
        ...state,
        item23: action.payload,
      };
    case SUBMIT_USER_INFO:
      return {
        ...state,
        item24: action.payload,
      };
    case SUBMIT_KYC:
      return {
        ...state,
        item25: action.payload,
      };
    case REGISTER_STATUS:
      return {
        ...state,
        item26: action.payload,
      };
    case USER_STATUS:
      return {
        ...state,
        item27: action.payload,
      };
    case PLAID_LINK_TOKEN:
      return {
        ...state,
        item28: action.payload,
      };
    case CREATE_PLAID_LINK:
      return {
        ...state,
        item29: action.payload,
      };
    case GET_PLAID_LINK:
      return {
        ...state,
        item30: action.payload,
      };
    case GET_REFERRAL_DATA: {
      return {
        ...state,
        referralData: { ...action.payload, loaded: true },
      };
    }

    case POST_REFERRAL_ATTRIBUTE: {
      return {
        ...state,
        //nothing needed to be stored in store
      };
    }
    default:
      return state;
  }
};
