import React, { Component } from 'react';
import './InputEmail.scss';
import {
  Button,
  Grid,
  Input,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class InputEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailAddressError: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    await this.setState({ [name]: value });
  };

  onDataSubmit = async (e, email, ie) => {
    this.setState({ openBackdrop: true });
    e.preventDefault();
    try {
      const s = this.state;
      this.setState({
        emailAddressError: false,
      });
      let reg = /^([A-Za-z0-9_+\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      let emailPattern = new RegExp(reg);
      let em = await emailPattern.test(s.email);
      if (!em) {
        this.setState({ emailAddressError: true });
      } else {
        this.setState({ emailAddressError: false });
        this.props.emailLogin(e, email, ie);
      }
    } catch (e) {
      console.log('something went wrong', e);
    } finally {
      this.setState({ openBackdrop: false });
    }
  };

  render() {
    const { email, emailAddressError } = this.state;
    const { isChangeEmail, onCancel } = this.props;
    return (
      <>
        <form
          className="formAlignment"
          onSubmit={(e) => this.onDataSubmit(e, email, 'ie')}
        >
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12}>
              <h2>{isChangeEmail ? 'Change Email' : 'Enter email'}</h2>
              {isChangeEmail ? (
                <p className="sub-head">Create an account using your email.</p>
              ) : (
                ''
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <FormControl error={emailAddressError} style={{ width: '100%' }}>
                <label for="email" className="lbl">
                  {isChangeEmail ? 'New email address' : 'Email address'}
                </label>
                <Input
                  className="textfield"
                  placeholder="email@gmail.com"
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'email',
                  }}
                />
                {emailAddressError && (
                  <FormHelperText className="error">
                    Please enter valid email address
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                fullWidth={true}
                className="btn-primary mgr-top-8"
                type="submit"
                id="RegisterWithEmail-Continue"
              >
                {isChangeEmail ? 'Change Email' : 'Continue'}
              </Button>
              {isChangeEmail ? (
                <>
                  <Link
                    onClick={onCancel}
                    className="link-account text-red"
                    to="/email_login"
                  >
                    Cancel
                  </Link>
                </>
              ) : (
                ' '
              )}
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

export default InputEmail;
