import { SAVE_WAITLIST_ENTRY, SMS_APP_LINK } from "./_types";

import ava from "../apis/ava";

export const saveWaitlistEntry = (data) => async (dispatch) => {
  await ava.post("/notifications/ava/waitlist", data)
    .then((res) => {
      const waitlistSaved = res;
      dispatch({
        type: SAVE_WAITLIST_ENTRY,
        payload: waitlistSaved,
      });
    })
    .catch((err) => {
      const waitlistSaved = err.response;
      dispatch({
        type: SAVE_WAITLIST_ENTRY,
        payload: waitlistSaved,
      });
    });
};

export const smsAppLink = () => async (dispatch) => {
  await ava.post("/sms/applink")
    .then((res) => {
      const appLinkSent = res;
      dispatch({
        type: SMS_APP_LINK,
        payload: appLinkSent,
      });
    })
    .catch((err) => {
      const appLinkSent = err.response;
      dispatch({
        type: SMS_APP_LINK,
        payload: appLinkSent,
      });
    });
};


