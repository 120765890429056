import React from 'react';
import TagManager from 'react-gtm-module';

class BaseComponent extends React.Component {
    constructor(props, pageName) {
        super(props);
        //const pageName = props.pageName;
        //console.log("this is the pageName arg", pageName);
        const path = `register/${pageName}`; 
        //console.log(path)

        TagManager.dataLayer({
            dataLayer: {
              event: 'custom_page_view_trigger_event',
              pagePath: path
            }
          });
    }
}

export default BaseComponent;
