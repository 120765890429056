import { SEND_OTP_TO_MOBILENUMBER, OTP_VERIFY } from "../_actions/_types";

const initialState = {
  item11: {},
  item12: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP_TO_MOBILENUMBER:
      return {
        ...state,
        item11: action.payload,
      };
    case OTP_VERIFY:
      return {
        ...state,
        item12: action.payload,
      };
    default:
      return state;
  }
};
