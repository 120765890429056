import React, { Component } from 'react';
import './CheckEmail.scss';
import { Typography, Box, Button, Grid } from '@mui/material';

class CheckEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailForSignIn: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getEmailfromLocalStorage();
  }

  getEmailfromLocalStorage = async () => {
    let emailSignIn = localStorage.getItem('emailForSignIn');
    await this.setState({ emailForSignIn: emailSignIn });
  };

  render() {
    const { email, newUser } = this.props;
    const { emailForSignIn } = this.state;
    return (
      <>
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12}>
            <h2>Please check your email</h2>
            <p className="sub-head">
              We sent an email to
              <strong> {emailForSignIn}</strong>. Click on the link in your
              email to continue.
            </p>
          </Grid>
          <Grid item xs={12}>
            <Button
              className="btn-secondary"
              fullWidth={true}
              startIcon={
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <g fill="#7B57E2" clipPath="url(#clip0_75_12600)">
                      <path d="M1.43 2.562C.434 2.805-.19 3.82.06 4.785c.078.293.223.559.418.762.176.18 8.496 5.746 8.8 5.883a1.794 1.794 0 001.493-.02c.371-.176 8.523-5.633 8.73-5.844.973-.992.442-2.68-.949-3.007-.367-.086-16.761-.082-17.12.003z"></path>
                      <path d="M0 11.266c0 3.164.012 4.613.043 4.757.145.688.7 1.25 1.406 1.418.367.086 16.668.086 17.078 0a1.859 1.859 0 001.43-1.418c.05-.238.063-9.32.012-9.296-.016.003-1.903 1.261-4.196 2.793-2.293 1.53-4.293 2.847-4.449 2.921-.43.22-.797.301-1.324.301s-.895-.082-1.324-.3c-.156-.075-2.156-1.391-4.45-2.922A755.037 755.037 0 00.032 6.727C.011 6.719 0 8.762 0 11.266z"></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_75_12600">
                        <path fill="#fff" d="M0 0H20V20H0z"></path>
                      </clipPath>
                    </defs>
                  </svg>
                </>
              }
              onClick={(e) => this.props.emailLogin(e, email, 'ce')}
              id="EmailVerification-ResentEmail"
            >
              Resend Email
            </Button>
          </Grid>
          {newUser && (
            <Grid item xs={12}>
              <Button
                className="btn-secondary"
                fullWidth={true}
                startIcon={
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <g fill="#7B57E2" clipPath="url(#clip0_75_12607)">
                        <path d="M17.656.035c-.215.067-.355.176-.86.684l-.526.531 1.242 1.238L18.75 3.73l.531-.527c.29-.289.567-.598.614-.683.113-.211.117-.637.011-.86-.043-.09-.363-.445-.761-.844-.586-.586-.715-.699-.88-.754-.203-.066-.445-.078-.609-.027zM11.707 5.813l-3.7 3.699-.448 1.347c-.247.743-.45 1.391-.45 1.442 0 .187.153.422.329.508.09.046.207.082.257.082.051 0 .7-.204 1.442-.45l1.351-.449 3.711-3.71L17.91 4.57l-1.23-1.23a54.529 54.529 0 00-1.25-1.23c-.012 0-1.684 1.663-3.723 3.703z"></path>
                        <path d="M1.738 3.242C.98 3.398.363 3.942.11 4.68l-.09.261v13.32l.09.259a2.197 2.197 0 001.368 1.37l.261.09h13.32l.262-.09c.637-.222 1.117-.695 1.367-1.359l.09-.23.012-5.469.008-5.469-2.781 2.778c-2.551 2.543-2.805 2.785-3.04 2.894-.457.219-2.863.992-3.156 1.016a1.78 1.78 0 01-1.36-.512c-.362-.367-.562-.902-.507-1.398.027-.27.82-2.715 1.012-3.118.11-.234.351-.488 2.894-3.039l2.774-2.78-5.367.003c-2.954.004-5.442.02-5.528.035z"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_75_12607">
                          <path fill="#fff" d="M0 0H20V20H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </>
                }
                onClick={this.props.handleChangeEmail}
                id="EmailVerification-ChangeEmailAddress"
              >
                Change Email
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

export default CheckEmail;
