import { SEND_OTP_TO_MOBILENUMBER, OTP_VERIFY } from "./_types";

import ava from "../apis/ava";

export const sendOtpToMobile = (data) => async (dispatch) => {
  await ava.post("/sms/request", data)
    .then((res) => {
      const otpSent = res;
      dispatch({
        type: SEND_OTP_TO_MOBILENUMBER,
        payload: otpSent,
      });
    })
    .catch((err) => {
      const otpSent = err.response;
      dispatch({
        type: SEND_OTP_TO_MOBILENUMBER,
        payload: otpSent,
      });
    });
};

export const verifyOTP = (data) => async (dispatch) => {
await ava.post("/sms/verify", data)
  .then((res) => {
    const otpVerified = res;
    dispatch({
      type: OTP_VERIFY,
      payload: otpVerified,
    });
  })
  .catch((err) => {
    const otpVerified = err.response;
    dispatch({
      type: OTP_VERIFY,
      payload: otpVerified,
    });
  });
};

