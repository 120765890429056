import React, { Component } from 'react';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import InputEmail from './InputEmail/InputEmail';
import CheckEmail from './CheckEmail/CheckEmail';

import { authentication } from '../../../services/firebase-config';
import {
  sendSignInLinkToEmail,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';

import { getAnalytics, logEvent } from 'firebase/analytics';
import Partners from '../../Common/Partners/Partners';
import ScreenWrapper from '../../Common/ScreenWrapper/ScreenWrapper';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentPage,
  segmentTrack,
} from '../../../services/segment';

const auth = authentication;

const analytics = getAnalytics();

const actionCodeSettings = {
  url: `${window.location.origin}/login`,
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios',
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12',
  },
};

class EmailLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBackdrop: false,
      openCheckMail: false,
      newUser: true,
      email: '',
      activeStepper: 1,
      isChangeEmail: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let emailAlreadySent = localStorage.getItem('emailAlreadySent');
    if (emailAlreadySent === 'true') {
      this.setState({ openCheckMail: true });
    } else {
      this.setState({ openCheckMail: false });
    }
    localStorage.removeItem('emailAlreadySent');
    segmentPage({
      userId: null,
      name: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.PAGE_NAME,
      properties: {
        page_category: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.CATEGORY,
        name: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.PAGE_NAME,
        page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
        referrer_page: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.REFERER,
        category_flow: SEGMENT_PAGE_CATEGORY.CATEGORY_FLOW.FLOW_2,
        platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
      },
    });
  }

  emailLogin = async (event, email, refComp) => {
    segmentTrack(null, SEGMENT_PAGE_CATEGORY.EVENT.BUTTON_CLICKED, {
      properties: {
        page_name: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.PAGE_NAME,
        button_text: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.RESEND_EMAIL_BUTTON_TEXT,
        button_color:
          SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.RESEND_EMAIL_BUTTON_COLOR,
        button_rgb: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.RESEND_EMAIL_RGB,
        button_name: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.RESEND_EMAIL_BUTTON_NAME,
        email: email,
        firstName: localStorage.getItem('firstName'),
        lastName: localStorage.getItem('lastName'),
        platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
      },
    });
    event.preventDefault();
    this.setState({ openBackdrop: true });
    window.localStorage.setItem('emailForSignIn', email);
    logEvent(analytics, 'web_emailSignIn_clicked');
    if (refComp === 'ie') {
      logEvent(analytics, 'web_emailSignIn_loginlink_sent', { email: email });
      this.setState({ email: email, openCheckMail: false });
    } else {
      logEvent(analytics, 'web_emailSignIn_resendLoginlink_sent', {
        email: email,
      });
      this.setState({ email: email });
    }
    await fetchSignInMethodsForEmail(auth, email).then((signInMethods) => {
      if (signInMethods.length === 0) {
        this.setState({ newUser: true });
      } else {
        this.setState({ newUser: false });
      }
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          this.setState({ openCheckMail: true, openBackdrop: false });
          logEvent(analytics, 'web_emailSignIn_loginlink_success', {
            email: email,
          });
          window.localStorage.setItem('emailForSignIn', email);
        })
        .catch((error) => {
          this.setState({ openCheckMail: false, openBackdrop: false });
          logEvent(analytics, 'web_emailSignIn_loginlink_failed');
        });
    });
  };

  handleChangeEmail = async () => {
    segmentTrack(null, SEGMENT_PAGE_CATEGORY.EVENT.BUTTON_CLICKED, {
      properties: {
        page_name: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.PAGE_NAME,
        button_text: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.CHANGE_EMAIL_BUTTON_TEXT,
        button_color:
          SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.CHANGE_EMAIL_BUTTON_COLOR,
        button_rgb: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.CHANGE_EMAIL_RGB,
        button_name: SEGMENT_PAGE_CATEGORY.CHECK_EMAIL.CHANGE_EMAIL_BUTTON_NAME,
        email: localStorage.getItem('emailForSignIn'),
        firstName: localStorage.getItem('firstName'),
        lastName: localStorage.getItem('lastName'),
        platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
      },
    });
    this.setState({ isChangeEmail: true });
    await this.setState({ openCheckMail: false });
  };
  onCancel = () => {
    this.setState({ isChangeEmail: false });
  };
  render() {
    const { email, newUser, openCheckMail, openBackdrop, isChangeEmail } =
      this.state;
    return (
      <>
        <ScreenWrapper>
          {!openCheckMail ? (
            <InputEmail
              emailLogin={this.emailLogin}
              isChangeEmail={isChangeEmail}
              onCancel={this.onCancel}
            />
          ) : (
            <CheckEmail
              email={email}
              newUser={newUser}
              emailLogin={this.emailLogin}
              handleChangeEmail={this.handleChangeEmail}
            />
          )}
        </ScreenWrapper>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

export default EmailLogin;
