import { singularSdk, SingularConfig } from "singular-sdk";
import { getIpAddress } from "../ipHelper";

export function initSingular() {
    const config = new SingularConfig(
        process.env.REACT_APP_SINGULAR_KEY,
        process.env.REACT_APP_SINGULAR_SECRET,
        process.env.REACT_APP_SINGULAR_PRODUCT_ID
    ).withPersistentSingularDeviceId(process.env.REACT_APP_SINGULAR_DEVICE_ID);
    singularSdk.init(config);
}

export const singluarTrackCustomEvent = async (event, data = {}) => {
    // let IPAddress = localStorage.getItem('ip_address');
    // if (!IPAddress) {
    //     IPAddress = await getIpAddress();
    //     localStorage.setItem("IPAddress", IPAddress);
    // }
    let customUserId;
    if (!customUserId) {
        customUserId = localStorage.getItem('userId');
    }

    try {
        if (!!event) {
            // IPAddress
            await singularSdk.event(event, { ...data, customUserId,  });
        }
    } catch (ex) {
        console.log("Error Tracking Custom Event " + event, ex);
    }
};
