import React, { Component } from 'react';
import './PaymentConfirmation2.scss';

import {
  Typography,
  Card,
  CardHeader,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { getPriceList } from '../../../../../services/firebase-config';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentPage,
  segmentTrack,
} from '../../../../../services/segment';

class PaymentConfirmation2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPlan: null,
      isPopMobileView: false,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
    const { userPlan } = this.props;
    this.setState({
      confirmPlan: userPlan,
    });
    if (this.props?.openConfirmDialog) {
      segmentPage({
        userId: localStorage.getItem('userId'),
        name: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.NAME,
        properties: {
          page_category: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.CATEGORY,
          page_type: SEGMENT_PAGE_CATEGORY.TYPE.MODAL,
          name: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.NAME,
          referrer_page: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.REFERER,
          category_flow: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.FLOW_7,
          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
        },
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate = () => {
    this.setState({ isPopMobileView: window.innerWidth <= 385 });
  };

  registerAndSendAppLink = async (event) => {
    const { userPlan } = this.props;
    let getCity;
    let getState;
    let getZipcode;
    if (this.props?.entireData?.personalDetails?.address) {
      getCity = this.props?.entireData?.personalDetails?.address?.city;
      getState = this.props?.entireData?.personalDetails?.address?.state;
      getZipcode = this.props?.entireData?.personalDetails?.address?.zip;
    } else {
      getCity = this.props?.userData?.data?.user?.address?.city;
      getState = this.props?.userData?.data?.user?.address?.state;
      getZipcode = this.props?.userData?.data?.user?.address?.zip;
    }
    segmentTrack(
      this.props.userId,
      SEGMENT_PAGE_CATEGORY.EVENT.BUTTON_CLICKED,
      {
        properties: {
          page_name: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.NAME,
          button_text: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.BUTTON_TEXT,
          button_color: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.BUTTON_COLOR,
          button_name: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.BUTTON_NAME,
          name: SEGMENT_PAGE_CATEGORY.PAYMENT_CONFIRMATION.NAME,
          email: this.props?.userData?.data?.user?.email,
          firstName: this.props?.userData?.data?.user?.firstName,
          lastName: this.props?.userData?.data?.user?.lastName,
          advertiser_tracking_enabled: 0,
          application_tracking_enabled: 0,
          city: getCity,
          state: getState,
          zip: getZipcode,
          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
        },
      }
    );
    this.props.handleSubmitRegisterPlan(event, userPlan);
  };

  render() {
    const {
      isMobileView,
      openConfirmDialog2,
      userPlan,
      authorizationDescription,
      salesTax,
      subtotal,
      subscriptionPlans,
    } = this.props;

    const { confirmPlan, isPopMobileView } = this.state;
    return (
      <Dialog
        open={openConfirmDialog2}
        maxWidth={isPopMobileView ? 'xl' : 'xs'}
        fullWidth={isPopMobileView}
        className={'dialogBox2'}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="dialogHeader1"
          style={{ marginTop: 20 }}
        >
          Payment Confirmation
        </DialogTitle>
        <DialogContent className="padd-0">
          <DialogContentText
            id="alert-dialog-description"
            className={'dialogText1'}
          >
            {isMobileView
              ? 'By moving forward, upon approval you’ll be billed for your membership, starting today.'
              : 'This confirms you’ll be billed for your membership starting today.'}
          </DialogContentText>
          {confirmPlan && (
            <Card
              className="planCard"
              style={{ position: userPlan?.discount > 0 ? 'relative' : null }}
            >
              <CardHeader
                className="planCardHeader"
                style={{
                  padding: isMobileView
                    ? '20px 20px 20px 20px'
                    : '20px 20px 20px 20px',
                }}
                title={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <Typography
                      className="cardTypo1"
                      style={{
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                        width: isMobileView ? '30%' : 'inherit',
                      }}
                    >
                      {userPlan?.plan}
                    </Typography>
                    <Typography
                      className="cardTypo21"
                      style={{
                        textAlign: 'right',
                        width: isMobileView ? '70%' : 'inherit',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: userPlan?.paymentTermText?.replace(
                          userPlan.paymentTermTextToBold,
                          `<strong style="color: ${
                            subscriptionPlans?.chooserNotificationTitle
                              ? 'green'
                              : ''
                          }">${userPlan.paymentTermTextToBold}</strong>`
                        ),
                      }}
                    />
                  </div>
                }
                subheader={
                  <Typography className="cardTypo2">
                    {confirmPlan.payBy}
                  </Typography>
                }
              />
            </Card>
          )}
        </DialogContent>
        {/* <DialogContent style={{ marginBottom: 10 }}>
           <DialogContentText
            id="alert-dialog-description"
            className={"dialogText1"}
          >{authorizationDescription}
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button
            className="btn-primary mgr-top-8"
            fullWidth={true}
            onClick={this.registerAndSendAppLink}
            //id='CCConfirmation-IUnderstand'
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" fill="#fff"></circle>
                <path
                  fill="#7B57E2"
                  d="M3 7.798L6.424 11 13 4.869 12.056 4 6.424 9.25 3.931 6.923 3 7.798z"
                ></path>
              </svg>
            }
          >
            Confirm Payment
          </Button>
        </DialogActions>
        <DialogContent style={{ marginBottom: 30 }}>
          <DialogContentText
            id="alert-dialog-description"
            className={'dialogText1'}
          >
            {authorizationDescription}
          </DialogContentText>
          {salesTax && (
            <Typography className="subtotal">
              <span className="lbl-total">Subtotal:</span>{' '}
              <span className="lbl-value">{subtotal}</span>
            </Typography>
          )}
          {salesTax && (
            <Typography className="subtotal total">
              <span className="lbl-total">Sales Tax:</span>{' '}
              <span className="lbl-value">{salesTax}</span>{' '}
            </Typography>
          )}
          {/* You can cancel at any time by contacting Member Support. */}
        </DialogContent>
      </Dialog>
    );
  }
}

export default PaymentConfirmation2;
