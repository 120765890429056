import * as React from "react";
import "./LoadingProgress.scss";
import { styled } from "@mui/material/styles";

import { Box, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(123, 87, 226, 0.3)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    backgroundColor: "#7B57E2",
  },
}));

export default function LoadingProgress(props) {
  return (
    <Box sx={{ width: "50%" }} className="loaderBox">
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Typography  style={{ marginTop: 20, marginBottom: 40 }} className="loaderText">Just a few seconds</Typography>
      <BorderLinearProgress
        variant="determinate"
        value={props.loadingPercent}
      />
    </Box>
  );
}
