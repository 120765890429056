import React, { Component } from 'react';
import './assets/scss/style.scss';
// import './App.css';

import { Route, Redirect, Switch } from 'react-router-dom';
import {
  Container,
  Drawer,
  List,
  ListItem,
  Link,
  CircularProgress,
  Backdrop,
} from '@mui/material';

/** Layouts **/
import LoginLayoutRoute from './Components/Layout/LoginLayout';
import MainLayoutRoute from './Components/Layout/MainLayout';

/** Components **/
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';

import LandingPage from './Components/_pages/LandingPage/LandingPage';

import EmailLogin from './Components/_pages/EmailLogin/EmailLogin';
import MobileVerify from './Components/_pages/Registration/MobileVerify/MobileVerify';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { postReferralAttribution } from './_actions/userActions';
import { initSingular } from './services/singular';
import useStateChangeListener from './Components/Hooks/useStateChangeListener'


const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const mapDispatchToProps = {
  postReferralAttribution
};

//Initializing singular tracking
initSingular();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: false,
      isDrawerView: false,
      openDrawer: false,
      avaLogo: null,
      headerContent: null,
      dataLoaded: false,
      branchData: null,
    };
  }

  componentDidMount = async () => {
    ReactPixel.init(
      process.env.REACT_APP_FACEBOOK_PIXEL,
      advancedMatching,
      options
    );
    this.updatePredicate();

    window.addEventListener('resize', this.updatePredicate);
    let accessToken = localStorage.getItem('accessToken');
    window.addEventListener('branch_io_data', async (e) => {
      let data = e?.detail || {};
      this.setState({ branchData: data });
      if (accessToken) {
        if (data?.data_parsed?.campaign_id) {
          let campaignId = data?.data_parsed?.campaign_id;
          let userId = data?.data_parsed?.user_id;
          try {
            await this.props.postReferralAttribution({
              campaign_id: campaignId,
              user_id: userId,
            });
          } catch (e) {
            console.log('some error occured in winback endpoint.', e);
          } finally {
            this.setState({ dataLoaded: true });
          }
        } else {
          this.setState({ dataLoaded: true });
        }
      } else {
        this.setState({ dataLoaded: true });
      }
    });
    //setting load state false if event event didn't fired
    setTimeout(() => {
      if (!this.state.dataLoaded) {
        this.setState({ dataLoaded: true });
      }
    }, 3000);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate = () => {
    this.setState({
      isMobileView: window.innerWidth <= 450,
      isDrawerView: window.innerWidth <= 768,
    });
    if (!this.state.isDrawerView) {
      this.setState({
        openDrawer: false,
      });
    }
  };

  handleDrawerOpen = () => {
    this.setState({ openDrawer: !this.state.openDrawer });
  };

  render() {
    const {
      isMobileView,
      openDrawer,
      isDrawerView,
      headerContent,
      avaLogo,
      dataLoaded,
    } = this.state;
    return (
      <Switch>
        {dataLoaded ? (
          localStorage.getItem('accessToken') ? (
            <Route exact path="/">
              <Redirect to="/register" />
            </Route>
          ) : (
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
          )
        ) : (
          <div>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                display: 'flex',
                height: '100vh',
              }}
            >
              <CircularProgress color="primary" />
            </div>
          </div>
        )}

        <Route
          path="/register/*"
          render={() => (window.location.href = '/register')}
        />
        <Route
          path="/email_login/*"
          render={() => (window.location.href = '/login')}
        />
        <>
          {!isMobileView && (
            <Header
              handleDrawerOpen={this.handleDrawerOpen}
              isDrawerView={isDrawerView}
              isMobileView={isMobileView}
              headerContent={headerContent}
              avaLogo={avaLogo}
            />
          )}
          {isMobileView && (
            <Header
              handleDrawerOpen={this.handleDrawerOpen}
              isDrawerView={isDrawerView}
              isMobileView={isMobileView}
              headerContent={headerContent}
              avaLogo={avaLogo}
            />
          )}
          {/* <Container maxWidth={false} spacing={0}> */}
          <LoginLayoutRoute
            exact
            path="/login"
            isMobileView={isMobileView}
            component={LandingPage}
            branchData={this.state.branchData}
          />
          <MainLayoutRoute
            path="/register"
            isMobileView={isMobileView}
            component={MobileVerify}
          />
          <Route
            path="/email_login"
            render={(props) => (
              <EmailLogin isMobileView={isMobileView} {...props} />
            )}
          />
          {/* </Container> */}
          <Drawer
            open={openDrawer}
            onClose={this.handleDrawerOpen}
            hideBackdrop={true}
            className="customDrawerStyle"
            elevation="0"
          >
            <List className="drawerList">
              <ListItem disablePadding>
                <Link href="https://meetava.com/product" target={'_blank'}>
                  Product
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link href="https://meetava.com/about" target={'_blank'}>
                  About Us
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link href="https://meetava.com/faq" target={'_blank'}>
                  FAQ
                </Link>
              </ListItem>
              {/* {headerContent && headerContent.filter((x) => x.fields.isInternalLink)
                .map((x, i) => (
                  <ListItem disablePadding key={i}>
                    <Link href={`https://meetava.com/${x.fields.slug}`} target={"_blank"}>
                      {x.fields.title}
                    </Link>
                  </ListItem>
                ))} */}
            </List>
          </Drawer>
          <Footer isMobileView={isMobileView} />
        </>
      </Switch>
    );
  }
}

function AppWrapper(props) {
  useStateChangeListener();
  return <App {...props} />;
}

export default connect(null, mapDispatchToProps)(AppWrapper);

//  minHeight: '90vh'
