import { combineReducers } from "redux";

import userReducer from "./userReducer.js";
import goalsReducer from "./goalsReducer.js";
import mobileReducer from "./mobileReducer.js";
import notificationReducer from "./notificationReducer.js";
import paymentReducer from "./paymentReducer.js";
import subscriptionReducer from './subscriptionReducer.js';

export default combineReducers({

  //------ LANDING PAGE ------//
  lcList: goalsReducer,

  //---------- USERS ---------//
  userCreated: userReducer,
  userData: userReducer,
  acceptedTos: userReducer,
  userInfoSubmitted: userReducer,
  kycSubmitted: userReducer,
  regStatus: userReducer,
  userStatus: userReducer,
  plaidLinkToken: userReducer,
  plaidLink: userReducer,
  getPlaid: userReducer,
  
  //---------- GOALS ---------//
  goalsList: goalsReducer,
  goalSaved: goalsReducer,

  //---------- MOBILE --------//
  otpSent: mobileReducer,
  otpVerified: mobileReducer,

  //------- NOTIFICATIONS ------- //
  waitlistSaved: notificationReducer,
  appLinkSent: notificationReducer,

  //------- PAYMENTS ------- //
  cardToken: paymentReducer,
  userCardDtls: paymentReducer,

  //------- SUBSCRIPTIONS ------- //
  regSubs: subscriptionReducer,
  subsStatus: subscriptionReducer,
});
