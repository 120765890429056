/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';

const PlaidSetup = (props) => {


  const onSuccess = useCallback(async (token, metadata) => {
    let account = metadata.accounts && metadata.accounts.length > 0 ? metadata.accounts[0] : {};
    const data = {
      plaidPublicToken: token,
      blockingRequest: false,
      plaidAccountId: account.id || metadata.account_id,
      plaidAccountMask: account.mask || metadata?.account?.mask, //fallback check for mask
      plaidAccountName: account.name,
      plaidBankName: metadata.institution?.name,
      plaidInstitutionId: metadata.institution?.institution_id,
    }
    props.handleCreatePlaidLink(data)
  }, []);

  const config = {
    token: props.plaidLinkToken.toString(),
    onSuccess
  };

  const { open, ready, } = usePlaidLink(config);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return (
    <>
    </>
  );
};

export default PlaidSetup;