import { LANDING_CONTENT, LIST_OF_GOALS, SAVE_GOAL_ENTRY } from "../_actions/_types";

const initialState = {
  item1: {},
  item2: {},
  item3: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_OF_GOALS:
      return {
        ...state,
        item1: action.payload,
      };
    case SAVE_GOAL_ENTRY:
      return {
        ...state,
        item2: action.payload,
      };
    case LANDING_CONTENT:
      return {
        ...state,
        item3: action.payload,
      };
    default:
      return state;
  }
};
