import React, { Component } from 'react';
import './MobileVerify.scss';

import { Button, Grid } from '@mui/material';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import ScreenWrapper from '../../../Common/ScreenWrapper/ScreenWrapper';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentPage,
  segmentTrack,
} from '../../../../services/segment';

import BaseComponent from '../../../BaseComponent';

const phoneUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

class MobileVerify extends BaseComponent {
  constructor(props) {
    super(props, 'mobile_verify');

    this.state = {
      mobileNumber: '',
      mobileVerified: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = async (value) => {
    await this.setState({ mobileNumber: value });

    const { mobileNumber } = this.state;
    if (mobileNumber && mobileNumber.length === 11) {
      if (
        phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'US'), 'US')
      ) {
        this.setState({ mobileVerified: true });
      } else {
        this.setState({ mobileVerified: false });
      }
    } else {
      this.setState({ mobileVerified: false });
    }
  };

  textAutofocus = (event) => {
    event.focus();
    event.currentTarget.trigger();
  };

  render() {
    const { isMobileView, disableSubmitBtn } = this.props;
    const { mobileNumber, mobileVerified } = this.state;
    return (
      <ScreenWrapper>
        <form
          onSubmit={(e) => {
            let getCity;
            let getState;
            let getZipcode;
            if (this.props?.entireData?.personalDetails?.address) {
              getCity = this.props?.entireData?.personalDetails?.address?.city;
              getState =
                this.props?.entireData?.personalDetails?.address?.state;
              getZipcode =
                this.props?.entireData?.personalDetails?.address?.zip;
            } else {
              getCity = this.props?.userData?.data?.user?.address?.city;
              getState = this.props?.userData?.data?.user?.address?.state;
              getZipcode = this.props?.userData?.data?.user?.address?.zip;
            }

            segmentTrack(
              this.props.userData?.data?.userId,
              SEGMENT_PAGE_CATEGORY.EVENT.FORM_SUBMITTED,
              {
                properties: {
                  page_name: SEGMENT_PAGE_CATEGORY.MOBILE_VERIFY.NAME,
                  form_name: SEGMENT_PAGE_CATEGORY.MOBILE_VERIFY.FORM_NAME,
                  form_category: SEGMENT_PAGE_CATEGORY.MOBILE_VERIFY.CATEGORY,
                  form_referrer: SEGMENT_PAGE_CATEGORY.MOBILE_VERIFY.REFERER,
                  input_type: SEGMENT_PAGE_CATEGORY.MOBILE_VERIFY.INPUT_TYPE,
                  number_of_fields:
                    SEGMENT_PAGE_CATEGORY.MOBILE_VERIFY.NUMBER_OF_FIELDS,
                  email: this.props?.userData?.data?.user?.email,
                  firstName: this.props?.userData?.data?.user?.firstName,
                  lastName: this.props?.userData?.data?.user?.lastName,
                  city: getCity,
                  state: getState,
                  zip: getZipcode,
                  advertiser_tracking_enabled: 0,
                  application_tracking_enabled: 0,
                  phone: mobileNumber,
                  platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
                },
              }
            );
            this.props.verifyMobileNumber(e, mobileNumber);
          }}
        >
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12}>
              <h2>Mobile number</h2>
              <p className="sub-head">
                Your credit is your identity. We keep it secure by verifying
                your number.
              </p>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="lbl" for="mobileNumber">
                Phone number
              </label>
              <PhoneInput
                inputProps={{
                  name: 'mobileNumber',
                  required: true,
                  autoFocus: true,
                  maxLength: '17',
                }}
                // onFocus={e => e.currentTarget.focus()}
                onFocus={(event) => this.textAutofocus(event)}
                defaultCountry={'us'}
                autoFocus={true}
                country={'us'}
                onlyCountries={['us']}
                specialLabel=""
                placeholder="+1"
                disableDropdown={true}
                value={mobileNumber}
                onChange={(e) => this.handleChange(e)}
                countryCodeEditable={false}
                isValid={(value, country) => {
                  if (value && value.length === 11) {
                    const countryCode = country.iso2.toUpperCase();
                    if (
                      phoneUtil.isValidNumberForRegion(
                        phoneUtil.parse(value, countryCode),
                        countryCode
                      )
                    ) {
                      return true;
                    } else {
                      return 'Invalid mobile number';
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth={true}
                className={'btn-primary mgr-top-8'}
                type="submit"
                disabled={!mobileVerified}
                id="MobileNumber-Continue"
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </form>
      </ScreenWrapper>
    );
  }
}

export default MobileVerify;
