
import ReactPixel from 'react-facebook-pixel';

export const fbqTrackCustomEvent = (event, data) => {
    try {
        if (!!event) {
            ReactPixel.trackCustom(event, data);
        }
    } catch (ex) {
        console.log("Error Tracking Custom Event " + event, ex);
    }
}