import React, { Component } from 'react';
import './GoogleSearchBox.scss';
import { alpha, styled } from '@mui/material/styles';

import { InputBase, CircularProgress, Input, Button } from '@mui/material';

import PlacesAutocomplete from 'react-places-autocomplete';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 20,
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: '1px solid #D4D6D8',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

class GoogleSearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  render() {
    return (
      <PlacesAutocomplete
        value={this.props.streetAddress}
        onChange={this.props.handleLocationChange}
        onSelect={this.props.handleLocationSelect}
        searchOptions={{ componentRestrictions: { country: ['us'] } }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          let inputProps = getInputProps({
            placeholder: '#1234',
            className: 'textfield',
          })
          return (
            <>
              <Input
                //required
                {...inputProps}
                className="textfield"
                onBlur={(e) => {
                  // this.props.handleLocationChange('');
                  // this.props.toggleManualAddress(false)
                }}
                onFocus={(e) => {
                  // this.props.toggleManualAddress(true)
                }}
              />
              <div className="autocomplete-dropdown-container">
                {loading && (
                  <div className={'googleMapsLoader'}>
                    <CircularProgress color="primary" size={20} />
                  </div>
                )}
                {suggestions.map((suggestion) => {

                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      className={'googleMapsDropdown'}
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
                {((this.props.streetAddress && !this.props.addressSelected) || this.props.addressError) && <div className={'googleMapsDropdown center'} {...{
                  "style": {
                    "backgroundColor": "#ffffff",
                    "cursor": "pointer"
                  },
                  "role": "option",
                  onClick: () => {
                    if (inputProps.onBlur) {
                      inputProps.onBlur();
                    }
                    this.props.showManualAddressForm();
                  }
                }}>
                  <span className='f-500'>{'Add address manually'}</span>
                </div>}
              </div>
            </>
          )
        }
        }
      </PlacesAutocomplete>
    );
  }
}

export default GoogleSearchBox;