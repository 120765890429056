import React, {Component} from 'react';
import { fetchAndRedirectToDeepLink } from '../../../services/deepLinkUtils';
import './Registration.scss';
import { Box, Snackbar, Alert, Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MobileVerify from './MobileVerify/MobileVerify';
import OTPVerify from './OTPVerify/OTPVerify';
import UserInfo from './UserInfo/UserInfo';
import ConfirmInfo from './ConfirmInfo/ConfirmInfo';
import EditInfo from './EditInfo/EditInfo';
import { connect } from 'react-redux';
import { saveUserGoal } from '../../../_actions/goalsActions';
import { sendOtpToMobile, verifyOTP } from '../../../_actions/mobileActions';
import {
  termsAndCons,
  submitUserInfo,
  submitKyc,
  getPlaidLink,
  getUserById,
  registerStatus,
  createPlaidLinkToken,
  createPlaidLink,
} from '../../../_actions/userActions';
import {
  subscriptionStatus,
  registerSubscription,
  subscriptionAuthorization,
  getSubscriptionPlans,
} from '../../../_actions/subscriptionActions';
import { smsAppLink } from '../../../_actions/notificationsActions';

import StateUnsupported from './StateUnsupported/StateUnsupported';
import CreditFrozen from './CreditFrozen/CreditFrozen';
import KYCFailed from './KYCFailed/KYCFailed';
import ConfirmPlan from './ConfirmPlan/ConfirmPlan';
import LoadingProgress from './LoadingProgress/LoadingProgress';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import Welcome from './Welcome/Welcome';
import AlmostThere from './AlmostThere/AlmostThere';
import PlaidError from './PlaidError/PlaidError';
import PlaidLoadingProgress from './PlaidLoadingProgress/PlaidLoadingProgress';
import {
  API_RESPONSE_STATUS,
  APP_CONSTANTS,
  FB_PIXEL_EVENTS,
  USER_NAME_EXISTS,
} from '../../../Constants/AppConstants';
import ImproveCredit from './ImproveCredit/ImproveCredit';
import { fbqTrackCustomEvent } from '../../../services/facebookPixelTracking';
import UserAddress from './UserAddress/UserAddress';
import { singularSdk } from 'singular-sdk';
import ScreenWrapperPayment from '../../Common/ScreenWrapperPayment/ScreenWrapperPayment';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentPage,
  sendRelativePageViewtoSegment,
} from '../../../services/segment';
const phoneUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeComponent: 0,
      entireData: '',
      activeStepper: 2,
      loadingPercent: 20,
      plaidLoadingPercent: 10,
      openSnackBar: false,
      snackBarTitle: '',
      snackBarMsg: '',
      disableSubmitBtn: false,
      disableSubmitBtn2: false,
      authorizationDescription: null,
      stripeClientSecret: null,
      cardErrorMessage: null,
      hidePaymentPopup: false,
      paymentAction: null,
      plaidErrorLinkToken: null,
      openBackdrop: false,
      authSalesTax: null,
      authSubtotal: null,
      showModalFlag: false,
      allStop: false
    };
  }

  appendUserNameIfExists = async (res) => {
    let fName = localStorage.getItem('firstName');
    let lName = localStorage.getItem('lastName');
    let userRes = null;

    if (
      res &&
      res.data &&
      res.data.user.firstName === null &&
      res.data.user.lastName === null
    ) {
      if (fName && lName) {
        const data = {
          id: localStorage.getItem('userId'),
          version: '1',
        };

        const data2 = {
          name: {
            firstName: fName,
            lastName: lName,
            middleName: null,
          },
        };

        try {
          await this.updateUserDetails(data, data2);
          userRes = USER_NAME_EXISTS;
        } catch (e) {
          console.log(e);
        } finally {
          localStorage.removeItem('firstName');
          localStorage.removeItem('lastName');
        }
        return userRes;
      }
      return userRes;
    }
    userRes = USER_NAME_EXISTS;
    return userRes;
  };

  componentDidMount = async () => {
    singularSdk.pageVisit();
    window.scrollTo(0, 0);

    if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload');
      window.location.reload();
    } else {
      localStorage.removeItem('foo');
      await this.props.getUserById();
      const res = this.props.userData;
      let userNameExists = await this.appendUserNameIfExists(res);
      if (res.status === 200) {
        if (res.data.registrationStatus === API_RESPONSE_STATUS.NOT_FINISHED) {
          //handle not finished cases

          let hasSubscription = false;
          if (res.data.user.subscription) {
            hasSubscription =
              res.data.user.subscription.status ===
                API_RESPONSE_STATUS.INITIALIZING ||
              res.data.user.subscription.status ===
                API_RESPONSE_STATUS.ACTIVE ||
              res.data.user.subscription.status ===
                API_RESPONSE_STATUS.WILL_CANCEL;
          }

          // if firstname and lastname is empty
          if (
            res.data.user.firstName === null &&
            res.data.user.lastName === null &&
            userNameExists !== USER_NAME_EXISTS
          ) {
            this.setState({ changeComponent: APP_CONSTANTS.USER_INFO_SCREEN });
          } else if (
            res.data.user.address === null
          ) {
            this.setState({
              changeComponent: APP_CONSTANTS.ADD_ADDRESS_SCREEN,
            });
          }
          // if plaid is failed
          else if (
            res?.data?.user?.plaid?.plaidLinkStatus ===
            API_RESPONSE_STATUS.FAILED
          ) {
            this.setState({
              changeComponent: APP_CONSTANTS.ALMOST_THERE_SCREEN,
            });
          }
          // if there is no subscription
          else if (res.data.user.subscription === null || !hasSubscription) {
            this.setState({
              changeComponent: APP_CONSTANTS.CONFIRM_PLAN_SCREEN,
            });
          }
          //if phone number is empty
          else if (res.data.user.phoneNumber === null) {
            this.setState({
              changeComponent: APP_CONSTANTS.MOBILE_VERIFY_SCREEN,
            });
          }
          //SUCCESS: Everything is done
          else {
            // Redirect to the Deep Link url
            await fetchAndRedirectToDeepLink()
          }
        } else if (
          res.data.registrationStatus === API_RESPONSE_STATUS.FAIL ||
          res.data.registrationStatus === API_RESPONSE_STATUS.SUCCESS ||
          res.data.registrationStatus === API_RESPONSE_STATUS.PENDING
        ) {
          // Redirect to the Deep Link url
          await fetchAndRedirectToDeepLink()
        }
      } else if (res.status === 400) {
        localStorage.clear();
        window.location.reload();
      }
    }
    window.history.pushState(null, null, null);
    window.addEventListener('popstate', this.handleBackButton);
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !this.state.allStop  // prevent further updates (desktop) once Welcome component is created
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.changeComponent !== this.state.changeComponent) {
      sendRelativePageViewtoSegment(this.state.changeComponent);
    }
  }
  handleBackButton = async (event, ref) => {
    event.preventDefault();
    event.stopPropagation();
    const { changeComponent } = this.state;
    if (
      changeComponent > APP_CONSTANTS.MOBILE_VERIFY_SCREEN &&
      changeComponent <= APP_CONSTANTS.OTP_VERIFY_SCREEN
    ) {
      this.setState({ changeComponent: APP_CONSTANTS.MOBILE_VERIFY_SCREEN });
    } else if (changeComponent === APP_CONSTANTS.PAYMENT_DETAILS_SCREEN) {
      this.setState({ changeComponent: changeComponent - 1 });
    } else if (changeComponent === APP_CONSTANTS.STATE_UN_SUPPORTED_SCREEN) {
      this.setState({ changeComponent: 3 });
    } else if (changeComponent === APP_CONSTANTS.CREDIT_FROZEN_SCREEN) {
      window.history.go(1);
    } else if (changeComponent === APP_CONSTANTS.KYC_FAILED_SCREEN) {
      window.history.go(1);
    } else {
      window.history.pushState(null, null, null);
      window.history.go(1);
    }
  };

  selectComponent = () => {
    const { changeComponent } = this.state;
    this.setState({ changeComponent: changeComponent + 1 });
  };

  selectGoal = async (event, data) => {
    event.preventDefault();
    await this.setState({
      entireData: {
        ...this.state.entireData,
        selectedGoal: data.selectedGoal,
        selectedDescription: data.selectedDescription,
      },
    });
    this.selectComponent();
  };

  saveGoalEntry = async (event, data) => {
    event.preventDefault();
    await this.props.saveUserGoal({ goal: data });
    const res = this.props.goalSaved;
    if (res.status === 200) {
      this.selectComponent();
    }
  };

  // ===== MOBILE VERIFICATION ===== //
  verifyMobileNumber = async (event, mobileNum) => {
    event.preventDefault();
    this.setState({ disableSubmitBtn: true });
    const mn = phoneUtil.parseAndKeepRawInput(mobileNum, 'US');
    const phoneNum = '+1' + mn.getNationalNumber();
    await this.props.sendOtpToMobile({ phoneNumber: phoneNum });
    const res = this.props.otpSent;
    if (res.status === 200) {
      await this.setState({
        entireData: {
          ...this.state.entireData,
          mobileNumber: mobileNum,
          otpError: false,
        },
        activeStepper: 2,
        disableSubmitBtn: false,
      });
      this.selectComponent();
    } else if (res.data.user_facing) {
      this.setState({ disableSubmitBtn: false });
      this.displaySnackbar(res.data);
    }
  };

  // ===== RESEND OTP TEXT ===== //
  resendOtpText = async (event, mobileNum) => {
    event.preventDefault();
    this.setState({ disableSubmitBtn2: true });
    const mn = phoneUtil.parseAndKeepRawInput(mobileNum, 'US');
    const phoneNum = '+1' + mn.getNationalNumber();
    await this.props.sendOtpToMobile({ phoneNumber: phoneNum });
    const res = this.props.otpSent;
    if (res.status === 200) {
      await this.setState({
        entireData: {
          ...this.state.entireData,
          mobileNumber: mobileNum,
          otpError: false,
        },
        disableSubmitBtn2: false,
      });
    } else {
      this.setState({ disableSubmitBtn2: false });
    }
  };

  otpClear = () => {
    this.setState({
      entireData: {
        ...this.state.entireData,
        otpError: false,
        otpErrorMsg: '',
      },
    });
  };

  // ===== OTP VERIFICATION ===== //
  verifyOTP = async (event, otp) => {
    event.preventDefault();
    this.setState({ disableSubmitBtn: true });
    const mn = phoneUtil.parseAndKeepRawInput(
      this.state.entireData.mobileNumber,
      'US'
    );
    const phoneNum = '+1' + mn.getNationalNumber();
    await this.props.verifyOTP({ phoneNumber: phoneNum, message: otp });
    const res = this.props.otpVerified;
    if (res.status === 200 && res.data.success) {
      await this.setState({
        activeStepper: 3,
        disableSubmitBtn: false,
      });
      fbqTrackCustomEvent(FB_PIXEL_EVENTS.REGISTRATION_SUCCESS);
      this.setState({ changeComponent: APP_CONSTANTS.WELCOME_SCREEN });
      // this.handleSubmitAndSmsAppLink();
    } else if (res.status === 200 && !res.data.success) {
      await this.setState({
        entireData: {
          ...this.state.entireData,
          otpError: true,
          otpErrorMsg:
            'Incorrect verification PIN. Please re-enter or try resending text.',
        },
        disableSubmitBtn: false,
      });
    } else if (res.status === 400) {
      await this.setState({
        entireData: {
          ...this.state.entireData,
          otpError: true,
          otpErrorMsg: res.data.error_message,
        },
        disableSubmitBtn: false,
      });
    } else {
      this.setState({ disableSubmitBtn: false });
    }
  };

  // ===== POSTING USER DATA ==== //
  updateUserDetails = async (tosData, uData) => {
    await this.setState({
      entireData: {
        ...this.state.entireData,
        personalDetails: uData,
        otpError: false,
      },
      disableSubmitBtn: true,
    });
    await this.props.termsAndCons(tosData);
    const res = this.props.acceptedTos;
    if (res.status === 200) {
      await this.props.submitUserInfo(tosData.id, uData);
      const res2 = this.props.userInfoSubmitted;
      if (res2.status === 200) {
        this.setState({
          disableSubmitBtn: false,
          changeComponent: APP_CONSTANTS.ADD_ADDRESS_SCREEN,
        });
      } else if (res2.status === 400) {
        this.setState({ disableSubmitBtn: false });
        this.displaySnackbar(res2.data);
      } else {
        this.setState({ disableSubmitBtn: false });
      }
    }
    return;
  };

  updateUserAddressDetails = async (tosData, uData) => {
    await this.setState({
      entireData: {
        ...this.state.entireData,
        personalDetails: uData,
        otpError: false,
      },
      disableSubmitBtn: true,
    });
    await this.props.termsAndCons(tosData);
    const res = this.props.acceptedTos;
    if (res.status === 200) {
      await this.props.submitUserInfo(tosData.id, uData);
      const res2 = this.props.userInfoSubmitted;
      if (res2.status === 200) {
        this.setState({
          disableSubmitBtn: false,
          changeComponent: APP_CONSTANTS.ALMOST_THERE_SCREEN,
        });
      } else if (res2.status === 400) {
        this.setState({ disableSubmitBtn: false });
        if (res2.data.error_code === API_RESPONSE_STATUS.STATE_NOT_SUPPORTED) {
          this.setState({
            changeComponent: APP_CONSTANTS.STATE_UN_SUPPORTED_SCREEN,
          });
        } else {
          this.displaySnackbar(res2.data);
        }
      } else {
        this.setState({ disableSubmitBtn: false });
      }
    }
  };

  displaySnackbar = (x) => {
    this.setState({
      openSnackBar: true,
      snackBarTitle: x.title,
      snackBarMsg: x.error_message,
    });
    setTimeout(() => {
      this.handleCloseSnackbar();
    }, 6000);
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackBarTitle: '',
      snackBarMsg: '',
      openSnackBar: false,
    });
  };

  editInfoFlow = (event, ccNum) => {
    event.preventDefault();
    this.setState({
      changeComponent: ccNum,
    });
  };

  // ===== EDITING USER DATA AGAIN =====//
  editUpdatedUserDetails = async (uData) => {
    await this.setState({
      entireData: {
        ...this.state.entireData,
        personalDetails: uData,
        otpError: false,
      },
      disableSubmitBtn: true,
    });
    await this.props.submitUserInfo(localStorage.getItem('userId'), uData);
    const res = this.props.userInfoSubmitted;

    if (res.status === 200) {
      await this.setState({
        disableSubmitBtn: false,
        changeComponent: APP_CONSTANTS.ALMOST_THERE_SCREEN,
      });
    } else if (res.status === 400) {
      this.setState({ disableSubmitBtn: false });
      if (res.data.error_code === API_RESPONSE_STATUS.STATE_NOT_SUPPORTED) {
        await this.setState({
          changeComponent: APP_CONSTANTS.STATE_UN_SUPPORTED_SCREEN,
        });
      }
      if (res.data.error_code === API_RESPONSE_STATUS.CREDIT_FREEZE) {
        await this.setState({
          changeComponent: APP_CONSTANTS.CREDIT_FROZEN_SCREEN,
        });
      }
      if (res.data.user_facing) {
        this.displaySnackbar(res.data);
      }
    } else {
      this.setState({ disableSubmitBtn: false });
    }
  };

  handleConfirmationPopupShow = () => {
    this.setState({
      changeComponent: APP_CONSTANTS.PLAID_LOADING_PROGRESS_SCREEN,
      openConfirmDialog2: true,
    });
  };

  handleSubmitKYC = async (event) => {
    event.preventDefault();
    this.setState({
      disableSubmitBtn: true,
      changeComponent: APP_CONSTANTS.LOADING_PROGRESS_SCREEN,
    });
    await this.props.submitKyc();

    if (this.props.kycSubmitted.status === 200) {
      this.setState({ disableSubmitBtn: false });
      let intervalID = setInterval(async () => {
        await this.props.registerStatus();

        if (this.props.regStatus.data.status === API_RESPONSE_STATUS.PENDING) {
          if (this.state.loadingPercent < 100) {
            this.setState({ loadingPercent: this.state.loadingPercent + 20 });
          }
        } else if (
          this.props.regStatus.data.status === API_RESPONSE_STATUS.SUCCESS
        ) {
          clearInterval(intervalID);
          clearTimeout(this.timeoutKYC);
          this.setState({ changeComponent: APP_CONSTANTS.ALMOST_THERE_SCREEN });
        } else if (
          this.props.regStatus.data.status === API_RESPONSE_STATUS.FAILED &&
          this.props.regStatus.data.canRetryKyc
        ) {
          clearInterval(intervalID);
          clearTimeout(this.timeoutKYC);
          this.setState({ changeComponent: APP_CONSTANTS.EDIT_INFO_SCREEN });
        } else if (
          this.props.regStatus.data.status === API_RESPONSE_STATUS.FAILED &&
          this.props.regStatus.data.failureReason ===
            API_RESPONSE_STATUS.CREDIT_FREEZE
        ) {
          clearInterval(intervalID);
          clearTimeout(this.timeoutKYC);
          this.setState({
            changeComponent: APP_CONSTANTS.CREDIT_FROZEN_SCREEN,
          });
        } else if (
          this.props.regStatus.data.status === API_RESPONSE_STATUS.FAILED &&
          this.props.regStatus.data.failureReason === null
        ) {
          clearInterval(intervalID);
          clearTimeout(this.timeoutKYC);
          this.setState({ changeComponent: APP_CONSTANTS.KYC_FAILED_SCREEN });
        } else {
        }
      }, 3000);

      this.timeoutKYC = setTimeout(() => {
        this.setState({ changeComponent: APP_CONSTANTS.KYC_FAILED_SCREEN });
        clearInterval(intervalID);
      }, 60 * 2 * 1000);
    } else {
      this.setState({
        disableSubmitBtn: false,
        changeComponent: APP_CONSTANTS.KYC_FAILED_SCREEN,
      });
    }
  };

  handleSelectPlan = async (event, userPlan, linkStatus) => {
    event?.preventDefault();
    await this.setState({
      entireData: {
        ...this.state.entireData,
        userPlan,
      },
      disableSubmitBtn: true,
    });
    if (
      linkStatus === API_RESPONSE_STATUS.LINKED ||
      linkStatus === API_RESPONSE_STATUS.SUCCESS ||
      linkStatus === API_RESPONSE_STATUS.PENDING
    ) {
      // this.props.isMobileView
      if (this.props.isMobileView) {
        this.setState({
          disableSubmitBtn: false,
          changeComponent: APP_CONSTANTS.PLAID_LOADING_PROGRESS_SCREEN,
        });
      }
      let intervalID = setInterval(async () => {
        if (this.state.plaidLoadingPercent < 100) {
          this.setState({
            plaidLoadingPercent: this.state.plaidLoadingPercent + 10,
          });
        }
      }, 3000);

      this.timeoutKYC = setTimeout(() => {
        this.setState({
          changeComponent: APP_CONSTANTS.PLAID_ERROR_SCREEN,
          plaidErrMsg: 'Request timeout, Please try again',
        });
        clearInterval(intervalID);
      }, 60 * 2 * 1000);
      let data = userPlan;
      await this.props.subscriptionAuthorization(data);
      const res2 = this.props.regSubs;

      if (res2?.authorization?.description) {
        this.setState({
          authorizationDescription: res2.authorization.description,
          authSalesTax: res2.authorization.salesTax,
          authSubtotal: res2.authorization.subtotal,
        });
      }
      if (res2?.paymentCardSetup) {
        this.setState({
          stripeClientSecret: res2?.paymentCardSetup?.stripeClientSecret,
          paymentAction: res2?.paymentCardSetup?.action,
        });
      }

      if (res2 && res2.authorization !== null) {
        clearInterval(intervalID);
        clearTimeout(this.timeoutKYC);
        console.log('Success 1');
        if (!this.props.isMobileView) {
          this.setState({ showModalFlag: true });
          segmentPage({
            userId: localStorage.getItem('userId'),
            page_category: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN.CATEGORY,
            name: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN.NAME,
            properties: {
              page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
              referrer_page: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN.REFERER,
              category_flow: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN.FLOW_6,
              page_category: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN.PAGE_CATEGORY,
              name: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN.NAME,
              platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
              plan: userPlan,
            },
          });
        } else {
          this.setState({ openConfirmDialog2: true });
        }
      }
      // res2.data.error_code === "PAY_WITH_CARD_INSTEAD"
      if (
        res2 &&
        res2.authorization === null &&
        res2.paymentCardSetup &&
        res2.paymentCardSetup.stripeClientSecret
      ) {
        clearInterval(intervalID);
        clearTimeout(this.timeoutKYC);
        console.log('Failure 1');
        segmentPage({
          userId: localStorage.getItem('userId'),
          page_category: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.CATEGORY,
          name: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.NAME,
          properties: {
            page_type: SEGMENT_PAGE_CATEGORY.TYPE.PAGE,
            referrer_page: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.REFERER,
            category_flow: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.FLOW_6,
            page_category:
              SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.PAGE_CATEGORY,
            name: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN.NAME,
            platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
          },
        });

        if (this.props.isMobileView) {
          this.setState({
            changeComponent: APP_CONSTANTS.PAYMENT_DETAILS_SCREEN,
            stripeClientSecret: res2.paymentCardSetup.stripeClientSecret,
          });
        } else {
          this.setState({
            stripeClientSecret: res2.paymentCardSetup.stripeClientSecret,
          });
        }
      }
    } else {
      this.setState({ disableSubmitBtn: false });
    }
  };

  navigateToPlaid = () => {
    this.setState({ changeComponent: APP_CONSTANTS.ALMOST_THERE_SCREEN });
  };

  // AFTER Plaid Acc Success - CREATING PLAID LINK//
  handleCreatePlaidLink = async (data) => {
    this.setState({
      changeComponent: APP_CONSTANTS.PLAID_LOADING_PROGRESS_SCREEN,
    });
    let intervalID = setInterval(async () => {
      if (this.state.plaidLoadingPercent < 100) {
        this.setState({
          plaidLoadingPercent: this.state.plaidLoadingPercent + 10,
        });
      }
    }, 3000);

    this.timeoutKYC = setTimeout(() => {
      this.setState({
        changeComponent: APP_CONSTANTS.PLAID_ERROR_SCREEN,
        plaidErrMsg: 'Request timeout, Please try again',
      });
      clearInterval(intervalID);
    }, 60 * 2 * 1000);

    await this.props.createPlaidLink(data);
    const res = this.props.plaidLink;

    if (res.data.plaidLinkStatus === API_RESPONSE_STATUS.INITIALIZING) {
      let getPlaidInterval = setInterval(async () => {
        await this.props.getPlaidLink();

        if (
          this.props.getPlaid &&
          (this.props.getPlaid?.data?.plaidLinkStatus ===
            API_RESPONSE_STATUS.SUCCESS ||
            this.props.getPlaid?.data?.plaidLinkStatus ===
              API_RESPONSE_STATUS.PENDING)
        ) {
          fbqTrackCustomEvent(FB_PIXEL_EVENTS.PLAID_SUCCESS, {});

          this.setState({ changeComponent: APP_CONSTANTS.CONFIRM_PLAN_SCREEN });
          clearInterval(intervalID);
          clearTimeout(this.timeoutKYC);

          clearInterval(getPlaidInterval);
        } else if (
          this.props.getPlaid &&
          this.props.getPlaid?.data?.plaidLinkStatus ===
            API_RESPONSE_STATUS.FAILED
        ) {
          await this.setState({
            entireData: {
              ...this.state.entireData,
              plaidErrMsg: this.props?.getPlaid?.data?.errorMessage,
              plaidLinkToken:
                this.props.getPlaid?.data?.error?.plaidAction?.linkToken,
            },
            changeComponent: APP_CONSTANTS.PLAID_ERROR_SCREEN,
            plaidLoadingPercent: 10,
          });
          clearInterval(getPlaidInterval);
          clearInterval(intervalID);
          clearTimeout(this.timeoutKYC);
        }
      }, 2000);

      setTimeout(() => {
        // clearing interval after 2 minutes
        clearInterval(getPlaidInterval);
      }, 2 * 60 * 1000);
    }

    if (
      res &&
      (res.data.plaidLinkStatus === API_RESPONSE_STATUS.UNLINKED ||
        res.data.plaidLinkStatus === API_RESPONSE_STATUS.FAILED)
    ) {
      await this.setState({
        entireData: {
          ...this.state.entireData,
          plaidErrMsg: res.data.errorMessage,
          plaidLinkToken: res?.data?.error?.plaidAction?.linkToken,
        },
        changeComponent: APP_CONSTANTS.PLAID_ERROR_SCREEN,
        plaidLoadingPercent: 10,
      });
      clearInterval(intervalID);
      clearTimeout(this.timeoutKYC);
    }
  };

  handleConnectPlaidAcc = async (event) => {
    event.preventDefault();
    await this.props.createPlaidLinkToken();
    await this.setState({
      entireData: {
        ...this.state.entireData,
        plaidLinkToken: this.props?.plaidLinkToken?.data?.linkToken,
      },
    });
  };

  handleSubmitRegisterPlan = async (event, userPlan) => {
    this.setState({ openBackdrop: true });
    try {
      event.preventDefault();
      if (userPlan) {
        await this.props.registerSubscription(userPlan);
        const res2 = this.props.regSubs;
        if (res2 && res2.status === 200 && res2?.data?.subscription) {
          fbqTrackCustomEvent(FB_PIXEL_EVENTS.REGISTRATION_SUCCESS, res2);
          this.setState({
            changeComponent: APP_CONSTANTS.MOBILE_VERIFY_SCREEN,
          });
        } else {
          this.setState({
            changeComponent: APP_CONSTANTS.PAYMENT_DETAILS_SCREEN,
            hidePaymentPopup: true,
            cardErrorMessage: res2?.data?.paymentCardSetup?.warning,
            stripeClientSecret:
              res2?.data?.paymentCardSetup?.stripeClientSecret,
            paymentAction: res2?.data?.paymentCardSetup?.action,
          });
        }
      }
    } catch (e) {
      console.log('Something went wrong!', e);
    } finally {
      this.setState({ openBackdrop: false });
    }
  };

  // handleSubmitAndSmsAppLink = async (event, userPlan) => {
  //   await this.props.smsAppLink();
  //   const res = this.props.appLinkSent;
  //   if (res && res.status === 200) {
  //     fbqTrackCustomEvent(FB_PIXEL_EVENTS.REGISTRATION_SUCCESS);
  //     this.setState({ changeComponent: APP_CONSTANTS.WELCOME_SCREEN });
  //   }
  // };

  resetPopupAndError = () => {
    this.setState({
      hidePaymentPopup: false,
      cardErrorMessage: null,
    });
  };

  render() {
    const {
      changeComponent,
      activeStepper,
      loadingPercent,
      plaidLoadingPercent,
      disableSubmitBtn,
      openBackdrop,
      authSalesTax,
      authSubtotal,
      disableSubmitBtn2,
      authorizationDescription,
      stripeClientSecret,
      cardErrorMessage,
      hidePaymentPopup,
      paymentAction,
    } = this.state;
    return (
      <>
        {/* {changeComponent === APP_CONSTANTS.USER_INFO_SCREEN ||
        changeComponent === APP_CONSTANTS.ADD_ADDRESS_SCREEN ? (
          <div style={{ paddingTop: 80, marginBottom: 20 }}>
            <StepperProgress activeStepper={activeStepper} />{" "}
          </div>
        ) : null} */}
        {/* {changeComponent === 1 ? (
          <UserGoals selectGoal={this.selectGoal} />
        ) : changeComponent === 2 ? (
          <UserGoalInfo
            selectComponent={this.selectComponent}
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            saveGoalEntry={this.saveGoalEntry}
          />
        ) :  */}
        {changeComponent === APP_CONSTANTS.MOBILE_VERIFY_SCREEN ? (
          <MobileVerify
            userData={this.props.userData}
            entireData={this.state.entireData}
            verifyMobileNumber={this.verifyMobileNumber}
            isMobileView={this.props.isMobileView}
            disableSubmitBtn={disableSubmitBtn}
          />
        ) : changeComponent === APP_CONSTANTS.OTP_VERIFY_SCREEN ? (
          <OTPVerify
            resendOtpText={this.resendOtpText}
            verifyOTP={this.verifyOTP}
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            handleBackButton={this.handleBackButton}
            otpClear={this.otpClear}
            disableSubmitBtn={disableSubmitBtn}
            disableSubmitBtn2={disableSubmitBtn2}
            userData={this.props.userData}
          />
        ) : changeComponent === APP_CONSTANTS.USER_INFO_SCREEN ? (
          <UserInfo
            updateUserDetails={this.updateUserDetails}
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            disableSubmitBtn={disableSubmitBtn}
          />
        ) : changeComponent === APP_CONSTANTS.ADD_ADDRESS_SCREEN ? (
          <UserAddress
            updateUserAddressDetails={this.updateUserAddressDetails}
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            disableSubmitBtn={disableSubmitBtn}
          />
        ) : changeComponent === APP_CONSTANTS.CONFIRM_INFO_SCREEN ? (
          <ConfirmInfo
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            handleSubmitKYC={this.handleSubmitKYC}
            editInfoFlow={this.editInfoFlow}
            disableSubmitBtn={disableSubmitBtn}
          />
        ) : // ====== Loading State ====== //
        changeComponent === APP_CONSTANTS.LOADING_PROGRESS_SCREEN ? (
          <LoadingProgress
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            loadingPercent={loadingPercent}
          />
        ) : // ====== IF SUCCESS KYC ====== //
        changeComponent === APP_CONSTANTS.CONFIRM_PLAN_SCREEN ? (
          <ConfirmPlan
            userData={this.props.userData}
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            handleSelectPlan={this.handleSelectPlan}
            disableSubmitBtn={disableSubmitBtn}
            getSubscriptionPlans={this.props.getSubscriptionPlans}
            subscriptionPlans={this.props.subscriptionPlans}
            handleSubmitRegisterPlan={this.handleSubmitRegisterPlan}
            registerSubscription={this.props.registerSubscription}
            authorizationDescription={authorizationDescription}
            paymentAction={paymentAction}
            cardErrorMessage={cardErrorMessage}
            hidePopup={hidePaymentPopup}
            resetPopupAndError={this.resetPopupAndError}
            salesTax={authSalesTax}
            subtotal={authSubtotal}
            stripeClientSecret={stripeClientSecret}
            showConfirmationModalFlag={this.state.showModalFlag}
            handleConfirmationPopupShow={this.handleConfirmationPopupShow}
          />
        ) : changeComponent === APP_CONSTANTS.ALMOST_THERE_SCREEN ? (
          <AlmostThere
            userData={this.props.userData}
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            handleConnectPlaidAcc={this.handleConnectPlaidAcc}
            handleCreatePlaidLink={this.handleCreatePlaidLink}
          />
        ) : changeComponent === APP_CONSTANTS.PAYMENT_DETAILS_SCREEN ? (
          <>
            <ScreenWrapperPayment>
              <PaymentDetails
                isMobileView={this.props.isMobileView}
                PaymentDetails
                entireData={this.state.entireData}
                handleSubmitRegisterPlan={this.handleSubmitRegisterPlan}
                registerSubscription={this.props.registerSubscription}
                authorizationDescription={authorizationDescription}
                stripeClientSecret={stripeClientSecret}
                paymentAction={paymentAction}
                cardErrorMessage={cardErrorMessage}
                hidePopup={hidePaymentPopup}
                resetPopupAndError={this.resetPopupAndError}
                salesTax={authSalesTax}
                subtotal={authSubtotal}
                subscriptionPlans={this.props.subscriptionPlans}
                userDetails={this.props.userData}
                //address={this.props.entireData}
              />
            </ScreenWrapperPayment>
          </>
        ) : changeComponent === APP_CONSTANTS.WELCOME_SCREEN ? (
          <Welcome
            isMobileView={this.props.isMobileView}
            userData={this.props.userData}
            entireData={this.state.entireData}
          />
        ) : changeComponent === APP_CONSTANTS.IMPROVE_CREDIT_SCREEN ? (
          <ImproveCredit
            isMobileView={this.props.isMobileView}
            navigateToPlaid={this.navigateToPlaid}
          />
        ) : changeComponent === APP_CONSTANTS.EDIT_INFO_SCREEN ? (
          <EditInfo
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            editUpdatedUserDetails={this.editUpdatedUserDetails}
            disableSubmitBtn={disableSubmitBtn}
          />
        ) : changeComponent === APP_CONSTANTS.STATE_UN_SUPPORTED_SCREEN ? (
          <StateUnsupported isMobileView={this.props.isMobileView} />
        ) : changeComponent === APP_CONSTANTS.CREDIT_FROZEN_SCREEN ? (
          <CreditFrozen isMobileView={this.props.isMobileView} />
        ) : changeComponent === APP_CONSTANTS.KYC_FAILED_SCREEN ? (
          <KYCFailed isMobileView={this.props.isMobileView} />
        ) : changeComponent === APP_CONSTANTS.PLAID_ERROR_SCREEN ? (
          <PlaidError
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            plaidErrMsg={this.state.entireData.plaidErrMsg}
            handleConnectPlaidAcc={this.handleConnectPlaidAcc}
            handleCreatePlaidLink={this.handleCreatePlaidLink}
          />
        ) : changeComponent === APP_CONSTANTS.PLAID_LOADING_PROGRESS_SCREEN ? (
          <PlaidLoadingProgress
            isMobileView={this.props.isMobileView}
            entireData={this.state.entireData}
            plaidLoadingPercent={plaidLoadingPercent}
            handleSubmitRegisterPlan={this.handleSubmitRegisterPlan}
            openConfirmDialog2={this.state.openConfirmDialog2}
            registerSubscription={this.props.registerSubscription}
            authorizationDescription={authorizationDescription}
            salesTax={authSalesTax}
            subtotal={authSubtotal}
            subscriptionPlans={this.props.subscriptionPlans}
            userId={this.props.userData?.data?.userId}
            userData={this.props.userData}
            address={this.props.entireData?.personalDetails}
          />
        ) : (
          <Box className="mainLayoutLoading">
            <CircularProgress />
          </Box>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.openSnackBar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
          className="snackbarStyle"
        >
          <Alert
            onClose={this.handleCloseSnackbar}
            severity="warning"
            sx={{ width: '100%' }}
          >
            {this.state.snackBarTitle === 'INVALID_NAME'
              ? 'INVALID NAME'
              : this.state.snackBarTitle}
            <br />
            {this.state.snackBarMsg}
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    goalSaved: dispatch.goalSaved.item2,
    otpSent: dispatch.otpSent.item11,
    otpVerified: dispatch.otpVerified.item12,
    userData: dispatch.userData.item22,
    acceptedTos: dispatch.acceptedTos.item23,
    userInfoSubmitted: dispatch.userInfoSubmitted.item24,
    kycSubmitted: dispatch.kycSubmitted.item25,
    regStatus: dispatch.regStatus.item26,
    plaidLinkToken: dispatch.plaidLinkToken.item28,
    plaidLink: dispatch.plaidLink.item29,
    appLinkSent: dispatch.appLinkSent.item32,
    regSubs: dispatch.regSubs.item51,
    confirmationStatus: dispatch.regSubs.confirmationStatus,
    subsStatus: dispatch.subsStatus.item52,
    subscriptionPlans: dispatch.regSubs.subscriptionPlans,
    getPlaid: dispatch.getPlaid.item30,
  };
};

export default connect(mapDispatchToProps, {
  saveUserGoal,
  sendOtpToMobile,
  verifyOTP,
  termsAndCons,
  submitUserInfo,
  submitKyc,
  smsAppLink,
  getUserById,
  registerStatus,
  createPlaidLinkToken,
  createPlaidLink,
  subscriptionStatus,
  registerSubscription,
  getSubscriptionPlans,
  subscriptionAuthorization,
  getPlaidLink,
})(Registration);
