import React, { Component } from 'react';
import './UserInfo.scss';
import { alpha, styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

import {
  Typography,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  InputBase,
  Button,
  Backdrop,
  CircularProgress,
  Grid,
  Input,
} from '@mui/material';

import ScreenWrapper from '../../../Common/ScreenWrapper/ScreenWrapper';
import { connect } from 'react-redux';
import { getUserById } from '../../../../_actions/userActions';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { FB_PIXEL_EVENTS } from '../../../../Constants/AppConstants';
import { fbqTrackCustomEvent } from '../../../../services/facebookPixelTracking';
import { singluarTrackCustomEvent } from '../../../../services/singular';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentIdentify,
  segmentPage,
  segmentTrack,
} from '../../../../services/segment';
import { Deblur } from '@mui/icons-material';
import BaseComponent from '../../../BaseComponent';

class UserInfo extends BaseComponent {
  constructor(props) {
    super(props, 'user_info');


    this.state = {
      firstName: '',
      lastName: '',
      acceptedPolicy: true,
      firstNameError: false,
      lastNameError: false,
      openBackdrop: false,
      disableSubmit: true,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    await this.props.getUserById();
    const res = this.props.userData;

    singluarTrackCustomEvent('User Created', {
      email: this.props?.userData?.data?.user?.email,
    });

    if (res.status === 200 && res.data?.user?.firstName !== null) {
      await this.setState({
        firstName: res?.data?.user?.firstName,
        lastName: res.data?.user?.lastName,
      });
      this.setState({
        firstNameError: false,
        lastNameError: false,
        disableSubmit: false,
      });
    }
    segmentIdentify(
      this.props?.userData?.data?.userId,
      SEGMENT_PAGE_CATEGORY.EVENT.IDENTIFY_CALL,
      {
        traits: {
          // anonymousId: localStorage.getItem('segmentAnonymousId'),
          // userId: this.props?.userData?.data?.userId,
        },
      }
    );
  };

  handleChange = async (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    if (name === 'acceptedPolicy') {
      await this.setState({ [name]: !this.state[name], disableSubmit: true });
    } else {
      await this.setState({ [name]: value, disableSubmit: false });
    }
  };

  onDataSubmit = async (event) => {
    this.setState({ openBackdrop: true });
    event.preventDefault();
    try {
      const s = this.state;
      this.setState({
        firstNameError: false,
        lastNameError: false,
      });

      let fPattern = new RegExp("^[a-zA-Z ,'-]+$");
      let fn = await fPattern.test(s.firstName);
      let ln = await fPattern.test(s.lastName);

      if (!fn) {
        this.setState({ firstNameError: true });
      }
      if (!ln) {
        this.setState({ lastNameError: true });
      }

      if (fn && ln && s.acceptedPolicy) {
        const data = {
          id: localStorage.getItem('userId'),
          version: '1',
        };

        const data2 = {
          name: {
            firstName: s.firstName,
            lastName: s.lastName,
            middleName: null,
          },
        };
        segmentTrack(
          this.props?.userData?.data?.userId,
          SEGMENT_PAGE_CATEGORY.EVENT.FORM_SUBMITTED,
          {
            properties: {
              page_name: SEGMENT_PAGE_CATEGORY.FULL_NAME.PAGE_NAME,
              form_name: SEGMENT_PAGE_CATEGORY.FULL_NAME.FORM_NAME,
              form_category: SEGMENT_PAGE_CATEGORY.FULL_NAME.FORM_CATEGORY,
              input_type: SEGMENT_PAGE_CATEGORY.FULL_NAME.INPUT_TYPE,
              number_of_fields:
                SEGMENT_PAGE_CATEGORY.FULL_NAME.NUMBER_OF_FIELDS,
              email: this.props?.userData?.data?.user?.email,
              firstName: s.firstName,
              lastName: s.lastName,
              advertiser_tracking_enabled: 0,
              application_tracking_enabled: 0,
              platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
            },
          }
        );

        await this.props.updateUserDetails(data, data2);
        fbqTrackCustomEvent(FB_PIXEL_EVENTS.CONTINUE_USER_INFO, {
          ...data2,
          ...data,
        });
      }
    } catch (e) {
      console.log('something went wrong', e);
    } finally {
      this.setState({ openBackdrop: false });
    }
  };

  render() {
    const {
      firstName,
      lastName,
      acceptedPolicy,
      firstNameError,
      lastNameError,
      openBackdrop,
      disableSubmit,
    } = this.state;
    const { isMobileView, disableSubmitBtn } = this.props;

    return (
      <>
        <ScreenWrapper>
          <form onSubmit={this.onDataSubmit}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12}>
                <h2>Tell us about yourself</h2>
                {/* <p className="sub-head">Create an account using your email.</p> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl error={firstNameError} style={{ width: '100%' }}>
                  <label for="firstName" className="lbl">
                    First Name
                  </label>

                  <Input
                    id="firstName"
                    name="firstName"
                    className="textfield"
                    value={firstName}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'firstName',
                    }}
                  />
                  {firstNameError && (
                    <FormHelperText className="error">
                      Please enter valid first name
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl error={lastNameError} style={{ width: '100%' }}>
                  <label for="lastName" className="lbl">
                    Last Name
                  </label>

                  <Input
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    className="textfield"
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'lastName',
                    }}
                  />
                  {lastNameError && (
                    <FormHelperText className="error">
                      Please enter valid last name
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  fullWidth={true}
                  className="btn-primary mgr-top-8"
                  type="submit"
                  disabled={disableSubmit ? true : false}
                  id="UserInfo-Continue"
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </form>
        </ScreenWrapper>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userData: dispatch.userData.item22,
});

export default connect(mapDispatchToProps, { getUserById })(UserInfo);
