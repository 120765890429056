/* eslint-disable import/no-anonymous-default-export */
import { REG_SUBSCRIPTION, SUBSCRIPTION_PLANS, SUBSCRIPTION_STATUS } from "../_actions/_types";

const initialState = {
  item51: {},
  item52: {},
  subscriptionPlans: [],
  confirmationStatus:null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REG_SUBSCRIPTION:
      return {
        ...state,
        item51: action.payload,
        confirmationStatus : action.payload,
      };
    case SUBSCRIPTION_STATUS:
      return {
        ...state,
        item52: action.payload,
      };

    case SUBSCRIPTION_PLANS:
      return {
        ...state,
        subscriptionPlans: action.payload,
      };

    default:
      return state;
  }
};
