import React, { Component } from 'react';
import './CarouselList.scss';

import { Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { connect } from 'react-redux';
import { landingContent } from '../../../_actions/goalsActions';

import Testimonial1 from '../../../assets/testimonials/Testimonial-1.svg';
import Testimonial2 from '../../../assets/testimonials/Testimonial-2.svg';
import Testimonial3 from '../../../assets/testimonials/Testimonial-3.svg';
import Testimonial4 from '../../../assets/testimonials/Testimonial-4.svg';
import _ from 'lodash';
const IMAGES = [Testimonial1, Testimonial2, Testimonial3, Testimonial4];

const styles = {
  header1: {
    fontWeight: 600,
    fontSize: 48,
    color: '#000000',
    lineHeight: '65px',
  },
  header2: {
    fontWeight: 300,
    fontSize: 32,
    color: '#363636',
    lineHeight: '40px',
    margin: '8px 0 48px 0',
  },
  header3: {
    fontSize: 26,
    fontWeight: 600,
    color: '#363636',
    marginTop: 36,
  },

  mobheader3: {
    fontSize: 26,
    fontWeight: 600,
    color: '#363636',
    marginTop: 36,
  },
};

class CarouselList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselList: [],
      sliderLoaded: false,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    await this.props.landingContent();
    const res = this.props.lcList;
    if (res.status === 200) {
      const carousalData = res.data.data;
      const parsedCarousalList = this.getParsedCarousalListData(carousalData);
      const platformCarousalList = this.props.isMobileView
        ? parsedCarousalList.mobile
        : parsedCarousalList.web;
      const defaultSelected =
        platformCarousalList && platformCarousalList.length > 0
          ? platformCarousalList[0]
          : [];

      this.setState({
        carouselList: platformCarousalList,
        ...defaultSelected,
      });
    }
    this.setState({ sliderLoaded: true });
  };

  handleChange = async (index, x) => {
    const { carouselList } = this.state;
    if (carouselList[index]) {
      await this.setState(carouselList[index]);
    }
  };

  //We are getting data as Object not an array so we are transforming the data into array here
  getParsedCarousalListData = (carousalData) => {
    let parsedObject = { web: [], mobile: [] };
    _.each(carousalData, (val, key) => {
      if (key.startsWith('splash_screen') && key.endsWith('_title')) {
        let splittedKey = key?.split('_');
        let noIdentifier = null;
        if (splittedKey.length >= 3) {
          noIdentifier = splittedKey[2];
        }
        //Not handling web or mobile data just using same for mobile and web
        parsedObject.mobile.push({
          title: val,
          description:
            carousalData[`splash_screen_${noIdentifier}_description`],
        });
        parsedObject.web.push({
          title: val,
          description:
            carousalData[`splash_screen_${noIdentifier}_description`],
        });
      }
    });
    //Appending Images in the data
    parsedObject.web = _.map(parsedObject.web, (_webObj, index) => {
      return { ..._webObj, image: IMAGES[index] ? IMAGES[index] : IMAGES[0] };
    });

    parsedObject.mobile = _.map(parsedObject.mobile, (_mobileObj, index) => {
      return {
        ..._mobileObj,
        image: IMAGES[index] ? IMAGES[index] : IMAGES[0],
      };
    });

    return parsedObject;
  };

  render() {
    const { classes, isMobileView } = this.props;
    const { title, description, carouselList, sliderLoaded } = this.state;
    return (
      <>
        <div className="carousel-wrapper">
          {sliderLoaded && (
            <Carousel
              showStatus={false}
              showThumbs={false}
              swipeable={true}
              emulateTouch={true}
              showArrows={false}
              onChange={this.handleChange}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={60}
              selectedItem={0}
              interval={5000}
              transitionTime={isMobileView ? 400 : 700}
              infiniteLoop={true}
              centerMode={true}
              centerSlidePercentage={100}
              autoPlay={true}
            >
              {carouselList &&
                carouselList.map((slide) => {
                  return (
                    <div className="carousal" key={slide.title}>
                      <img src={slide.image} alt={slide.title} />

                      <Typography variant="h2" className={'carousal-title'}>
                        {title}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={'carousal-subhead'}
                      >
                        {description}
                      </Typography>
                    </div>
                  );
                })}
            </Carousel>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userCreated: dispatch.userCreated.item21,
  userStatus: dispatch.userStatus.item27,
  lcList: dispatch.lcList.item3,
});

export default connect(mapDispatchToProps, { landingContent })(
  withStyles(styles)(CarouselList)
);
