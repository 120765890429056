import { CARD_TOKEN, USER_CARD_DETAILS } from "../_actions/_types";

const initialState = {
  item41: {},
  item42: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CARD_TOKEN:
      return {
        ...state,
        item41: action.payload,
      };
    case USER_CARD_DETAILS:
      return {
        ...state,
        item42: action.payload,
      };
    default:
      return state;
  }
};
