import {useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';

function useStateChangeListener() {
    // Impact's action tracker IDs used in each tracked events
    const userCreatedTrackerId = process.env.REACT_APP_IMPACT_USER_CREATED_TRACKER_ID
    const subsPendingTrackerId = process.env.REACT_APP_IMPACT_SUBS_PENDING_TRACKER_ID
    const avaUserCreatedEventKey= "avaUserCreatedEventKey"

    // Redux states to listen to:
    const state = useSelector(state => state);
    const {
        userCreated: userCreatedState,
        userData: userDataState,
        regSubs: regSubsState,
        regStatus: regStatusState
    } = state;
    console.log('state change listener hook triggered...', {...state});

    const userIdRef = useRef(null); // To store the userId once we have it.
    const userEmailRef = useRef(null); // To store the email once we have it.
    const subscriptionIdRef = useRef(null);
    // const userCreatedEventTriggeredRef = useRef(false)

    useEffect(async () => {
        console.log('state change listener useEffect triggered...', {...userCreatedState});
        const emitUserData = (userId, userEmail) => {
            console.log(`Emitting user data to the GTM dataLayer with userId=${userId} and userEmail=${userEmail}...`)
            // const userId = userData.userId
            // const userEmail = userData.user?.email
            // Set the subscriptionId DLV to be the userId when if we don't have the subscriptionId yet.
            const subscriptionId = getSubscriptionIdOrUserId(subscriptionIdRef.current, userId)
            console.log(`Current userIdRef=${userIdRef.current}, current userEmail=${userEmailRef.current}`)
            // Push to dataLayer if userId changed from null to a value and hasn't been pushed before
            // TODO: Determine if we really need to skip dataLayer push if it was already done in a session by adding
            //  this to the if statement (!userIdRef.current &&)
            if (window.dataLayer) {
                console.log(`Emitting GTM dataLayer push for DLVs: userId: ${userId}, email: ${userEmail}, subscriptionId: ${subscriptionId}`);
                window.dataLayer.push({
                    'customerId': userId,
                    'customerEmail': userEmail,
                    'subscriptionId': subscriptionId
                });

                if(!userIdRef.current || userIdRef.current !== userId) {
                    userIdRef.current = userId; // update the ref value for next render
                    userEmailRef.current = userEmail;
                    console.log(`Emitting GTM dataLayer push for [UserDataLoaded]`);
                    window.dataLayer.push({
                        'event': 'UserDataLoaded'
                    });
                }
            }
        }

        const emitSubscriptionData = (subsData) => {
            if (subsData) {
                console.log(`Emitting subscription data to the GTM dataLayer...`)
                const subscriptionId = subsData.item51?.data?.subscription?.id ??
                    subsData.item22?.data?.user?.subscription?.id
                console.log(`Current subscriptionId=${subscriptionIdRef.current}`)
                // Push to dataLayer if subscriptionId changed from null to a value and hasn't been pushed before
                // TODO: Determine if we really need to skip dataLayer push if it was already done in a session by adding
                //  this to the if statement (!subscriptionIdRef.current && )
                if (window.dataLayer) {
                    console.log(`Emitting GTM dataLayer push for DLVs: subscriptionId: ${subscriptionId}, userId: ${userIdRef.current}, email: ${userEmailRef.current}`);
                    window.dataLayer.push({
                        'subscriptionId': subscriptionId,
                        'customerId': userIdRef.current,
                        'customerEmail': userEmailRef.current,
                        'impactActionTrackerId': subsPendingTrackerId
                    });

                    if(!subscriptionIdRef.current && subscriptionId) {
                        subscriptionIdRef.current = subscriptionId; // update the ref value for next render
                        console.log(`Emitting GTM dataLayer push for [LeadEvent]`);
                        window.dataLayer.push({
                            'event': 'LeadEvent',
                            'impactActionTrackerId': subsPendingTrackerId
                        });
                    }
                }
            }
        }

        function emitUserCreatedEvent() {
            if (window.dataLayer) {
                console.log(`Emitting GTM dataLayer push for [UserCreated]`);
                window.dataLayer.push({
                    'event': 'UserCreated',
                    'impactActionTrackerId': userCreatedTrackerId
                });
                // indicate we have already triggered the user created event
                localStorage.setItem(avaUserCreatedEventKey, userIdRef.current)
            }
        }

        console.log('state change listener useEffect triggered...', {...userCreatedState.item21}, isUserCreatedStateAvailable(userCreatedState));
        if(isUserCreatedStateAvailable(userCreatedState)){
            console.log('=> userCreatedState is available...');
            if(userCreatedState.item21?.data) {
                emitUserData(userCreatedState.item21?.data?.userId, userCreatedState.item21?.data?.email)
            }
            let avaCreatedUserId = localStorage.getItem(avaUserCreatedEventKey);
            if(!avaCreatedUserId) {
                console.log('=> FIRST TIME INVOKING UserCreated event');
                emitUserCreatedEvent()
            }
        } else if(isUserDateStateAvailable(userDataState)){
            console.log('userDataState has changed:', userDataState);
            emitUserData(userDataState.item22?.data?.userId, userDataState.item22?.data.user?.email)
        }

        if(isRegSubsStatePassed(regSubsState)){
            // console.log('regSubsState has changed:', regSubsState);
            emitSubscriptionData(regSubsState)
        } else if(isRegStatusStateInitialized(regStatusState)){
            // console.log('regStatusState has changed:', regStatusState);
            emitSubscriptionData(regStatusState)
        }
        // }
    }, [userCreatedState, userDataState, regSubsState, regStatusState, subsPendingTrackerId, userCreatedTrackerId]);
}

function getSubscriptionIdOrUserId(subscriptionId, userId) {
    if (subscriptionId && subscriptionId.length > 0) {
        return subscriptionId;
    }
    return userId;
}

function isUserDateStateAvailable(userDataState) {
    // item22 is where we keep user data. See src/_reducers/userReducer.js
    return userDataState &&
        userDataState.item22 &&
        typeof userDataState.item22 === 'object' &&
        Object.keys(userDataState.item22).length > 0;
}

function isUserCreatedStateAvailable(userCreatedState) {
    // item22 is where we keep user data. See src/_reducers/userReducer.js
    return userCreatedState &&
        userCreatedState.item21 &&
        typeof userCreatedState.item21 === 'object' &&
        Object.keys(userCreatedState.item21).length > 0;
}

function isRegSubsStateAvailable(subsState) {
    // item22 is where we keep user data. See src/_reducers/userReducer.js
    return subsState &&
        subsState.item51 &&
        typeof subsState.item51 === 'object' &&
        Object.keys(subsState.item51).length > 0;
}

function isRegSubsStatePassed(regSubsState) {
    // console.log(`isRegSubsStatePassed called: ${regSubsState}`)
    if(isRegSubsStateAvailable(regSubsState)) {
        console.log(`Subscription status is: ${regSubsState.item51?.status}`)
        return (regSubsState.item51?.status === 200)
    }
    return false
}

function isRegStatusStateInitialized(regStatusState){
    // console.log(`isRegStatusStateInitialized called: ${regStatusState}`)
    if(isUserDateStateAvailable(regStatusState)){
        console.log(`Subscription status is: ${regStatusState.item22?.data?.subscription?.status}`)
        return (regStatusState.item22?.data?.user?.subscription?.status === "INITIALIZING")
    }
    return false
}

export default useStateChangeListener;