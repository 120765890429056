import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GTM = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
  }, []);

  return null;
};

export default GTM;
