import { SAVE_WAITLIST_ENTRY, SMS_APP_LINK } from "../_actions/_types";
  
  const initialState = {
    item31: {},
    item32: {}
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SAVE_WAITLIST_ENTRY:
        return {
          ...state,
          item31: action.payload,
        };
      case SMS_APP_LINK:
        return {
          ...state,
          item32: action.payload,
        };
      default:
        return state;
    }
  };
  