import React from "react";
import {
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import Registration from "./Components/_pages/Registration/Registration";


class RoutesList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isMobileView } = this.props;
    return (
      <Switch>
        {localStorage.getItem('accessToken') ? 
          <Route exact path='/register' render={(props) => <Registration isMobileView={isMobileView} {...props} />} />
        :  
          <Route path='/*'>
            <Redirect to='/' />
          </Route>
        }
      </Switch>
    );
  }
}

export default RoutesList;

{/* <Route exact path="/" render={(props) => <LandingPage isMobileView={isMobileView} {...props} />} /> */}
