import React, { Component } from 'react';
import './OTPVerify.scss';

import { Button, Grid } from '@mui/material';

import OtpInput from 'react-otp-input';
import { singluarTrackCustomEvent } from '../../../../services/singular';
import ScreenWrapper from '../../../Common/ScreenWrapper/ScreenWrapper';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentPage,
  segmentTrack,
} from '../../../../services/segment';

import BaseComponent from '../../../BaseComponent';



const phoneUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

class OTPVerify extends BaseComponent {
  constructor(props) {
    super(props, 'otp_verify');

    this.state = {
      otp: '',
    };
  }

  componentDidMount() {
    singluarTrackCustomEvent('Mobile OTP Verified', {
      email: this.props?.userData?.data?.user?.email,
      Name:
        this.props?.userData?.data?.user?.firstName ||
        '' + ' ' + this.props?.userData?.data?.user?.lastName ||
        '',
      phone: this.props?.userData?.data?.user?.phoneNumber,
    });
    window.scrollTo(0, 0);
  }

  handleChange = (otp) => {
    this.setState({ otp });
    this.props.otpClear();
  };

  render() {
    const { entireData, disableSubmitBtn } = this.props;
    const { otp } = this.state;
    let phNum;
    if (entireData.mobileNumber) {
      phNum = phoneUtil.formatInOriginalFormat(
        phoneUtil.parse(entireData.mobileNumber, 'US'),
        'US'
      );
    }
    return (
      <ScreenWrapper>
        <form
          onSubmit={(e) => {
            let getCity;
            let getState;
            let getZipcode;
            if (this.props?.entireData?.personalDetails?.address) {
              getCity = this.props?.entireData?.personalDetails?.address?.city;
              getState =
                this.props?.entireData?.personalDetails?.address?.state;
              getZipcode =
                this.props?.entireData?.personalDetails?.address?.zip;
            } else {
              getCity = this.props?.userData?.data?.user?.address?.city;
              getState = this.props?.userData?.data?.user?.address?.state;
              getZipcode = this.props?.userData?.data?.user?.address?.zip;
            }

            segmentTrack(
              this.props.userData?.data?.userId,
              SEGMENT_PAGE_CATEGORY.EVENT.FORM_SUBMITTED,
              {
                properties: {
                  page_name: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.NAME,
                  form_name: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.FORM_NAME,
                  form_category: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.CATEGORY,
                  form_referrer: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.REFERER,
                  input_type: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.INPUT_TYPE,
                  number_of_fields:
                    SEGMENT_PAGE_CATEGORY.OTP_VERIFY.NUMBER_OF_FIELDS,
                  email: this.props?.userData?.data?.user?.email,
                  firstName: this.props?.userData?.data?.user?.firstName,
                  lastName: this.props?.userData?.data?.user?.lastName,
                  city: getCity,
                  state: getState,
                  zip: getZipcode,
                  advertiser_tracking_enabled: 0,
                  application_tracking_enabled: 0,
                  phone: entireData.mobileNumber,
                  platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
                },
              }
            );
            this.props.verifyOTP(e, otp);
          }}
        >
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12}>
              <h2>Mobile number verification</h2>
              <p className="sub-head" s>
                We sent a 6 digit code to the phone number +1{' '}
                <strong>{phNum}</strong>.
              </p>
              <p
                className="sub-head"
                style={{
                  color: !entireData.otpError ? '#363636' : '#F66360',
                }}
              >
                {!entireData.otpError ? '' : entireData.otpErrorMsg}
              </p>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="lbl" for="otp">
                Please enter the code
              </label>
              <OtpInput
                name="otp"
                value={otp}
                onChange={this.handleChange}
                numInputs={6}
                containerStyle={'otpAlignment'}
                inputStyle={'otpStyle'}
                focusStyle={'otpFocusStyle'}
                isInputNum={true}
                hasErrored={entireData.otpError}
                errorStyle={'otpErrorStyle'}
                shouldAutoFocus={true}
                separator={<span className="otpInputSpacing">--</span>}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <p className="text-right">
                <span
                  className="link-btn"
                  onClick={(e) => {
                    segmentTrack(
                      this.props.userData?.data?.userId,
                      SEGMENT_PAGE_CATEGORY.EVENT.BUTTON_CLICKED,
                      {
                        properties: {
                          page_name: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.NAME,
                          button_text:
                            SEGMENT_PAGE_CATEGORY.OTP_VERIFY.BUTTON_TEXT_RESEND,
                          button_color:
                            SEGMENT_PAGE_CATEGORY.OTP_VERIFY.BUTTON_COLOR,
                          button_name:
                            SEGMENT_PAGE_CATEGORY.OTP_VERIFY.BUTTON_NAME_RESEND,
                          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
                        },
                      }
                    );
                    this.props.resendOtpText(e, entireData.mobileNumber);
                  }}
                  id="Otp-ResendText"
                >
                  Resend Text
                </span>
              </p>
              <Button
                fullWidth={true}
                className={
                  disableSubmitBtn
                    ? 'btn-primary mgr-top-8 disableSubmitBtn'
                    : 'btn-primary mgr-top-8'
                }
                type="submit"
                id="Otp-Continue"
              >
                Continue
              </Button>
              <p className="text-center mgr-top-8">
                <span
                  className="link-btn "
                  onClick={(e) => {
                    segmentTrack(
                      this.props.userData?.data?.userId,
                      SEGMENT_PAGE_CATEGORY.EVENT.BUTTON_CLICKED,
                      {
                        properties: {
                          page_name: SEGMENT_PAGE_CATEGORY.OTP_VERIFY.NAME,
                          button_text:
                            SEGMENT_PAGE_CATEGORY.OTP_VERIFY
                              .BUTTON_TEXT_CHANGE_PHONE,
                          button_color:
                            SEGMENT_PAGE_CATEGORY.OTP_VERIFY.BUTTON_COLOR,
                          button_name:
                            SEGMENT_PAGE_CATEGORY.OTP_VERIFY
                              .BUTTON_NAME_CHANGE_PHONE,
                          platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
                        },
                      }
                    );
                    this.props.handleBackButton(e);
                  }}
                  id="Otp-ChangePhoneNumber"
                >
                  Change Phone Number
                </span>
              </p>
            </Grid>
          </Grid>
        </form>
      </ScreenWrapper>
    );
  }
}

export default OTPVerify;
