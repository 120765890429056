import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getAuth } from "firebase/auth";

import {
  getRemoteConfig,
  fetchAndActivate,
  getString,
  getValue,
  getNumber,
} from "firebase/remote-config";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.fetchTimeMillis = 3600000;

let annual_subscription_price;
let monthly_subscription_price;
let show_money_back_guarantee;
let money_back_guarantee_string;

export const authentication = getAuth(app);
export function getPriceList() {
  return fetchAndActivate(remoteConfig)
  .then(() => {
    annual_subscription_price = getNumber(
      remoteConfig,
      "annual_subscription_price"
    );
    monthly_subscription_price = getNumber(
      remoteConfig,
      "monthly_subscription_price"
    );
    return { annual_subscription_price, monthly_subscription_price };
  })
  .catch((err) => {
    // ...
  });
}


export function getMoneyBackGuarantee() {
  return fetchAndActivate(remoteConfig)
  .then(() => {
    show_money_back_guarantee = getString(
      remoteConfig,
      "show_money_back_guarantee"
    );
    money_back_guarantee_string = getString(
      remoteConfig,
      "money_back_guarantee_string"
    );
    return { show_money_back_guarantee, money_back_guarantee_string };
  })
  .catch((err) => {
    // ...
  });
}

