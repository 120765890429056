export const APP_CONSTANTS = {
  MOBILE_VERIFY_SCREEN: 1,
  OTP_VERIFY_SCREEN: 2,
  USER_INFO_SCREEN: 3,
  ADD_ADDRESS_SCREEN: 11,
  CONFIRM_INFO_SCREEN: 4,
  LOADING_PROGRESS_SCREEN: 5,
  CONFIRM_PLAN_SCREEN: 6,
  ALMOST_THERE_SCREEN: 7,
  PAYMENT_DETAILS_SCREEN: 8,
  WELCOME_SCREEN: 9,
  IMPROVE_CREDIT_SCREEN: 10,
  EDIT_INFO_SCREEN: 101,
  STATE_UN_SUPPORTED_SCREEN: 102,
  CREDIT_FROZEN_SCREEN: 103,
  KYC_FAILED_SCREEN: 104,
  PLAID_ERROR_SCREEN: 105,
  PLAID_LOADING_PROGRESS_SCREEN: 106,
};

export const API_RESPONSE_STATUS = {
  FAILED: 'FAILED',
  FAIL: 'FAIL',
  NOT_FINISHED: 'NOT_FINISHED',
  SUCCESS: 'SUCCESS',
  CHOOSE_SUBSCRIPTION: 'CHOOSE_SUBSCRIPTION',
  LINK_PLAID: 'LINK_PLAID',
  CHARGE_SUBSCRIPTION: 'CHARGE_SUBSCRIPTION',
  RESUBMIT_PII: 'RESUBMIT_PII',
  STATE_NOT_SUPPORTED: 'STATE_NOT_SUPPORTED',
  CREDIT_FREEZE: 'CREDIT_FREEZE',
  PENDING: 'PENDING',
  LINKED: 'LINKED',
  INITIALIZING: 'INITIALIZING',
  UNLINKED: 'UNLINKED',
  WILL_CANCEL: 'WILL_CANCEL',
  ACTIVE: 'ACTIVE',
  RECONNECT_ACCOUNT: 'RECONNECT_ACCOUNT',
};

export const AFFILIATE_DATA = 'affiliateData';
export const FB_PIXEL_EVENTS = {
  CONTINUE_WITH_EMAIL: 'CONTINUE_WITH_EMAIL',
  CONTINUE_WITH_GMAIL: 'CONTINUE_WITH_GMAIL',
  CONTINUE_WITH_APPLE: 'CONTINUE_WITH_APPLE',
  CONTINUE_USER_INFO: 'CONTINUE_USER_INFO',
  CONTINUE_PLAID: 'PLAID_PENDING',
  PLAID_SUCCESS: 'PLAID_SUCCESS',
  SELECT_PLAN: 'SELECT_PLAN',
  REGISTRATION_SUCCESS: 'SUBSCRIPTION_PENDING',
};

export const USER_NAME_EXISTS = 'USER_NAME_EXISTS';

export const devices = {
  desktopXX: 3840,
  desktopX: 2560,
  desktop: 1920,
  desktopS: 1440,
  desktopXS: 1200,
  tabletX: 1024,
  tablet: 768,
  mobileXX: 568,
  mobileX: 450,
  mobile: 390,
};

export const deviceTypes = {
  web: 'web',
  desktopXX: 'desktopXX',
  desktopX: 'desktopX',
  desktop: 'desktop',
  desktopS: 'desktopS',
  desktopXS: 'desktopXS',
  tabletX: 'tabletX',
  tablet: 'tablet',
  mobileXX: 'mobileXX',
  mobileX: 'mobileX',
  mobile: 'mobile',
};
