import React, { Component } from 'react';
import './AlmostThere.scss';

import { Typography, Button, Grid } from '@mui/material';

import { withStyles } from '@mui/styles';

import { connect } from 'react-redux';
import {
  createPlaidLink,
  getPlaidLink,
} from '../../../../_actions/userActions';

import PlaidSetup from '../PlaidSetup';
import { FB_PIXEL_EVENTS } from '../../../../Constants/AppConstants';
import { fbqTrackCustomEvent } from '../../../../services/facebookPixelTracking';
import { singluarTrackCustomEvent } from '../../../../services/singular';
import Partners from '../../../Common/Partners/Partners';
import LinkAccount from '../../../../assets/ai/points.png';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentPage,
  segmentTrack,
} from '../../../../services/segment';


import BaseComponent from '../../../BaseComponent';

class AlmostThere extends BaseComponent {
  constructor(props) {
    super(props,'almost_there');

    this.state = {
      userID: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  pushTrackingData = () => {
    if (window.dataLayer) {
      const plaidStartedTrackerId = process.env.REACT_APP_IMPACT_PLAID_STARTED_TRACKER_ID;

      console.log(`Emitting GTM dataLayer push for [LeadEvent] for [PLAID_STARTED]`);
      window.dataLayer.push({
        'event': 'LeadEvent',
        'impactActionTrackerId': plaidStartedTrackerId
      });
    }
  };

  render() {
    const plaidLinkToken = this.props.entireData.plaidLinkToken;
    const { classes } = this.props;
    let btnProps = { marginTop: 40 };
    if (!this.props.isMobileView) {
      btnProps.width = '380px';
    }

    return (
      <>
        <Grid container className={'gridWrapper reverse'}>
          <Grid item xs={12} sm={12} md={6} lg={6} size={12}>
            <div className={'rightGridWrapper min-height'}>
              <div className={'rightGrid'}>
                <section className="rightGrid-content">
                  <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid item xs={12}>
                      <h2 className="space-top">
                        Securely link your bank account so we can help build
                        your score
                      </h2>
                      <p className="sub-head-d">
                        Connect an active US checking account to finish your
                        application. Select an account that you use{' '}
                        <strong>often</strong> with at least{' '}
                        <strong>$1,500</strong> in direct deposit.
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth={true}
                        className="btn-primary"
                        onClick={(arg) => {
                          fbqTrackCustomEvent(
                            FB_PIXEL_EVENTS.CONTINUE_PLAID,
                            {}
                          );
                          singluarTrackCustomEvent('Plaid Pending', {
                            //customUserId:  localStorage.getItem('userId'),
                            email: this.props?.userData?.data?.user?.email,
                          });
                          let getCity;
                          let getState;
                          let getZipcode;
                          if (
                            this.props?.entireData?.personalDetails?.address
                          ) {
                            getCity =
                              this.props?.entireData?.personalDetails?.address
                                ?.city;
                            getState =
                              this.props?.entireData?.personalDetails?.address
                                ?.state;
                            getZipcode =
                              this.props?.entireData?.personalDetails?.address
                                ?.zip;
                          } else {
                            getCity =
                              this.props?.userData?.data?.user?.address?.city;
                            getState =
                              this.props?.userData?.data?.user?.address?.state;
                            getZipcode =
                              this.props?.userData?.data?.user?.address?.zip;
                          }
                          segmentTrack(
                            this.props.userData?.data?.userId,
                            SEGMENT_PAGE_CATEGORY.EVENT.BUTTON_CLICKED,
                            {
                              properties: {
                                page_name:
                                  SEGMENT_PAGE_CATEGORY.PLAID_START.PAGE_NAME,
                                button_text:
                                  SEGMENT_PAGE_CATEGORY.PLAID_START.BUTTON_TEXT,
                                button_color:
                                  SEGMENT_PAGE_CATEGORY.PLAID_START
                                    .BUTTON_COLOR,
                                button_name:
                                  SEGMENT_PAGE_CATEGORY.PLAID_START.BUTTON_NAME,
                                name: SEGMENT_PAGE_CATEGORY.PLAID_START.NAME,
                                button_rgb:
                                  SEGMENT_PAGE_CATEGORY.PLAID_START.BUTTON_RGB,
                                event:
                                  SEGMENT_PAGE_CATEGORY.PLAID_START.PAGE_NAME,
                                email: this.props?.userData?.data?.user?.email,
                                firstName:
                                  this.props?.userData?.data?.user?.firstName,
                                lastName:
                                  this.props?.userData?.data?.user?.lastName,
                                advertiser_tracking_enabled: 0,
                                application_tracking_enabled: 0,
                                city: getCity,
                                state: getState,
                                zip: getZipcode,
                                platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
                              },
                            }
                          );
                          segmentTrack(
                            this.props.userData?.data?.userId,
                            SEGMENT_PAGE_CATEGORY.CUSTOM_EVENTS.PLAID_STARTED,
                            {
                              properties: {
                                email: this.props?.userData?.data?.user?.email,
                                firstName:
                                  this.props?.userData?.data?.user?.firstName,
                                lastName:
                                  this.props?.userData?.data?.user?.lastName,
                                address:
                                  this.props.userData?.data?.user?.address,
                                advertiser_tracking_enabled: 0,
                                application_tracking_enabled: 0,
                                platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
                                city: getCity,
                                state: getState,
                                zip: getZipcode,
                              },
                            }
                          );
                          this.props.handleConnectPlaidAcc(arg);
                          this.pushTrackingData();
                        }}
                        id="PlaidConnect-ApplyNow"
                        startIcon={
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#fff"
                                d="M12 5.333h-.667V4a3.335 3.335 0 00-6.666 0v1.333H4c-.733 0-1.333.6-1.333 1.334v6.666c0 .734.6 1.334 1.333 1.334h8c.733 0 1.333-.6 1.333-1.334V6.667c0-.734-.6-1.334-1.333-1.334zm-4 6c-.733 0-1.333-.6-1.333-1.333S7.267 8.667 8 8.667s1.333.6 1.333 1.333-.6 1.333-1.333 1.333zm2.067-6H5.933V4c0-1.14.927-2.067 2.067-2.067 1.14 0 2.067.927 2.067 2.067v1.333z"
                              ></path>
                            </svg>
                          </>
                        }
                      >
                        Link your account
                      </Button>
                      <p className="lbl-encrp">
                        256-bit encryption keeps your data secure
                      </p>
                      {plaidLinkToken && (
                        <PlaidSetup
                          plaidLinkToken={plaidLinkToken}
                          handleCreatePlaidLink={
                            this.props.handleCreatePlaidLink
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </section>
              </div>
              <Partners />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            size={12}
            className="right-container f-center"
          >
            <div className="moneyback-wrap green show-mobile">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="none"
                  viewBox="0 0 32 32"
                >
                  <g fill="#4EB668" clipPath="url(#clip0_112_10907)">
                    <path d="M27.25 2.569c-2.512.125-2.75.162-3 .475a1.365 1.365 0 00-.194.406c-.112.425 0 .619.925 1.556.456.463.831.875.831.907 0 .03-2.524 2.58-5.606 5.662l-5.612 5.613-2.625-2.625c-2.888-2.882-2.906-2.894-3.625-2.9-.269 0-.456.043-.669.143-.206.107-1.275 1.138-3.881 3.75C.544 18.813.194 19.188.1 19.462c-.219.67-.056 1.244.506 1.8.494.482.8.632 1.294.632.731 0 .725.006 3.738-3.006l2.737-2.726 2.644 2.626c1.631 1.625 2.725 2.668 2.868 2.73.37.17.738.194 1.15.076l.37-.1 6.512-6.513 6.512-6.506.9.881c.738.725.944.894 1.138.931.575.107 1.137-.337 1.243-.974.02-.12.094-1.532.17-3.138l.137-2.912-.138-.275c-.225-.47-.45-.55-1.45-.544-.462.006-1.893.062-3.181.125z"></path>
                    <path d="M26.075 13.581l-2.387 2.388V29.23l.162.169.169.163h4.462l.169-.163.163-.169V11.525l-.175-.169-.176-.168-2.387 2.393zM19.375 20.281c-1.256 1.257-2.413 2.388-2.575 2.519l-.3.225v6.15l.163.194.162.194h4.494l.15-.15a.853.853 0 00.187-.357c.019-.119.025-2.65.019-5.637L21.656 18l-2.281 2.281zM9.313 24.438c0 4.875 0 4.893.3 5.056.087.044.875.069 2.343.069h2.219l.162-.194.163-.194-.012-2.788-.02-2.793-.343-.038c-.425-.044-.731-.143-1.219-.387-.3-.15-.675-.482-1.937-1.738-.863-.856-1.588-1.556-1.613-1.556-.025 0-.043 2.056-.043 4.563zM5.656 21.55c-1.131 1.119-1.731 1.675-1.968 1.806a4.004 4.004 0 01-1.232.425l-.268.044V29.231l.162.169.169.163h4.487l.15-.163.157-.169v-4.644c0-2.556-.007-4.65-.013-4.65-.013 0-.75.726-1.644 1.613z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_112_10907">
                      <path fill="#fff" d="M0 0H32V32H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="content">
                <p>
                  Average Ava members see an average score increase of{' '}
                  <strong>41 points </strong> after 4 months.
                </p>
              </div>
            </div>
            <img
              src={LinkAccount}
              alt="Ava subscribers see an average score increase of 41 points after 4 months..."
            />
            <p className="avg-lbl">
              Average Ava members see an average score increase of
              <strong>41 points</strong>
              after 4 months.
            </p>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  plaidLink: dispatch.plaidLink.item29,
  getPlaid: dispatch.getPlaid.item30,
});

export default connect(mapDispatchToProps, {
  createPlaidLink,
  getPlaidLink,
})(AlmostThere);
