import React, { Component } from 'react';

import './Footer.scss';
import { Divider, Button, Grid, Typography } from '@mui/material';

import avaLogo from '../../assets/logos/AvaLogo.svg';
import appleLogo from '../../assets/icons/apple.png';
import playstoreLogo from '../../assets/icons/playstore.png';
import { Box } from '@mui/system';
import { singularSdk } from 'singular-sdk';
import { isIOS, isAndroid } from 'react-device-detect';
import {DEFAULT_DEEP_LINK, fetchDeepLink} from "../../services/deepLinkUtils";
import QRCode from "qrcode.react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      footerAvaLogo: null,
      footerContent: null,
      footerCopyRight: '',
      deepLinkUrl: ''
    };
  }

  async componentDidMount() {
      if (!this.state.deepLinkUrl || this.state.deepLinkUrl === DEFAULT_DEEP_LINK) {  // Only fetch if it hasn't been fetched yet
          const deepLink = await fetchDeepLink();
          this.setState({ deepLinkUrl: deepLink });
      }
  }
  render() {
    const { isMobileView } = this.props;

    return (
      <footer className="app-footer">
        <br />
        <br />

        <a href="http://www.meetava.com/" target={'_blank'} rel="noreferrer">
          <img src={avaLogo} height={isMobileView ? 37 : 48} alt="" />
        </a>

        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="footerBtns"
          style={{ marginTop: 30 }}
        >
          {(() => {
            if (isIOS) {
              return (
                <>
                  <Grid item size={6}>
                    <Button
                      variant="contained"
                      size="small"
                      className="appStoreBtn"
                      startIcon={<img src={appleLogo} style={{ height: 25 }} />}
                      // href="https://apps.apple.com/us/app/ava-credit-building/id1584183782"
                      target={'_blank'}
                      onClick={() => window.location.href = this.state.deepLinkUrl || DEFAULT_DEEP_LINK}
                    >
                      <div>
                        <small>Download on the</small>
                        <p>App Store</p>
                      </div>
                    </Button>
                  </Grid>
                </>
              );
            } else if (isAndroid) {
              return (
                <>
                  <Grid item size={6}>
                    <Button
                      variant="contained"
                      size="small"
                      className="playStoreBtn"
                      startIcon={
                        <img src={playstoreLogo} style={{ height: 25 }} />
                      }
                      // href="https://play.google.com/store/apps/details?id=com.meetava.avaapp&_branch_match_id=911526692101531853&utm_source=web_to_app&utm_medium=website"
                      target={'_blank'}
                      onClick={() => window.location.href = this.state.deepLinkUrl || DEFAULT_DEEP_LINK}
                    >
                      <div>
                        <small>GET IT ON</small>
                        <p>Google Play</p>
                      </div>
                    </Button>
                  </Grid>
                </>
              );
            } else {
              return (
                <>
                    <p>Scan with your phone</p>
                    <Grid item size={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button
                      variant="contained"
                      size="small"
                      className="playStoreBtn"
                      startIcon={
                        <img src={playstoreLogo} style={{ height: 25 }} />
                      }
                    >
                      <div>
                        <small>GET IT ON</small>
                        <p>Google Play</p>
                      </div>
                    </Button>
                        <div className="qrcode-container" style={{ marginTop: '10px' }}>
                        <QRCode
                            value={this.state.deepLinkUrl ? this.state.deepLinkUrl : DEFAULT_DEEP_LINK}
                            size={100} // Adjust the size as needed
                            fgColor="#000" // Set the QR code color
                        />
                    </div>
                  </Grid>
                  <Grid item size={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button
                      variant="contained"
                      size="small"
                      className="appStoreBtn"
                      startIcon={<img src={appleLogo} style={{ height: 25 }} />}
                    >
                      <div>
                        <small>Download on the App Store</small>
                        <p>App Store</p>
                      </div>
                    </Button>
                      <div className="qrcode-container" style={{ marginTop: '10px' }}>
                          <QRCode
                              value={this.state.deepLinkUrl ? this.state.deepLinkUrl : DEFAULT_DEEP_LINK}
                              size={100} // Adjust the size as needed
                              fgColor="#000" // Set the QR code color
                          />
                      </div>

                  </Grid>
                </>
              );
            }
          })()}
        </Grid>

        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Grid item size={12}>
            <Typography variant="body1" gutterBottom className="footerText">
              <b>Questions?</b> Contact us at{' '}
              <a
                href={'mailto:support@meetava.com'}
                style={{ textDecoration: 'none' }}
                target={'_top'}
              >
                {' '}
                <b style={{ color: '#7B57E2' }}>support@meetava.com</b>
              </a>
              <br />
              {isMobileView ? <br /> : null}
              See{' '}
              <a
                href={'https://meetava.com/terms'}
                style={{ textDecoration: 'none' }}
                target="_blank"
                rel="noreferrer"
              >
                <b style={{ color: '#7B57E2' }}>Terms of Use</b>
              </a>{' '}
              for important disclaimers and legal information.
            </Typography>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" style={{ marginTop: 40 }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{ color: '#7B57E2' }}
          >
            {/* {footerCopyRight} */}© MeetAva 2023
          </Typography>
        </Box>
        <br />
      </footer>
    );
  }
}

export default Footer;
