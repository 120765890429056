import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import './WhatsIncluded.scss';

import cardBuilder from '../../../../assets/ai/card-builder.png';
import CashBack from '../../../../assets/ai/cash-back.png';
import CreditPlan from '../../../../assets/ai/credit-plan.png';

export default function WhatsIncluded() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <a onClick={handleOpen} className="mobile-a" title="See what’s included">
        See what’s included
      </a>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <section className={'modal-content'}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 2, sm: 3, md: 5, lg: 7 }}
          >
            <Grid item xs={12} className="position-relative">
              <h2>Included in your plan:</h2>
              <span className="close-btn" onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="#2A3333"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1.8 1.8L9 9m0 0l7.2-7.2M9 9l7.2 7.2M9 9l-7.2 7.2"
                  ></path>
                </svg>
              </span>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} className="text-center">
              <img src={cardBuilder} alt="Credit Builder Card" />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <h3>Credit Builder Card</h3>
              <ul>
                <li>No interest or fees. Credit limit $2,500.</li>
                <li>Use your card to pay for subscriptions</li>
                <li>
                  We report your progress to the credit bureaus. Scores go up in
                  30 days.
                </li>
              </ul>
            </Grid>

            <Grid
              item
              display={{ xs: 'block', sm: 'block', md: 'none' }}
              xs={12}
              sm={6}
              md={0}
              lg={0}
              className="text-center"
            >
              <img src={CreditPlan} alt="Save & Build Credit Plan" />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <h3>Save & Build Credit Plan</h3>
              <ul>
                <li>
                  Set aside as little as $21 per paycheck in your locked Ava
                  account.
                </li>
                <li>
                  Build credit with every on-time payment. Access all your
                  savings after completing your plan.
                </li>
              </ul>
            </Grid>

            <Grid
              item
              display={{ xs: 'none', sm: 'none', md: 'block' }}
              xs={0}
              sm={0}
              md={6}
              lg={6}
              className="text-center"
            >
              <img src={CreditPlan} alt="Save & Build Credit Plan" />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6} lg={6} className="text-center">
              <img src={CashBack} alt="Earn Up To $200 Cash Back" />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <h3>Earn Up To $200 Cash Back</h3>
              <ul>
                <li>We’ll find loans that lower your monthly payments.</li>
                <li>
                  Get up to $200 cash back every time you refinance through us.
                </li>
                <li>
                  It’s free money on top of what you’re saving every month.
                </li>
              </ul>
            </Grid> */}
          </Grid>
        </section>
      </Modal>
    </>
  );
}
