import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from './SetupForm';

import ava from '../../../../apis/ava';

const stripePromise = loadStripe(process.env.REACT_APP_PK_STRIPE);

function PaymentDetails(props) {
  const [options, setOptions] = useState({
    clientSecret: null,
    appearance: {
      theme: 'stripe',
      variables: {
        fontSmooth: 'always',
        colorPrimary: '#666666',
        colorTextPlaceholder: '#8B8B8B',
        colorBackground: '#ffffff',
        colorText: '#000',
        colorDanger: '#ED4740',
        fontFamily: 'Inter',
        spacingUnit: '5px',
        borderRadius: '8px',
        border: '2px solid #E6E6E6',
        marginBottom: '16px',
        boxShadow: 'none',
        fontSizeBase: '14px',
        fontWeight: '600',
      },

      rules: {
        '.Input--invalid': {
          boxShadow: 'none',
          fontWeight: '600',
        },
        '.Input': {
          boxShadow: 'none',
          fontSize: '14px',
          border: '2px solid #E6E6E6',
          fontFamily: 'Inter',
        },
        '.Label': {
          fontWeight: '600',

          fontSize: '14px',
          fontFamily: 'Inter',
        },
        // See all supported class names and selector syntax below
      },
    },
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap',
      },
    ],
  });

  const [cs, setCs] = useState(null);
  const [stripeMsg, setStripeMsg] = useState('');
  const [stripeTitle, setStripeTitle] = useState('');
  const [stripeCardHolderName, setStripeCardHolderName] = useState('');

  const [cb, setCb] = useState(
    new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    )
  );

  async function getClientSecret(a, b, cName) {
    const res = await ava.post('/payments/cards/token');
    const { stripeClientSecret } = res.data;

    setCs(props.stripeClientSecret);
    setOptions({ ...options, clientSecret: props.stripeClientSecret });
    if (a && b) {
      setStripeTitle(a);
      setStripeMsg(b);
    }
    if (cName) {
      setStripeCardHolderName(cName);
    }
  }

  useEffect(() => {
    if (props.stripeClientSecret) {
      getClientSecret();
    }
  }, [props.hidePopup, props.stripeClientSecret]);

  return options.clientSecret ? (
    <Elements
      stripe={stripePromise}
      key={options.clientSecret}
      options={options}
    >
      {!cb && (
        <SetupForm
          entireData={props.entireData}
          isMobileView={props.isMobileView}
          handleSubmitRegisterPlan={props.handleSubmitRegisterPlan}
          getClientSecret={getClientSecret}
          stripeTitle={stripeTitle}
          stripeMsg={stripeMsg}
          stripeCardHolderName={stripeCardHolderName}
          registerSubscription={props.registerSubscription}
          cardErrorMessage={props.cardErrorMessage}
          hidePopup={props.hidePopup}
          resetPopupAndError={props.resetPopupAndError}
          paymentAction={props.paymentAction}
          subscriptionPlans={props.subscriptionPlans}
          userData={props.userData}
          //address={this.props.entireData}
        />
      )}
    </Elements>
  ) : (
    <Box className="paymentFormLoading">
      <CircularProgress />
    </Box>
  );
}

export default PaymentDetails;
