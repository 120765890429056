import React, { useEffect, useState } from 'react';
import './PaymentDetails.scss';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import {
  Box,
  Typography,
  Button,
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  InputBase,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import warningIcon from '../../../../assets/icons/warningIcon.svg';

import PaymentConfirmation from './PaymentConfirmation/PaymentConfirmation';

import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';

import ava from '../../../../apis/ava';

import { getPriceList } from '../../../../services/firebase-config';
import {
  SEGMENT_PAGE_CATEGORY,
  segmentPage,
  segmentTrack,
} from '../../../../services/segment';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  // 'label + &': {
  //   marginTop: 20,
  // },
  // '& .MuiInputBase-input': {
  //   borderRadius: 8,
  //   position: 'relative',
  //   backgroundColor: '#fff',
  //   border: '1px solid #e6e6e6',
  //   boxShadow: '0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)',
  //   fontSize: 16,
  //   width: '100%',
  //   color: '#363636',
  //   padding: '10px 12px',
  //   transition: theme.transitions.create([
  //     'border-color',
  //     'background-color',
  //     'box-shadow',
  //   ]),
  //   // Use the system font instead of the default Roboto font.
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  //   '&:focus': {
  //     boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
  //     borderColor: theme.palette.primary.main,
  //   },
  //   '&::placeholder': {
  //     /* Chrome, Firefox, Opera, Safari 10.1+ */ color: '#D4D6D8',
  //     opacity: 1,
  //   },
  // },
}));

const useStyles = makeStyles({
  paymentAlert: {
    marginBottom: 20,
    borderRadius: 12,
    paddingTop: 15,
    paddingBottom: 15,
    border: '1px solid #FFC926',
    boxShadow: '0px 5px 10px rgba(103, 103, 103, 0.05)',
    '& .MuiAlert-icon': {
      padding: 0,
      marginRight: 8,
      color: '#FFC926',
    },
  },
});

const SetupForm = (props) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorTitle, setShowErrorTitle] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [cardHolderName, setCardHolderName] = useState('');
  const [authorizationDescription, setAuthorizationDescription] =
    useState(null);
  const [paymentLoad, setPaymentLoad] = useState(true);
  const [authorizationSubtotal, setAuthorizationSubtotal] = useState(true);
  const [authorizationSalesTax, setAuthorizationSalesTax] = useState(true);

  useEffect(() => {
    setShowErrorTitle(props.stripeTitle);
    setShowErrorMsg(props.stripeMsg);
    if (props.stripeTitle !== '' && props.stripeMsg !== '') {
      setShowErrorAlert(true);
    }
    if (props.stripeCardHolderName !== '') {
      setCardHolderName(props.stripeCardHolderName);
    }
  }, [props]);
  useEffect(() => {
    if (props.cardErrorMessage) {
      setShowErrorTitle(props.cardErrorMessage?.title);
      setShowErrorMsg(props.cardErrorMessage?.description);
      setShowErrorAlert(true);
    }
  }, [props.cardErrorMessage]);

  useEffect(() => {
    if (props.hidePopup === true) {
      setOpenConfirmDialog(false);
    }
  }, [props.hidePopup]);

  const handleSubmit = async (event) => {
    props.resetPopupAndError();
    event.preventDefault();
    setShowErrorAlert(false);
    const { currentTarget } = event;
    const fD = new FormData(currentTarget);
    const cardInfo = {
      name: fD.get('cardHolderName'),
    };
    if (!stripe || !elements) {
      return;
    }
    setOpenBackdrop(true);
    let res = null;
    if (props.paymentAction === 'CONFIRM_SETUP_INTENT') {
      res = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: cardInfo.name,
            },
          },
        },
      });
    } else {
      res = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: cardInfo.name,
            },
          },
        },
      });
    }
    if (
      res &&
      ((res.setupIntent && res.setupIntent.status === 'succeeded') ||
        (res.paymentIntent &&
          (res.paymentIntent.status === 'requires_capture' ||
            res.paymentIntent.status === 'success')))
    ) {
      const res2 = await ava.get(`/payments/cards`);
      //TODO: FAILED CARD NOT WORKING
      if (res2 && res2.status === 200) {
        // const priceList = await getPriceList();
        let data = {
          subscriptionPlanId: props.entireData.userPlan.id,
          cardId: res2.data.cards[0].id,
        };
        try {
          let getCity;
          let getState;
          let getZipcode;
          if (props?.entireData?.personalDetails?.address) {
            getCity = props?.entireData?.personalDetails?.address?.city;
            getState = props?.entireData?.personalDetails?.address?.state;
            getZipcode = props?.entireData?.personalDetails?.address?.zip;
          } else {
            getCity = props?.userData?.data?.user?.address?.city;
            getState = props?.userData?.data?.user?.address?.state;
            getZipcode = props?.userData?.data?.user?.address?.zip;
          }     
          segmentTrack(
            props.userData?.data?.userId,
            SEGMENT_PAGE_CATEGORY.EVENT.FORM_SUBMITTED,
            {
              properties: {
                page_name: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.NAME,
                form_name: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.FORM_NAME,
                form_category:
                  SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.CATEGORY,
                form_referrer: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.REFERER,
                input_type: SEGMENT_PAGE_CATEGORY.CHOOSE_PLAN_FAILED.INPUT_TYPE,
                input_selection: props.entireData.userPlan.id,
                email: props?.userData?.data?.user?.email,
                firstName: props?.userData?.data?.user?.firstName,
                lastName: props?.userData?.data?.user?.lastName,
                address: props.userData?.data?.user?.address,
                advertiser_tracking_enabled: 0,
                application_tracking_enabled: 0,
                platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
                city: getCity,
                state: getState,
                zip: getZipcode,
              },
            }
          );

          segmentTrack(
            props.userData?.data?.userId,
            SEGMENT_PAGE_CATEGORY.EVENT.FORM_SUBMITTED,
            {
              properties: {
                page_name: SEGMENT_PAGE_CATEGORY.CC_PAYMENT.NAME,
                form_name: SEGMENT_PAGE_CATEGORY.CC_PAYMENT.FORM_NAME,
                form_category: SEGMENT_PAGE_CATEGORY.CC_PAYMENT.CATEGORY,
                form_referrer: SEGMENT_PAGE_CATEGORY.CC_PAYMENT.REFERER,
                input_type: SEGMENT_PAGE_CATEGORY.CC_PAYMENT.INPUT_TYPE,
                number_of_fields:
                  SEGMENT_PAGE_CATEGORY.CC_PAYMENT.NUMBER_OF_FIELDS,
                advertiser_tracking_enabled: 0,
                application_tracking_enabled: 0,
                email: props?.userData?.data?.user?.email,
                firstName: props?.userData?.data?.user?.firstName,
                lastName: props?.userData?.data?.user?.lastName,
                city: getCity,
                state: getState,
                zip: getZipcode,
                platform: SEGMENT_PAGE_CATEGORY.PLATFORM.WEB,
              },
            }
          );

          const res3 = await ava.post(
            `${process.env.REACT_APP_V2_BASE_URL}/subscriptions/authorization`,
            data
          );

          if (res3 && res3.status === 200) {
            setOpenBackdrop(false);
            setOpenConfirmDialog(true);
          }
          if (res3?.data?.authorization?.description) {
            setAuthorizationDescription(res3.data.authorization.description);
            setAuthorizationSubtotal(res3.data.authorization.subtotal);
            setAuthorizationSalesTax(res3.data.authorization.salesTax);
          }
        } catch (err) {
          console.log('erorrrrrrr', err);    

          setOpenBackdrop(false);
          if (err.response?.data) {
            //TODO: verify
            props.getClientSecret(
              err.response.data.title,
              err.response.data.error_message,
              fD.get('cardHolderName')
            );
          }
        }
      } else {
        setShowErrorTitle('Invalid credit card number');
        setShowErrorMsg('Please re-check your credit card information.');
        setOpenBackdrop(false);
        setShowErrorAlert(true);
      }
    }

    if (res && res.error) {
      setShowErrorTitle('Invalid credit card number');
      setShowErrorMsg('Please re-check your credit card information.');
      setOpenBackdrop(false);
      setShowErrorAlert(true);
    }
  };
  return (
    <>
      <Box className="paymentForm">
        {/* <Typography
          variant="h6"
          className="infoHeader1"
          style={{ marginTop: 40 }}
        >
          Payment details
        </Typography> */}
        {showErrorAlert && (
          <Alert
            variant="outlined"
            severity="warning"
            className={classes.paymentAlert}
            icon={<img src={warningIcon} style={{ height: 40 }} />}
          >
            <AlertTitle style={{ fontWeight: 600, fontSize: 15 }}>
              {showErrorTitle}
            </AlertTitle>
            <text style={{ fontWeight: 500, fontSize: 14 }}>
              {showErrorMsg}
            </text>
          </Alert>
        )}
        {stripe && elements && (
          <form onSubmit={handleSubmit}>
            {!paymentLoad ? (
              <FormControl
                variant="standard"
                style={{ width: '100%', marginBottom: 12 }}
              >
                <label className="lbl" for="cardHolderName">
                  Cardholder Name
                </label>
                <BootstrapInput
                  className="textfield"
                  id="cardHolderName"
                  name="cardHolderName"
                  placeholder="Enter Full Name"
                  value={cardHolderName}
                  required
                  onChange={(event) => setCardHolderName(event.target.value)}
                />
              </FormControl>
            ) : (
              <Box className="paymentElementLoading">
                <CircularProgress />
              </Box>
            )}
            <PaymentElement
              className="card-element"
              onReady={() => setPaymentLoad(false)}
            />
            <Button
              className="btn-primary mgr-top-24"
              fullWidth={true}
              type="submit"
              disabled={!stripe}
              id="CC-AuthorizePayment"
              startIcon={
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#fff"
                      d="M12 5.333h-.667V4a3.335 3.335 0 00-6.666 0v1.333H4c-.733 0-1.333.6-1.333 1.334v6.666c0 .734.6 1.334 1.333 1.334h8c.733 0 1.333-.6 1.333-1.334V6.667c0-.734-.6-1.334-1.333-1.334zm-4 6c-.733 0-1.333-.6-1.333-1.333S7.267 8.667 8 8.667s1.333.6 1.333 1.333-.6 1.333-1.333 1.333zm2.067-6H5.933V4c0-1.14.927-2.067 2.067-2.067 1.14 0 2.067.927 2.067 2.067v1.333z"
                    ></path>
                  </svg>
                </>
              }
            >
              Make a Payment
            </Button>
            <p className="lbl-encrp">
              256-bit encryption keeps your data secure
            </p>
          </form>
        )}
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PaymentConfirmation
        isMobileView={props.isMobileView}
        userPlan={props.entireData.userPlan}
        openConfirmDialog={openConfirmDialog}
        handleSubmitRegisterPlan={props.handleSubmitRegisterPlan}
        registerSubscription={props.registerSubscription}
        authorizationDescription={authorizationDescription}
        subtotal={authorizationSubtotal}
        salesTax={authorizationSalesTax}
        subscriptionPlans={props.subscriptionPlans}
        userId={props.userId}
        userData={props.userData}
        address={props?.entireData?.personalDetails?.address}
      />
    </>
  );
};

export default SetupForm;
