/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { getReferralData } from "../../_actions/userActions";

const LoginLayout = ({ children }) => <>{children}</>;

const LoginLayoutRoute = ({ component: Component, branchData, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LoginLayout>
          <Component isMobileView={rest.isMobileView}
            {...matchProps}
            branchData={branchData} />
        </LoginLayout>
      )}
    />
  );
};

export default LoginLayoutRoute;
