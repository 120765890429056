// deepLinkUtils.js
import axios from 'axios';

export const DEFAULT_DEEP_LINK = 'https://meetava.app.link/web-reg';

export const fetchDeepLink = async () => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken == null) {
            return null;
        }

        const url = `${process.env.REACT_APP_BASE_URL}/login/onetimeLink`;
        const response = await axios.post(url, {}, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        return response.data.deepLink;
    } catch (error) {
        console.error('Error fetching the deep link:', error);
        return DEFAULT_DEEP_LINK;
    }
};

export const fetchAndRedirectToDeepLink = async () => {
    const deepLink = await fetchDeepLink();
    if (deepLink) {
        window.location.href = deepLink; // Redirect to the Deep Link url
    } else {
        // Redirect to the app
        window.location.href = DEFAULT_DEEP_LINK;
    }
};
