export const LANDING_CONTENT = 'LANDING_CONTENT';

export const CREATE_USER = 'CREATE_USER';
export const USER_BY_ID = 'USER_BY_ID';
export const TOS = 'TOS';
export const SUBMIT_USER_INFO = 'SUBMIT_USER_INFO';
export const SUBMIT_KYC = 'SUBMIT_KYC';
export const REGISTER_STATUS = 'REGISTER_STATUS';
export const USER_STATUS = 'USER_STATUS';
export const PLAID_LINK_TOKEN = 'PLAID_LINK_TOKEN';
export const GET_PLAID_LINK = 'GET_PLAID_LINK';
export const CREATE_PLAID_LINK = 'PLAID_LINK';

export const LIST_OF_GOALS = 'LIST_OF_GOALS';
export const SAVE_GOAL_ENTRY = 'SAVE_GOAL_ENTRY';

export const SEND_OTP_TO_MOBILENUMBER = 'SEND_OTP_TO_MOBILENUMBER';
export const OTP_VERIFY = 'OTP_VERIFY';

export const SAVE_WAITLIST_ENTRY = 'SAVE_WAITLIST_ENTRY';
export const SMS_APP_LINK = 'SMS_APP_LINK';

export const CARD_TOKEN = 'CARD_TOKEN';
export const USER_CARD_DETAILS = 'USER_CARD_DETAILS';

export const REG_SUBSCRIPTION = 'REG_SUBSCRIPTION';
export const SUBSCRIPTION_STATUS = 'SUBSCRIPTION_STATUS';

export const SUBSCRIPTION_PLANS = 'SUBSCRIPTION_PLANS';

export const GET_REFERRAL_DATA = 'GET_REFERRAL_DATA';

export const POST_REFERRAL_ATTRIBUTE = 'POST_REFERRAL_ATTRIBUTE';

