import React, { Component } from "react";
import { Route } from "react-router-dom";
import Routes from "../../Routes";

const MainLayout = ({ children, ...rest }) => {
  return <>{children}</>;
};

const MainLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <MainLayout>
          <Routes isMobileView={rest.isMobileView} {...matchProps} />
        </MainLayout>
      )}
    />
  );
};

export default MainLayoutRoute;
