import React, { Component } from "react";
import "./KYCFailed.scss";

import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";

import BaseComponent from '../../../BaseComponent';

class KYCFailed extends BaseComponent {
  constructor(props) {
    super(props, 'kyc_failed');


    this.state = {
      email: "",
      openDialog: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOpenDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };

  handleChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    await this.setState({ [name]: value });
  };

  render() {
    const { isMobileView } = this.props;
    const { email, openDialog } = this.state;
    return (
      <Box className="kycErrorBox">
        <form>
          <Typography
            variant="h6"
            className="infoHeader1"
            style={{ marginTop: 40 }}
          >
            Couldn't verify identity
          </Typography>
          <Typography
            variant="body1"
            className="infoText1"
            style={{ marginTop: 10 }}
          >
            Bummer. Our bank couldn’t verify your identity. We’re really sorry.
            For security reasons, they do not share the reason why. You can
            still request a manual verification.
          </Typography>

          <Button
            className="goalSelectionBtn"
            variant="contained"
            color="primary"
            size="large"
            style={{
              marginTop: 80,
              marginBottom: 80,
              width: isMobileView ? "100%" : "400px",
            }}
            onClick={this.handleOpenDialog}
          >
            Manual Verification
          </Button>

          <Dialog
            open={openDialog}
            maxWidth={"xs"}
            onClose={this.handleOpenDialog}
            className={"dialogBox"}
            style={{ marginTop: 80 }}
          >
            <DialogTitle
              id="alert-dialog-title"
              className="dialogHeader1"
              style={{ marginTop: 20 }}
            >
              Manual verification
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={"dialogText1"}
              >
                Please reach out to support@meetava.com to initiate the manual
                review process. You’ll be asked to share bank statements,
                driver’s license, and proof of residence.
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: 40 }}>
              <Button
                className="dialogBtn"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<MailOutlineSharpIcon />}
                href={"mailto:support@meetava.com"}
                target={"_top"}
              >
                Email Support
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    );
  }
}

export default KYCFailed;
